import React, { useEffect, useState } from 'react'
import { Box, Select, Group, Button, TextInput, Textarea } from '@mantine/core'
import { getListCompany } from '../../../services/companies'
import { getListCompanyReport } from '../../../services/company-report'
// import classes from '../../../pages/administrator/Benchmark/Benchmark.module.css'
import { validation } from '../../../plugins/validation'
import { useDispatch, useSelector } from 'react-redux'
import { updatedDataBenchmark } from '../../../store/benchmarkData.js'
import { addBenchmarkReport } from '../../../services/benchmark-report'
import { getListBenchmarker } from '../../../services/benchmarker'
import { notificationSuccess, notificationError } from '../../ui/Notifications'

const defaultVal = {
  name: '',
  description: '',
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  companyId: {
    isError: false,
    message: ''
  },
  companyReportId: {
    isError: false,
    message: ''
  },
  benchmarkerId: {
    isError: false,
    message: ''
  }
}

const defaultParamsCompany = {
  skip: 0,
  take: 10,
  search: '',
}


const defaultParamsBenchmarker = {
  skip: 0,
  take: 10,
  search: '',
  isClient: 1
}

const FormCompanyBenchmark = ({ nextStep, activeStep }) => {
  const dataBenchmark = useSelector(state => state.benchmark.dataBenchmark)
  const dispatch = useDispatch()
  const [loadingForm, setLoadingForm] = useState(false)
  const [formData, setFormData] = useState(defaultVal)
  const [paramsCompany, setParamsCompany] = useState(defaultParamsCompany)
  const [paramsBenchmarker, setParamsBenchmarker] = useState(defaultParamsBenchmarker)
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)
  const [selectedCompanyReportId, setSelectedCompanyReportId] = useState(null)
  const [selectedBenchmarkerId, setSelectedBenchmarkerId] = useState(null)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [companyList, setCompanyList] = useState([])
  const [reportList, setReportList] = useState([])
  const [benchmarkerList, setBenchmarkerList] = useState([])

  const handleGetBenchmarkerList = async () => {
    try {
      const response = await getListBenchmarker(paramsBenchmarker)
      const dataBenchmarker = response.data
      const mappingBenchmarker = dataBenchmarker.map((val) => {
        const remap = {
          value: val.id.toString(),
          label: val.name
        }
        return remap
      })
      setBenchmarkerList(mappingBenchmarker)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetCompanyList = async () => {
    try {
      const response = await getListCompany(paramsCompany)
      const dataCompany = response.data
      const mappingCompany = dataCompany.map((val) => {
        const remap = {
          value: val.id.toString(),
          label: val.name
        }
        return remap
      })
      setCompanyList(mappingCompany)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetReportCompany = async (params) => {
    try {
      const response = await getListCompanyReport(params)
      const dataCompanyReport = response.data
      const mappingReport = dataCompanyReport.map((val) => {
        const remap = {
          value: val.id.toString(),
          label: val.name
        }
        return remap
      })
      setReportList(mappingReport)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetBenchmarkerList()
    // eslint-disable-next-line
  }, [paramsBenchmarker.search])

  useEffect(() => {
    handleGetCompanyList()
    // eslint-disable-next-line
  }, [paramsCompany.search])

  useEffect(() => {
    const params = {
      company: selectedCompanyId,
      type: 'benchmark'
    }
    if (selectedCompanyId !== null) {
      handleGetReportCompany(params)
    }
    // eslint-disable-next-line
  }, [selectedCompanyId])

  useEffect(() => {
    if (dataBenchmark !== null) {
      handleGetDetail(dataBenchmark)
    }
  }, [dataBenchmark])

  const handleGetDetail = (val) => {
    const detailForm = {
      name: val.name,
      description: val.description
    }
    setFormData(detailForm)
    setSelectedCompanyId(val.companyId)
    setSelectedCompanyReportId(val.companyReportId)
    setSelectedBenchmarkerId(val.benchmarkerId)
  }

  const handleSearchBenchmarker = (e) => {
    const params = {
      skip: 0,
      take: 10,
      search: e,
    }
    setParamsBenchmarker(params)
  }

  const handleSearchCompany = (e) => {
    const params = {
      skip: 0,
      take: 10,
      search: e,
    }
    setParamsCompany(params)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const toNextStep = async () => {
    setValidationForm(formValidation)
    setLoadingForm(true)
    const payload = {
      name: formData.name,
      description: formData.description,
      companyReportId: selectedCompanyReportId,
      benchmarkerId: selectedBenchmarkerId
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await addBenchmarkReport(payload)
      if (response) {
        setLoadingForm(false)
        notificationSuccess('Tambah Data Benchmark Berhasil', 'Anda telah berhasil menambahkan data benchmark')
        payload.selectedParameter = null
        payload.companyId = selectedCompanyId
        payload.compareBenchmarkLevelAsia = null
        payload.compareBenchmarkLevelNational = null
        payload.compareBenchmarkLevelInternational = null
        payload.benchmarkReportId = response.id
        nextStep()
        dispatch(updatedDataBenchmark({ 'layoutStep': activeStep + 1, 'dataBenchmark': payload }))
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      if (dataBenchmark !== null) {
        if (errorMessage === 'name already exist') {
          const payload = {
            name: dataBenchmark.name,
            description: dataBenchmark.description,
            companyId: dataBenchmark.companyId,
            companyReportId: dataBenchmark.companyReportId,
            benchmarkReportId: dataBenchmark.benchmarkReportId,
            benchmarkerId: dataBenchmark.benchmarkerId,
            selectedParameter: null,
            changeParaphrase: false,
            compareBenchmarkLevelAsia: dataBenchmark.compareBenchmarkLevelAsia,
            compareBenchmarkLevelNational: dataBenchmark.compareBenchmarkLevelNational,
            compareBenchmarkLevelInternational: dataBenchmark.compareBenchmarkLevelInternational
          }
          nextStep()
          dispatch(updatedDataBenchmark({ 'layoutStep': activeStep + 1, 'dataBenchmark': payload }))
        }
      } else {
        notificationError('Gagal melakukan data benchmark', `${Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'}`)
        Object.values(errorMessage).forEach((el) => {
          Object.keys(formValidation).forEach((element) => {
            if (el.includes(element)) {
              setValidationForm((old) => ({
                ...old,
                [element]: {
                  ...old?.[element],
                  isError: true,
                  message: el
                }
              }))
            }
          })
        })
      }
    }
  }
  return (
    <Box my={40} pos='relative'>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Benchmark'
          placeholder='Masukkan nama benchmark'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi Benchmark'
          placeholder='Masukkan deskripsi benchmark'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={handleChange}
          withAsterisk
          autosize
          minRows={4}
        />
      </Box>
      <Box mb='md'>
        <Select
          label="Nama Perusahaan"
          placeholder="Pilih salah satu perusahaan"
          onSearchChange={handleSearchCompany}
          data={companyList}
          nothingFoundMessage='Perusahaan tidak ditemukan'
          onChange={(e) => setSelectedCompanyId(e)}
          error={validationForm.companyId.isError ? `${validationForm.companyId.message}` : ''}
          value={selectedCompanyId}
          withAsterisk
          searchable
          checkIconPosition="right"
          clearable
        />
      </Box>
      <Box mb='md'>
        <Select
          disabled={selectedCompanyId === null}
          label="Nama Laporan Perusahaan"
          placeholder="Pilih salah satu laporan perusahaan"
          data={reportList}
          nothingFoundMessage='Laporan tidak ditemukan'
          onChange={(e) => setSelectedCompanyReportId(e)}
          error={validationForm.companyReportId.isError ? `${validationForm.companyReportId.message}` : ''}
          value={selectedCompanyReportId}
          withAsterisk
          searchable
          checkIconPosition="right"
          clearable
        />
      </Box>
      <Box mb='md'>
        <Select
          label="Nama Bendera Kontrak"
          placeholder="Pilih salah satu bendera kontrak"
          onSearchChange={handleSearchBenchmarker}
          data={benchmarkerList}
          nothingFoundMessage='Bendera Kontrak tidak ditemukan'
          onChange={(e) => setSelectedBenchmarkerId(e)}
          error={validationForm.benchmarkerId.isError ? `${validationForm.benchmarkerId.message}` : ''}
          value={selectedBenchmarkerId}
          withAsterisk
          searchable
          checkIconPosition="right"
          clearable
        />
      </Box>
      <Group justify='flex-end' my="xl">
        <Button loading={loadingForm} onClick={toNextStep}>Selanjutnya</Button>
      </Group>
    </Box>
    
  )
}

export default FormCompanyBenchmark