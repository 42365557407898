import React, { useState, useEffect } from 'react'
import { Box, Center, Loader, ScrollArea, Text, Tabs, Badge, Divider, Flex, Button, Switch, Group, Modal, Select, FileInput, Tooltip, ActionIcon, TextInput } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { getDataBenchmarkReportReview, getListBenchmarkParameter, uploadBenchmarkParameterCustomChart } from '../../../../services/benchmark'
import { assignBenchmarkReportComparison } from '../../../../services/benchmark-report'
import { getListKlhkReportParams } from '../../../../services/klhk-report-params'
import { IconEye, IconTrash } from '@tabler/icons-react'
import { notificationError, notificationSuccess, notificationWarning } from '../../../ui/Notifications'
import { updatedDataBenchmark } from '../../../../store/benchmarkData'
import { useNavigate } from 'react-router-dom'
import BenchmarkDataSource from '../BenchmarkDataSource'
import TableSelectedCompanyReport from '../TableSelectedCompanyReport'
import TableSelectedKlhkReport from '../TableSelectedKlhkReport'
import CBadge from '../../../ui/CBadge'
import PreviewImageChartKlhk from '../PreviewImageChartKlhk'
import PreviewChartBenchmark from '../PreviewChartBenchmark'



const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Perusahaan',
    width: 'auto'
  },
  {
    label: 'Sumber Data',
    width: 'auto'
  },
  {
    label: 'Tahun',
    width: 'auto'
  },
  {
    label: 'Pemakaian / Timbulan',
    width: 'auto'
  },
  {
    label: 'Intensitas',
    width: 'auto'
  },
  {
    label: 'Aksi',
    width: 'auto'
  },
]

const mainLabelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Perusahaan',
    width: 'auto'
  },
  {
    label: 'Sumber Data',
    width: 'auto'
  },
  {
    label: 'Tahun',
    width: 'auto'
  },
  {
    label: 'Pemakaian / Timbulan',
    width: 'auto'
  },
  {
    label: 'Intensitas',
    width: ''
  }
]

const labelTableKlhk = [
  {
    label: 'No.',
    width: 20
  },
  {
    label: 'Data Perdirjen',
    width: 'auto'
  },
  {
    label: '50% Rata-Rata',
    width: 'auto'
  },
  {
    label: '25% Teratas',
    width: 'auto'
  },
  {
    label: '25% Terbawah',
    width: 'auto'
  },
]

const defaultVal = {
  id: null,
  company: '',
  companyReport: '',
  year: '',
  value: null,
  intensity: null,
}

const LoadingData = () => {
  return (
    <Center h={500}>
      <Loader color='blue' size='xl' type='dots' />
    </Center>
  )
}

const LoadingPreviewData = () => {
  return (
    <Center h={200}>
      <Loader color='blue' size='xl' type='dots' />
    </Center>
  )
}

const BenchmarkData = ({ prevStep, nextStep, activeStep, levelBenchmark }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const dataBenchmark = useSelector(state => state.benchmark.dataBenchmark)
  const [loading, setLoading] = useState(true)
  const [defaultBenchmarkData, setDefaultBenchmarkData] = useState([])
  const [compareBenchmarkList, setCompareBenchmarkList] = useState([])
  const [klhkReportList, setKlhkReportList] = useState([])
  const [klhkDataList, setKlhkDataList] = useState([])
  const [selectedParams, setSelectedParams] = useState(null)
  const [openModalSourceData, setOpenModalSourceData] = useState(false)
  const [openModalPreview, setOpenModalPreview] = useState(false)
  const [dataImagePreview, setDataImagePreview] = useState(null)
  const [openModalIntensity, setOpenModalIntensity] = useState(false)
  const [formDataIntensity, setFormDataIntensity] = useState(defaultVal)
  const [failMessageReview, setFailMessageReview] = useState([])
  const [loadingPreview, setLoadingPreview] = useState(true)
  const [dataPreviewBenchmark, setDataPreviewBenchmark] = useState(null)
  const [loadingNextStep, setLoadingNextStep] = useState(false)

  const onCloseModalSource = () => {
    setOpenModalSourceData(false)
  }

  const onCloseModalPreview = () => {
    setOpenModalPreview(false)
    setDataImagePreview(null)
  }

  const onCloseModalIntensity = () => {
    setOpenModalIntensity(false)
    setFormDataIntensity(defaultVal)
  }

  // find selected parameter
  // const findSelectedParams = compareBenchmarkList.find(val => val.parameterId === selectedParams)

  const handleGetDataBenchmarkList = async () => {
    setLoading(true)
    const params = {
      benchmarkReport: dataBenchmark.benchmarkReportId,
      level: levelBenchmark
    }
    
    try {
      const response = await getListBenchmarkParameter(params)
      setDefaultBenchmarkData(response.data)
      const compareBenchmark = response.data
      const remapParameter = compareBenchmark.map((val) => {
        const remap = {
          benchmarkParameterId: val.id,
          parameterId: val.companyReportParameter.parameter.id,
          companyReportParameterIds: [],
          klhkReportParameterId: null,
          klhkReportCustomChart: null,
          useDataKlhk: false
        }
        return remap
      })

      // mapping data compare from store
      let dataStore = null
      if (levelBenchmark === 'national') {
        dataStore = dataBenchmark.compareBenchmarkLevelNational
      } else if (levelBenchmark === 'asia') {
        dataStore = dataBenchmark.compareBenchmarkLevelAsia
      } else if (levelBenchmark === 'international') {
        dataStore = dataBenchmark.compareBenchmarkLevelInternational
      }
      
      
      const convertData = dataStore !== null ? JSON.parse(dataStore) : null
      setSelectedParams(dataBenchmark.selectedParameter !== null ? dataBenchmark.selectedParameter : response.data[0].companyReportParameter.parameter.id)
      setCompareBenchmarkList(convertData !== null ? convertData : remapParameter)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetKlhkReportParamsList = async (paramsId) => {
    const params = {
      parameter: paramsId,
      isNotNull: 1
    }
    try {
      const response = await getListKlhkReportParams(params)
      const dataKlhkReportParams = response.data
      const mappingData = dataKlhkReportParams.map((val) => {
        const remap = {
          value: val.id,
          label: val.klhkReport.name
        }
        return remap
      })
      setKlhkReportList(mappingData)
      setKlhkDataList(dataKlhkReportParams)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetReviewBenchmark = async () => {
    setLoadingPreview(true)
    const findSelectedParams = compareBenchmarkList.find(val => val.parameterId === selectedParams)
    if (findSelectedParams.klhkReportParameterId === null && findSelectedParams.companyReportParameterIds.length > 0) {
      const remapCompanyReportParameter = findSelectedParams.companyReportParameterIds.map((val) => {
        const remap = {
          id: val.id,
          customIntensity: val.intensity
        }
        return remap
      })
      const payload = {
        benchmarkParameterId: findSelectedParams.benchmarkParameterId,
        companyReportParameters: remapCompanyReportParameter,
        klhkReportParameterId: null,
      }
      try {
        const response = await getDataBenchmarkReportReview(payload)
        if (response.status === 'fail') {
          const errorMessage = response.message
          setFailMessageReview(response.message)
          errorMessage.map((val) => {
            return (
              notificationWarning('Peringatan Benchmark', `${val.includes('countries') ? 'Data pembanding minimal memilih 5 negara yang berbeda': 'Data pembanding minimal memilih 3 benua yang berbeda'}`)
            )
          })
        } else (
          setFailMessageReview([])
        )
        setDataPreviewBenchmark(response.data)
        
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingPreview(false)
      }
    } else {
      setLoadingPreview(false)
    }
  }

  const handleGetDetailBenchmark = async () => {
    const params = {
      benchmarkReport: dataBenchmark.benchmarkReportId,
      level: levelBenchmark
    }
    try {
      const response = await getListBenchmarkParameter(params)
      const resDataBenchmark = response.data
      const mappingPayload = (data) => {
        const dataCompare = []
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const dataBenchmarkComparison = data[i].benchmarkParameterComparisons
            if (dataBenchmarkComparison.length > 0) {
              const remapPayload = {
                benchmarkParameterId: data[i].id,
                parameterId: data[i].companyReportParameter.parameter.id,
                companyReportParameterIds: [],
                klhkReportParameterId: null,
                useDataKlhk: false,
                klhkReportCustomChart: data[i].customChart
              }
              for (let j = 0; j < dataBenchmarkComparison.length; j++) {
                if (dataBenchmarkComparison[j].isMain === false) {
                  if (dataBenchmarkComparison[j].klhkReportParameter === null) {
                    remapPayload.companyReportParameterIds.push(dataBenchmarkComparison[j].comparisonReportParameter)
                    remapPayload.useDataKlhk = false
                  } else {
                    remapPayload.klhkReportParameterId = dataBenchmarkComparison[j].klhkReportParameter
                    remapPayload.useDataKlhk = true
                  }
                }
              }
              dataCompare.push(remapPayload)
            }
          }
        }
        return dataCompare
      }

      const mappingDataNational = mappingPayload(resDataBenchmark)
      return mappingDataNational
    } catch (error) {
      console.log(error)
    }
  }

  const handleUploadCustomChartBenchmark = async (benchmarkParamsId, fileUpload) => {
    const form = new FormData()
    form.append('file', fileUpload)
    try {
      await uploadBenchmarkParameterCustomChart(benchmarkParamsId, form)
    } catch (error) {
      console.log(error)
      notificationError('Anda Gagal Mengunggah Gambar', 'Silahkan hubungi admin untuk mengatasi masalah ini')
    }
  }

  const handleStoreBenchmarkComparison = async (typeSession, dataComparison) => {
    const payload = {
      isFinished: false,
      benchmarkParameterComparisons: []
    }

    const mappingPayload = (data) => {
      if (data !== null) {
        for (let i = 0; i < data.length; i++) {
          const remapDataBenchmark = {
            benchmarkParameterId: data[i].benchmarkParameterId,
            companyReportParameters: data[i].companyReportParameterIds.length > 0 ? remapParameterId(data[i].companyReportParameterIds) : [],
            klhkReportParameterId: setValueKlhkParamsId(data[i].klhkReportParameterId)
          }
          payload.benchmarkParameterComparisons.push(remapDataBenchmark)
        }
      }
    }

    const remapParameterId = (parameter) => parameter.map((val) => {
      const remap = {
        id: val.id,
        customIntensity: val.intensity
      }
      return remap
    })

    mappingPayload(dataComparison.compareBenchmarkLevelAsia)
    mappingPayload(dataComparison.compareBenchmarkLevelNational)
    mappingPayload(dataComparison.compareBenchmarkLevelInternational)

    try {
      const response = await assignBenchmarkReportComparison(dataComparison.benchmarkReportId, payload)
      if (response) {
        if (typeSession === 'draft') {
          notificationSuccess('Simpan Data Draft Berhasil', 'Anda telah berhasil menyimpan data draft')
          navigate('/benchmark-results')
        } else {
          if (levelBenchmark === 'national') {
            const dataNational = await handleGetDetailBenchmark()
            const payloadStored = {
              ...dataComparison,
              compareBenchmarkLevelNational: JSON.stringify(dataNational),
              compareBenchmarkLevelAsia: JSON.stringify(dataComparison.compareBenchmarkLevelAsia),
              compareBenchmarkLevelInternational: JSON.stringify(dataComparison.compareBenchmarkLevelInternational)
            }
            dispatch(updatedDataBenchmark({ 'layoutStep': activeStep + 1, 'dataBenchmark': payloadStored }))
            nextStep()
          } else {
            const payload = {
              ...dataComparison,
              compareBenchmarkLevelNational: JSON.stringify(dataComparison.compareBenchmarkLevelNational),
              compareBenchmarkLevelAsia: JSON.stringify(dataComparison.compareBenchmarkLevelAsia),
              compareBenchmarkLevelInternational: JSON.stringify(dataComparison.compareBenchmarkLevelInternational)
            }
            dispatch(updatedDataBenchmark({ 'layoutStep': activeStep + 1, 'dataBenchmark': payload }))
            nextStep()
          }
        }
        setLoadingNextStep(false)
      }
    } catch (error) {
      console.log(error)
      notificationError(`${typeSession === 'next_step' ? 'Anda Tidak Bisa Melanjutkan Benchmark' : 'Anda Tidak Bisa Menyimpan Benchmarj'}`, 'Silahkan cek data anda')
      setLoadingNextStep(false)
    }
  }

  useEffect(() => {
    if (levelBenchmark) {
      handleGetDataBenchmarkList()
    }
    // eslint-disable-next-line
  }, [levelBenchmark])

  useEffect(() => {
    if (compareBenchmarkList && selectedParams !== null ) {
      handleGetReviewBenchmark()
    }
    // eslint-disable-next-line
  }, [compareBenchmarkList, selectedParams])

  const validateDataComparison = (selectedDataParams) => {
    let status = false
    for (let i = 0; i < selectedDataParams.length; i++) {
      if (selectedDataParams[i].useDataKlhk === false) {
        if (selectedDataParams[i].companyReportParameterIds.length === 0) {
          status = true
        }
      } else {
        if (selectedDataParams[i].klhkReportParameterId === null) {
          status = true
        }
      }
    }
    return status
  }

  const handleChange = (e) => {
    setFormDataIntensity({ ...formDataIntensity, [e.target.name]: e.target.value })
  }

  const updateIntensity = (val) => {
    const findSelectedParams = compareBenchmarkList.find(val => val.parameterId === selectedParams)
    const dataReport = findSelectedParams.companyReportParameterIds
    for (let i = 0; i < dataReport.length; i++) {
      if (dataReport[i].id === val.id) {
        dataReport[i].intensity = Number(val.intensity)
        if (dataReport[i].customIntensity) {
          dataReport[i].customIntensity = Number(val.intensity)
        }
      }
    }
    setCompareBenchmarkList([...compareBenchmarkList])
    onCloseModalIntensity()
  }

  

  const setValueKlhkParamsId = (data) => {
    let val = ''
    if (data !== null) {
      if (typeof data === 'object') {
        val = data.id
      } else {
        val = data
      }
    }
    return val
  }

  const mappingWarningMessage = (dataMessage) => {
    if (dataMessage.length > 0) {
      const mappingMessage = dataMessage.map((val, index) => {
        return (
          <Box key={index}>
            <Text c='red' fz={8}>- {val.includes('countries') ? 'Data pembanding minimal memilih 5 negara yang berbeda': 'Data pembanding minimal memilih 3 benua yang berbeda'}</Text>
          </Box>
        )
      })
      return mappingMessage
    }
  }

  const toPrevStep = () => {
    const payload = {
      name: dataBenchmark.name,
      description: dataBenchmark.description,
      companyId: dataBenchmark.companyId,
      companyReportId: dataBenchmark.companyReportId,
      benchmarkReportId: dataBenchmark.benchmarkReportId,
      benchmarkerId: dataBenchmark.benchmarkerId,
      selectedParameter: null,
      changeParaphrase: dataBenchmark.changeParaphrase,
      compareBenchmarkLevelAsia: dataBenchmark.compareBenchmarkLevelAsia,
      compareBenchmarkLevelNational: dataBenchmark.compareBenchmarkLevelNational,
      compareBenchmarkLevelInternational: dataBenchmark.compareBenchmarkLevelInternational
    }
    dispatch(updatedDataBenchmark({ 'layoutStep': activeStep - 1, 'dataBenchmark': payload }))
    prevStep()
  }

  const submitData = async (stateSubmit) => {
    setLoadingNextStep(true)
    const filterSelectedParameter = compareBenchmarkList.filter(val => val.parameterId !== undefined)
    const validateData = validateDataComparison(filterSelectedParameter)
    if (stateSubmit === 'next_step') {
      if (validateData) {
        notificationError('Anda Tidak Bisa Melanjutkan Benchmark', 'Silahkan cek data anda')
        setLoadingNextStep(false)
        return
      }
    }
    const payload = {
      name: dataBenchmark.name,
      description: dataBenchmark.description,
      companyId: dataBenchmark.companyId,
      companyReportId: dataBenchmark.companyReportId,
      benchmarkReportId: dataBenchmark.benchmarkReportId,
      benchmarkerId: dataBenchmark.benchmarkerId,
      selectedParameter: null,
      changeParaphrase: dataBenchmark.changeParaphrase,
      compareBenchmarkLevelAsia: JSON.parse(dataBenchmark.compareBenchmarkLevelAsia),
      compareBenchmarkLevelNational: JSON.parse(dataBenchmark.compareBenchmarkLevelNational),
      compareBenchmarkLevelInternational: JSON.parse(dataBenchmark.compareBenchmarkLevelInternational)
    }
    if (levelBenchmark === 'national') {
      for (let i = 0; i < filterSelectedParameter.length; i++) {
        if (filterSelectedParameter[i].useDataKlhk) {
          filterSelectedParameter[i].companyReportParameterIds = []
          if (typeof filterSelectedParameter[i].klhkReportCustomChart === 'object') {
            await handleUploadCustomChartBenchmark(filterSelectedParameter[i].benchmarkParameterId, filterSelectedParameter[i].klhkReportCustomChart)
          }
        } else {
          filterSelectedParameter[i].klhkReportParameterId = null
          filterSelectedParameter[i].klhkReportCustomChart = null
          await handleUploadCustomChartBenchmark(filterSelectedParameter[i].benchmarkParameterId, filterSelectedParameter[i].klhkReportCustomChart)
        }
      }
      payload.compareBenchmarkLevelNational = filterSelectedParameter
    } else if (levelBenchmark === 'asia') {
      payload.compareBenchmarkLevelAsia = filterSelectedParameter
    } else if (levelBenchmark === 'international') {
      payload.compareBenchmarkLevelInternational = filterSelectedParameter
    }
    await handleStoreBenchmarkComparison(stateSubmit, payload)
  }

  const layoutDataBenchmark = (data) => {
    if (data.length > 0) {
      return (
        <Tabs variant="pills" radius="md" defaultValue={selectedParams}>
          <Tabs.List>
            {mappingTabParamsBenchmark(data)}
          </Tabs.List>
          {mappingTabsParamsContent(data)}
        </Tabs>
      )
    } else {
      return (
        <Center h={300}>
          <Text c='gray.6'>Tidak ada data</Text>
        </Center>
      )
    }
  }

  // tab parameter
  const mappingTabParamsBenchmark = (data) => {

    const findTotalSelectedData = (paramsId) => {
      if (compareBenchmarkList) {
        const findData = compareBenchmarkList.find(val => val.parameterId === paramsId)
        const totalData = findData.companyReportParameterIds.length
        return (
          <Badge variant='default' color={totalData === 0 ? 'red' : 'green'} size='sm' circle>{totalData}</Badge>
        )
      }
      
    }

    const handlingReportParams = async (parameterId) => {
      setSelectedParams(parameterId)
      if (levelBenchmark === 'national') {
        await handleGetKlhkReportParamsList(parameterId)
      }
    }


    const remapTabs = data.map((val, index) => {
      return <Tabs.Tab key={index} value={val.companyReportParameter.parameter.id} rightSection={findTotalSelectedData(val.companyReportParameter.parameter.id)} onClick={() => handlingReportParams(val.companyReportParameter.parameter.id)}>{val.companyReportParameter.parameter.name}</Tabs.Tab>
    })
    return remapTabs
  }

  const mappingTabsParamsContent = (data) => {
    if (compareBenchmarkList) {
      const findSelectedParams = compareBenchmarkList.find(val => val.parameterId === selectedParams)
      const stateDataKlhk = (level) => {
        if (level === 'national') {
    
          const setUseDataKlhk = (eventChecked) => {
            if (eventChecked) {
              findSelectedParams.useDataKlhk = true
              handleGetKlhkReportParamsList(selectedParams)
            } else {
              findSelectedParams.useDataKlhk = false
            }
            setCompareBenchmarkList([...compareBenchmarkList])
          }
    
          return (
            <Group>
              <Switch size="xs" onLabel="ON" offLabel="OFF" checked={findSelectedParams.useDataKlhk} onChange={(e) => setUseDataKlhk(e.currentTarget.checked)} />
              <Text size='xs'>Gunakan Data Perdirjen</Text>
            </Group>
          )
        }
      }
      
      const remapContent = data.map((val, index) => {
        const mainBenchmarkReport = [val.companyReportParameter]

        const dataCompareBenchmark = () => {
          const actions = {
            update: (val) => {
              setOpenModalIntensity(true)
              const form = {
                id: val.id,
                company: val.companyReport.company.name,
                companyReport: val.companyReport.name,
                year: val.companyReport.year,
                value: val.finalValue,
                intensity: val.intensity,
              }
              setFormDataIntensity(form)
            },
            delete: (val) => {
              const selectedParameter = findSelectedParams.companyReportParameterIds
              for (let i = 0; i < selectedParameter.length; i++) {
                if (selectedParameter[i].id === val.id) {
                  selectedParameter.splice(i,1)
                }
              }
              setCompareBenchmarkList([...compareBenchmarkList])
            },
          }
        
          const handleAction = (val, type) => {
            return actions[type](val)
          }
      
          return (
            <Box>
              <Box mb={70}>
                <Box mb={20}>
                  <Text fz={14} fw={600}>Data Utama</Text>
                  <Box my={14}>
                    <TableSelectedCompanyReport label={mainLabelTable} data={mainBenchmarkReport} />
                  </Box>
                </Box>
                <Box>
                  <Text fz={14} fw={600}>Data Pembanding</Text>
                  <Box my={14}>
                    <TableSelectedCompanyReport label={labelTable} data={compareBenchmarkList.find(value => value.parameterId === val.companyReportParameter.parameter.id).companyReportParameterIds} actionMethod={handleAction} />
                  </Box>
                  <Box>
                    {failMessageReview.length > 0 && compareBenchmarkList.find(value => value.parameterId === val.companyReportParameter.parameter.id).companyReportParameterIds.length > 0 ? (<Box><Text c='red' fz={8}>Peringatan:</Text>{mappingWarningMessage(failMessageReview)}</Box>) : ''}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Text fz={14} fw={600}>Simulasi Grafik Benchmark</Text>
                <Box my={14}>
                  {loadingPreview ? (<LoadingPreviewData />) : (<PreviewChartBenchmark dataPreview={dataPreviewBenchmark} countDataPreview={compareBenchmarkList.find(value => value.parameterId === val.companyReportParameter.parameter.id).companyReportParameterIds.length} />)}
                </Box>
              </Box>
            </Box>
          )
        }

        const stateFormKlhk = () => {

          const setSelectedDataKlhk = (e) => {
            const findData = klhkDataList.find(val => val.id === e)
            console.log(findData)
            findSelectedParams.klhkReportParameterId = findData
            setCompareBenchmarkList([...compareBenchmarkList])
          }

          const handleFileKlhkCustomChart = (fileUpload) => {
            // eslint-disable-next-line
            findSelectedParams.klhkReportCustomChart = fileUpload
            setCompareBenchmarkList([...compareBenchmarkList])
          }

          const handleOpenModalPreview = (fileImage) => {
            setOpenModalPreview(true)
            const findImage = findSelectedParams.klhkReportCustomChart
            setDataImagePreview(findImage)
          }

          const deleteImageChart = () => {
            findSelectedParams.klhkReportCustomChart = null
            setCompareBenchmarkList([...compareBenchmarkList])
          }

          const showDataCustomChartKlhk = (nameFile) => {
            if (nameFile) {
              return (
                <Flex justify='space-between'>
                  <CBadge name={nameFile} />
                  <Group>
                    <Tooltip label='Preview'>
                      <ActionIcon variant='transparent' color='indigo.9' onClick={() => handleOpenModalPreview(nameFile)}>
                        <IconEye stroke={1.5} size={18} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label='Hapus'>
                      <ActionIcon variant='transparent' color='red' onClick={() => deleteImageChart()}>
                        <IconTrash stroke={1.5} size={18} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </Flex>
              )
            }
          }


          return (
            <Box>
              <Box mb='md'>
                <Select
                  name="klhkReport"
                  label="Laporan Data Perdirjen"
                  placeholder="Pilih salah satu Laporan"
                  searchable
                  nothingFoundMessage='Laporan tidak ditemukan'
                  data={klhkReportList}
                  onChange={(e) => setSelectedDataKlhk(e)}
                  value={setValueKlhkParamsId(findSelectedParams.klhkReportParameterId)}
                  withAsterisk
                  checkIconPosition="right"
                  clearable
                />
              </Box>
              <Box mb='md'>
                <FileInput
                  name='benchmarkParameterCustomChart'
                  accept="image/png,image/jpeg"
                  label='Upload Gambar Grafik Data Perdirjen'
                  placeholder='Upload gambar grafik data perdirjen (Opsional)'
                  onChange={handleFileKlhkCustomChart}
                  value={findSelectedParams.klhkReportCustomChart}
                />
              </Box>
              <Box my={12}>
                {findSelectedParams.klhkReportCustomChart !== null ? showDataCustomChartKlhk(typeof findSelectedParams.klhkReportCustomChart === 'object' ? findSelectedParams.klhkReportCustomChart.name : findSelectedParams.klhkReportCustomChart) : '' }
              </Box>
              <Text fz={16} fw={600} mt={30} mb={10}>Preview Data</Text>
              <Box>
                <Box mb={20}>
                  <Text fz={14} fw={600}>Data Utama</Text>
                  <Box my={14}>
                    <TableSelectedCompanyReport label={mainLabelTable} data={mainBenchmarkReport} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Text fz={14} fw={600}>Data Perdirjen</Text>
                <Box my={14}>
                  <TableSelectedKlhkReport label={labelTableKlhk} data={findSelectedParams.klhkReportParameterId !== null && findSelectedParams.klhkReportParameterId !== undefined ? [findSelectedParams.klhkReportParameterId] : []} />
                </Box>
              </Box>
            </Box>
          )
        }

        return (
          <Tabs.Panel value={val.companyReportParameter.parameter.id} key={index}>
            <Divider my={14} />
            <Box my={36}>
              <Flex justify='flex-start' my={20}>
                <Button size='xs' loading={loadingNextStep} onClick={() => submitData('draft')}>Simpan ke Draft</Button>
              </Flex>
              <Flex justify={levelBenchmark === 'national' ? 'space-between' : 'flex-end'} my={16} h={30}>
                {stateDataKlhk(levelBenchmark)}
                {!findSelectedParams.useDataKlhk ? (<Button size='xs' onClick={() => setOpenModalSourceData(true)}>Tambah Sumber Data</Button>) : ''}
              </Flex>
              {!findSelectedParams.useDataKlhk ? dataCompareBenchmark() : stateFormKlhk()}
            </Box>
          </Tabs.Panel>
        )
      })
      return remapContent
    }
    
  }

  

  return (
    <Box mt={40} pos='relative'>
      <Box my={30}>
        <ScrollArea miw={768} scrollbars='x'>
          {loading ? <LoadingData /> : layoutDataBenchmark(defaultBenchmarkData)}
        </ScrollArea>
      </Box>
      <Group justify='space-between'>
        <Button variant="default" size='xs' onClick={toPrevStep}>Sebelumnya</Button>
        <Button size='xs' loading={loadingNextStep} onClick={() => submitData('next_step')}>Selanjutnya</Button>
      </Group>
      <Modal opened={openModalSourceData} onClose={onCloseModalSource} closeOnClickOutside={false} centered size='70%' title={<Text fw='Bold'>Sumber Data Benchmark</Text>} scrollAreaComponent={ScrollArea.Autosize}>
        <BenchmarkDataSource onCloseModal={onCloseModalSource} selectedParams={selectedParams} companyId={dataBenchmark.companyId} comparisonBenchmarkList={compareBenchmarkList} benchmarkLevel={levelBenchmark} handleRefreshChart={handleGetReviewBenchmark} />
      </Modal>
      <Modal opened={openModalPreview} onClose={onCloseModalPreview} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>Preview Grafik Data Perdirjen</Text>}>
        <PreviewImageChartKlhk dataImage={dataImagePreview} onCloseModal={onCloseModalPreview} />
      </Modal>
      <Modal opened={openModalIntensity} onClose={onCloseModalIntensity} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>Update Nilai Intensitas</Text>}>
        <Box pos='relative'>
          <Box mb='md'>
            <TextInput
              name='company'
              value={formDataIntensity.company}
              label='Nama Perusahaan'
              disabled
            />
          </Box>
          <Box mb='md'>
            <TextInput
              name='companyReport'
              value={formDataIntensity.companyReport}
              label='Sumber Data'
              disabled
            />
          </Box>
          <Box mb='md'>
            <TextInput
              name='year'
              value={formDataIntensity.year}
              label='Tahun'
              disabled
            />
          </Box>
          <Box mb='md'>
            <TextInput
              name='value'
              value={formDataIntensity.value}
              label='Konversi Jumlah'
              disabled
            />
          </Box>
          <Box mb='md'>
            <TextInput
              name='intensity'
              value={formDataIntensity.intensity}
              label='Intensitas'
              onChange={handleChange}
              type='number'
            />
          </Box>
          <Box mt={20}>
            <Flex justify='flex-end'>
              <Group>
                <Button variant="outline" color='indigo.9' onClick={onCloseModalIntensity}>Tutup</Button>
                <Button variant="filled" color='indigo.9' onClick={() => updateIntensity(formDataIntensity)}>Update</Button>
              </Group>
            </Flex>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default BenchmarkData