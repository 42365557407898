import React, { useState } from 'react'
import PETLogo from '../../../assets/img/PET_logo.png'
import { Box, Text, Center, Card, TextInput, PasswordInput, Button, Group, Anchor } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import classes from './Login.module.css'
import { validation } from '../../../plugins/validation'
import { login, getPermissionRole } from '../../../services/auth.js'
import { useDispatch } from 'react-redux'
import { update } from '../../../store/authData.js'
import { updatedRoute } from '../../../store/permissionRoute.js'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications/index.js'
import http from '../../../plugins/axios.js'
import { encodedText } from '../../../plugins/encrypt.js'
import Cookies from 'universal-cookie'
import { useNavigate } from 'react-router-dom'

const defaultVal = {
  email: '',
  password: ''
}

const formValidation = {
  email: {
    isError: false,
    message: ''
  },
  password: {
    isError: false,
    message: ''
  }
}

const Login = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width, height } = useViewportSize()
  const [ formData, setFormData ] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [ loading, setLoading ] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setValidationForm(formValidation)
    const isErrorForm = validation(formData, setValidationForm)
    if (isErrorForm) {
      setLoading(false)
      return
    }
    try {
      const response = await login(formData)
      if (response) {
        notificationSuccess('Login Berhasil', `Selamat datang ${response.fullName}`)
        http.defaults.headers.common['Authorization'] = `Bearer ${response.access_token}`
        const stringValue = JSON.stringify(response)
        const encodedData = encodedText(stringValue)
        cookies.set('token', encodedData, { path: '/' })
        getHandlePermissionRole()
        dispatch(update({ 'dataUser': response, 'isLogin': true }))
        navigate('/dashboard')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Login Gagal', `${errorMessage ? errorMessage : 'Silahkan periksa kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }

  const getHandlePermissionRole = async () => {
    try {
      const response = await getPermissionRole()
      dispatch(updatedRoute({ 'access': response }))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box className={classes.loginPage} style={{ backgroundImage: `linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)`, minHeight: `${height}px`, minWidth: `${width}px`  }}>
      <Center maw={width / 1.2} h={height} mx='auto'>
        <Card className={classes.loginFormLayout} shadow='sm' radius='md' withBorder>
          <Box>
            <Box ta='center' mb={30}>
              <img src={PETLogo} width='160' alt='logo-katalis' />
              <Text fz='xl' fw='bold' mt={10}>Selamat Datang</Text>
              <Text fz='sm' c='gray.6'>Silahkan masukkan email dan password untuk login</Text>
            </Box>
            <Box>
              <form onSubmit={(e) => {handleSubmit(e)}}>
                <Box mb={30}>
                  <Box mb='md'>
                    <TextInput
                      name='email'
                      label='Email'
                      placeholder='Masukkan email'
                      error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
                      onChange={handleChange}
                      withAsterisk
                    />
                  </Box>
                  <Box mb='md'>
                    <PasswordInput
                      name='password'
                      placeholder='Masukkan Password'
                      label='Password'
                      error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                      onChange={handleChange}
                      withAsterisk
                    />
                  </Box>
                </Box>
                <Box>
                  <Group grow >
                    <Button radius='md' size='md' loading={loading} color='indigo.9' type='submit'>Login</Button>
                  </Group>
                </Box>
              </form>
              <Box align="center" mt={20} mb={10}>
                <Anchor component="button" c="blue" fz={13} onClick={() => navigate('/password-resets')}>Lupa password</Anchor>
              </Box>
            </Box>
          </Box>
        </Card>
      </Center>  
    </Box>
  )
}

export default Login