import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getPermissionRole } from '../services/auth'

export const getPermissionRoute = createAsyncThunk('permission/getPermissionRoute', async () => {
  const response = await getPermissionRole()
  return response
})

const permissionRoute = createSlice({
  name: 'permission',
  initialState: {
    isLoadingPermission: true,
    access: null
  },
  reducers: {
    updatedRoute: (state, action) => {
      state.isLoadingPermission = action.payload.isLoadingPermission
      state.access = action.payload.access
    }
  },
  extraReducers: {
    [getPermissionRoute.pending]: (state) => {
      state.isLoadingPermission = true
    },
    [getPermissionRoute.fulfilled]: (state, action) => {
      state.isLoadingPermission = false
      state.access = action.payload
    },
    [getPermissionRoute.rejected]: (state) => {
      state.isLoadingPermission = false
      state.access = null
    },
  }
})

export const { updatedRoute } = permissionRoute.actions
export default permissionRoute.reducer