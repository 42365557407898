import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import classes from '../Auth.module.css'
import { Text, Center, Loader, Box } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { getAllSummaries } from '../../../services/summaries'
import StatisticData from '../../../components/pages/Dashboard/StatisticData'
const LoadingPreviewData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.4}>
      <Loader color='blue' size='xl' type='dots' />
    </Center>
  )
}

const Dashboard = () => {
  const [loadingSummaries, setLoadingSummaries] = useState(true)
  const [summaries, setAllSummaries] = useState(null)
  
  const handleGetSummaries = async () => {
    setLoadingSummaries(true)
    try {
      const response = await getAllSummaries()
      setAllSummaries(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSummaries(false)
    }
  }

  useEffect(() => {
    handleGetSummaries()
  }, [])


  return (
    <AuthLayout>
      <Text className={classes.titlePage} mb={20}>Selamat Datang di PET Benchmark</Text>
      <Box>
        {loadingSummaries ? (<LoadingPreviewData />) : (<StatisticData data={summaries} />)}
      </Box>
      
    </AuthLayout>
    
  )
}

export default Dashboard