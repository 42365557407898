import React from 'react'
import { Paper, Text } from '@mantine/core'

const CBadge = ({ name }) => {
  return (
    <Paper shadow='xs' radius='xl' p={4} style={{ backgroundColor: 'rgb(54, 79, 199)' }} w='auto'>
      <Text fz={12} mx={8} lineClamp={1} c='white'>{name}</Text>
    </Paper>
  )
}

export default CBadge