import React, { useRef } from 'react'
import ProtectedRoute from '../middleware/ProtectedRoute'
import { AppShell, Box, ScrollArea, Flex, ThemeIcon, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import AuthHeader from '../components/ui/AppShell/AuthHeader'
import AuthSidebar from '../components/ui/AppShell/AuthSidebar'
import { IconLogout } from '@tabler/icons-react'
import Cookies from 'universal-cookie'
import { useDispatch } from 'react-redux'
import { update } from '../store/authData'
import { useNavigate } from 'react-router-dom'

const AuthLayout = ({ children }) => {
  const viewportRef = useRef(null)
  const cookies = new Cookies()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true)
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure()

  const handleLogout = () => {
    cookies.remove('token')
    dispatch(update({ 'dataUser': null, 'isLogin': false }))
    navigate('/login')
  }
  
  return (
    <ProtectedRoute>
      <AppShell
        transitionDuration={700}
        transitionTimingFunction="ease"
        offsetScrollbars={false}
        header={{ height: 70 }}
        navbar={{
          width: 280,
          breakpoint: 'sm',
          collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop }
        }}
        padding='md'
      >
        <AppShell.Header>
          <AuthHeader mobileToggle={toggleMobile} desktopToggle={toggleDesktop} />
        </AppShell.Header>
        <AppShell.Navbar style={{ backgroundColor: '#364fc7' }}>
          <AppShell.Section grow component={ScrollArea} viewportRef={viewportRef}>
            <AuthSidebar />
          </AppShell.Section>
          <AppShell.Section>
            <Box p='lg' style={{ borderTop: '1px solid gainsboro' }}>
              <Flex ml={12} align='center' style={{ cursor: 'pointer' }} onClick={handleLogout}>
                <ThemeIcon variant="light" color='white' size='md' radius='md' mr={15}>
                  <IconLogout stroke={1.5} size={18} />
                </ThemeIcon>
                <Text fz={13} fw='bold' c='white'>Keluar</Text>
              </Flex>
            </Box>
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>
          <Box p={14}>
            {children}
          </Box>
        </AppShell.Main>
      </AppShell>
    </ProtectedRoute>
  )
}

export default AuthLayout