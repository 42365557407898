import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button, TextInput, Select } from '@mantine/core'
import classes from '../Auth.module.css'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { getListUnit, deleteUnit } from '../../../services/unit-conversion'
import TableUnit from '../../../components/pages/Unit/TableUnit'
import FormUnit from '../../../components/pages/Unit/FormUnit'
import { TableConversion } from '../../../components/pages/Unit/TableConversion'
import { useSelector } from 'react-redux'

const defaultParameter = {
  skip: 0,
  take: 10,
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Satuan Unit',
    width: 'auto'
  },
  {
    label: 'Simbol Satuan Unit',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const Unit = () => {
  const permissionState = useSelector(state => state.permission.access)
  const [params, setParams] = useState(defaultParameter)
  const [unitList, setUnitList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [openModalConversion, setOpenModalConversion] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState('createdAt')
  const [selectedOrder, setSelectedOrder] = useState('desc')

  const mappingOrder = [
    {
      value: 'asc',
      label: selectedFilter === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: selectedFilter === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetUnitList = async () => {
    setLoading(true)
    try {
      const response = await getListUnit(params)
      setUnitList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetUnitList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
    }
    setParams(params)
  }

  const handleSelectFilter = (filterOrder) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: filterOrder,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: orders
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    conversion: (val) => {
      setOpenModalConversion(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('satuan unit', val.id, val.name, deleteUnit, handleGetUnitList)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalConversion = () => {
    setOpenModalConversion(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionUnit = permissionState['unit']
    const createdPermission = permissionUnit.find(val => val.alias === 'unit.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Satuan Unit</Button>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Manajemen Satuan Unit</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Unit Konversi. . .'
              name="search"
              onChange={handleSearchChange}
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [setSelectedFilter(e), handleSelectFilter(e)]}
              value={selectedFilter}
              checkIconPosition="right"
              allowDeselect={false}
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableUnit label={labelTable} data={unitList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Satuan Unit</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormUnit dataUnit={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetUnitList} />
      </Modal>
      <Modal opened={openModalConversion} onClose={onCloseModalConversion} centered closeOnClickOutside={false} size='60%' title={<Text fw='Bold'>{detailData !== null ? detailData.name : ''} - List Konversi</Text>} withCloseButton={false}> 
        <TableConversion unit={detailData} onClose={onCloseModalConversion} />
      </Modal>
    </AuthLayout>
    
  )
}

export default Unit