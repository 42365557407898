import React from 'react'
import { Table, Badge, Menu, ActionIcon } from '@mantine/core'
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react'

const ActionMenu = ({ handleAction }) => {
  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={18} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={12}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={18} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={12}
        >
          Hapus
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const TableUsers = ({ label, data, actionMethod }) => {

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.fullName}</Table.Td>
        <Table.Td>{val.email}</Table.Td>
        <Table.Td>{val.role.name}</Table.Td>
        <Table.Td><Badge size="xs" radius="sm" variant="filled" color={val.isActive ? 'green' : 'red'}>{val.isActive ? 'Active' : 'Non Active'}</Badge></Table.Td>
        <Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} /></Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableUsers