import React, { useState } from 'react'
import { Menu, ActionIcon, Accordion, Text, Box, Card, Flex, Divider, Badge } from '@mantine/core'
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react'
import dayjs from 'dayjs'
import CompanyReportParams from './CompanyReportParams'

const ActionMenu = ({ handleAction }) => {
  return (
    <Menu width={170} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={18} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={12}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={18} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={12}
        >
          Hapus
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}


const TableCompanyReport = ({ data, actionMethod }) => {
  const [idReportParams, setIdReportParams] = useState(null)

  const mappingDataReport = (dataReport) => dataReport.map((val) => {
    return (
      <Accordion.Item value={val.id} key={val.id} style={{ cursor: 'pointer' }}>
        <Card padding={0} radius='xs' withBorder>
          <Flex justify="space-between" align="center" px={8} pt={8}>
            <Text fz="xs">{dayjs(val.createdAt).format("DD-MMM-YYYY")}</Text>
            <Badge size="xs" radius="sm" variant="filled" color={val.type === 'benchmark' ? 'green' : 'red'}>{val.type}</Badge>
          </Flex>
          <Divider my="xs" />
          <Flex justify='space-between' align='start' pr={18}>
            <Accordion.Control onClick={() => setIdReportParams(val.id)}>
              <Box ml={20} h={70}>
                <Text fz="xs" fw="bold" mb={2}>{val.name}</Text>
                <Text fz="xs"><Text component='span' fw='600' fz='xs'>Tahun</Text> : {val.year}</Text>
              </Box>
            </Accordion.Control>
            <ActionMenu handleAction={(type) => actionMethod(val, type)} />
          </Flex>
        </Card>
        <Accordion.Panel>
          <Box pt={10}>
            <CompanyReportParams reportParamsId={idReportParams} reportName={val.name} />
          </Box>
        </Accordion.Panel>
      </Accordion.Item>
    )
  })

  if (data.length > 0) {
    return (
      <Accordion variant='separated' chevronPosition='left' mx='auto'>
        {mappingDataReport(data)}
      </Accordion>
    )
  } else {
    return (
      <Box align="center" my={100}>
        <Text fz="md" fw="600" c="gray.6">Perusahaan ini belum memiliki laporan</Text>
      </Box>
    )
  }
}

export default TableCompanyReport