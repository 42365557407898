export function formatPrice(value) {
  if (Number(value) === 0) {
    return 'FREE'
  }
  
  const val = (value / 1).toFixed().replace('.', ',')
  return 'Rp ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function thousandFormat(val) {
  return Intl.NumberFormat('id-ID').format(val)
}

export function decimalFormat(val) {
  return Intl.NumberFormat('id-ID', {maximumFractionDigits: 12}).format(val)
}

export function groupBy(array, result) {
  // eslint-disable-next-line
  return array.reduce((prevValue, currentValue, index) => ((prevValue[result(currentValue, index, array)]||=[]).push(currentValue), prevValue), {})
}

export function decimalCheck(val){
  return val % 1 === 0
}