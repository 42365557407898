import React from 'react'
import { Box, LoadingOverlay } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'

const LoadingPage = () => {
  const { width, height } = useViewportSize()
  return (
    <Box pos='relative' h={height} w={width}>
      <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: "md", blur: 3 }} />
    </Box>
  )
}

export default LoadingPage