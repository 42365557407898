import http from '../plugins/axios'

export const getListBenchmarker = async (params) => {
  try {
    const response = await http.get(`/benchmarkers`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addBenchmarker = async (payload) => {
  try {
    const response = await http.post(`/benchmarkers`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateBenchmarker = async (benchmarkerId, payload) => {
  try {
    const response = await http.patch(`/benchmarkers/${benchmarkerId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteBenchmarker = async (benchmarkerId) => {
  try {
    const response = await http.delete(`/benchmarkers/${benchmarkerId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const attachImageBenchmarker = async (benchmarkerId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/benchmarkers/${benchmarkerId}/image`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}