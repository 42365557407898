import React from 'react'
import { Table, Box, Text } from '@mantine/core'

const TableSelectedKlhkReport = ({ label, data }) => {
  
  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.klhkReport.name}</Table.Td>
        <Table.Td>{val.mean}</Table.Td>
        <Table.Td>{val.percentile25}</Table.Td>
        <Table.Td>{val.percentile75}</Table.Td>
      </Table.Tr>
    )
  })

  if (data.length > 0) {
    return (
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    )
  } else {
    return (  
      <Box ta='center' my={50}>
        <Text fz={13} mb={6} c='gray.6'>"Belum ada sumber data yang terpilih"</Text>
      </Box>
    )
  }
  
}

export default TableSelectedKlhkReport