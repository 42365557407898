import React, { useState, useEffect } from 'react'
import { Box, Flex, Group, Button, FileInput, Text, Tooltip, ActionIcon } from '@mantine/core'
import { notificationSuccess, notificationError } from '../../ui/Notifications'
import { uploadReport } from '../../../services/benchmark-report'
import CBadge from '../../ui/CBadge'
import { getFile } from '../../../services/file'
import { IconDownload, IconTrash } from '@tabler/icons-react'

const formValidation = {
  file: {
    isError: false,
    message: ''
  }
}

const FormDocumentBenchmark = ({ dataResult, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false)
  const [fileDocument, setFileDocument] = useState(null)
  const [prevFile, setPrevFile] = useState(null)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [selectedBenchmarkId, setSelectedBenchmarkId] = useState(null)

  useEffect(() => {
    if (dataResult !== null) {
      handleSetForm(dataResult)
    }
    // eslint-disable-next-line
  }, [dataResult])

  const handleSetForm = (value) => {
    setSelectedBenchmarkId(value.id)
    setFileDocument(value.customFile)
    setPrevFile(value.customFile)
  }

  const handleFile = (fileUpload) => {
    setFileDocument(fileUpload)
  }

  const attachFileDocument = async () => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    if (fileDocument === null) {
      setValidationForm({
        file: {
          isError: true,
          message: 'Silahkan upload laporan terlebih dahulu'
        }
      })
      setLoadingForm(false)
      return
    }
    const form = new FormData()
    form.append('file', fileDocument)
    try {
      await uploadReport(selectedBenchmarkId, form)
      onCloseModal()
      reloadList()
      notificationSuccess('Upload Laporan Berhasil', 'Anda telah berhasil menambahkan laporan')
    } catch (error) {
      notificationError('Upload Laporan Gagal', 'Anda gagal menambahkan laporan')
      console.log(error)
    } finally {
      setLoadingForm(false)
    }
  }

  const showDataFile = (dataSource) => {
    if (dataSource !== null) {
      const downloadFile = () => {
        window.open(`${getFile(dataSource)}`, '_blank')
      }

      const deleteAttachFile = async () => {
        const form = new FormData()
        form.append('file', null)
        try {
          await uploadReport(selectedBenchmarkId, form)
          reloadList()
          notificationSuccess('Hapus Laporan Berhasil', 'Anda telah berhasil menghapus laporan')
          setPrevFile(null)
          setFileDocument(null)
        } catch (error) {
          notificationError('Hapus Laporan Gagal', 'Anda gagal menghapus laporan')
          console.log(error)
        }
      }

      return (
        <Box>
          <Text fz={14} mb={8} fw={500}>File Sebelumnya</Text>
          <Flex justify='space-between'>
            <Box w="50%">
              <CBadge name={dataSource} />
            </Box>
            <Group>
              <Tooltip label='Unduh'>
                <ActionIcon variant='transparent' color='indigo.9' onClick={downloadFile}>
                  <IconDownload stroke={1.5} size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label='Hapus'>
                <ActionIcon variant='transparent' color='red' onClick={deleteAttachFile}>
                  <IconTrash stroke={1.5} size={18} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Flex>
        </Box>
        
      )
    } 
  }


  return (
    <Box>
      <Box>
        <Box mb='md'>
          <FileInput
            name='file'
            value={fileDocument}
            accept="application/msword"
            label='Upload Laporan'
            placeholder='Upload file Laporan'
            error={validationForm.file.isError ? `${validationForm.file.message}` : ''}
            onChange={handleFile}
            withAsterisk
            clearable
          />
        </Box>
        <Box mb='md'>
          {prevFile !== null ? showDataFile(prevFile) : ''}
        </Box>
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => attachFileDocument()}>Simpan</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormDocumentBenchmark