import React from 'react'
import { Table, Menu, ActionIcon } from '@mantine/core'
import { IconDotsVertical, IconTrash, IconPencil, IconBuilding } from '@tabler/icons-react'

const defaultLevel = {
  'national': 'Nasional',
  'international': 'Internasional'
}

const defaultContinent = {
  'asia': 'Asia',
  'afrika': 'Afrika',
  'eropa': 'Eropa',
  'amerika_utara': 'Amerika Utara',
  'amerika_selatan': 'Amerika Selatan',
  'australia': 'Australia',
  'oseania': 'Oseania'
}


const ActionMenu = ({ handleAction, dataClient }) => {

  const mappingMenuDataClient = (isClient) => {
    if (isClient) {
      return (
        <Menu.Item
          leftSection={<IconBuilding stroke={1.5} size={18} />}
          onClick={() => handleAction('client')}
          fw='600'
          fz={12}
        >
          Profil Perusahaan
        </Menu.Item>
      )
    }
  }
  return (
    <Menu width={190} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={18} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={12}
        >
          Edit
        </Menu.Item>
        {mappingMenuDataClient(dataClient)}
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={18} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={12}
        >
          Hapus
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const TableCompanies = ({ label, data, countData, actionMethod }) => {
  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + countData + 1}.</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td>{defaultLevel[val.level]}</Table.Td>
        <Table.Td>{val.continent ? defaultContinent[val.continent] : '-'}</Table.Td>
        <Table.Td>{val.industry.name}</Table.Td>
        <Table.Td>{val.country.name}</Table.Td>
        <Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} dataClient={val.isClient} /></Table.Td>
      </Table.Tr>
    )
  })
  return (
    <Table.ScrollContainer minWidth={768}>
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableCompanies