import React from 'react'
import classes from './AuthHeader.module.css'
import PetLogo from '../../../../assets/img/PET_logo.png'
import { Box, Burger, Flex, Group, Text, Avatar } from '@mantine/core'
import { useSelector } from 'react-redux'


const AuthHeader = ({ mobileToggle, desktopToggle }) => {
  const dataUser = useSelector(state => state.auth.dataUser)
  return (
    <header className={classes.root}>
      <Box className={classes.sectionHeader}>
        <Group gap='xs' align='center'>
          <Burger onClick={mobileToggle} hiddenFrom='sm' size='sm' />
          <Burger onClick={desktopToggle} visibleFrom='sm' size={20} />
          <img src={PetLogo} width='100' alt='pet-logo' />
        </Group>
        <Box>
          <Flex align='center'>
            <Avatar variant='light' src={null} radius='xl' size={45} color='indigo.9' />
            <Box ml={8}>
              <Text c="indigo.9" fw='bold' fz={13}>{dataUser.fullName}</Text>
              <Text c="indigo.9" fz={12}>{dataUser.role}</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </header>
  )
}

export default AuthHeader