import http from '../plugins/axios'

export const getListBenchmarkReport = async (params) => {
  try {
    const response = await http.get(`/benchmark-reports`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailBenchmarkReport = async (benchmarkId) => {
  try {
    const response = await http.get(`/benchmark-reports/${benchmarkId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addBenchmarkReport = async (payload) => {
  try {
    const response = await http.post(`/benchmark-reports`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateBenchmarkReport = async (benchmarkId, payload) => {
  try {
    const response = await http.patch(`/benchmark-reports/${benchmarkId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteBenchmarkReport = async (benchmarkId) => {
  try {
    const response = await http.delete(`/benchmark-reports/${benchmarkId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignBenchmarkReportComparison = async (benchmarkId, payload) => {
  try {
    const response = await http.post(`/benchmark-reports/${benchmarkId}/comparison`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadApprovalSheet = async (benchmarkId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/benchmark-reports/${benchmarkId}/approval-sheet`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadEventReport = async (benchmarkId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/benchmark-reports/${benchmarkId}/event-report`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadCoverLetter = async (benchmarkId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/benchmark-reports/${benchmarkId}/cover-letter`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadReport = async (benchmarkId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/benchmark-reports/${benchmarkId}/custom-file`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}
