import http from '../plugins/axios'

export const getListUnit = async (params) => {
  try {
    const response = await http.get(`/units`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addUnit = async (payload) => {
  try {
    const response = await http.post(`/units`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUnit = async (unitId, payload) => {
  try {
    const response = await http.patch(`/units/${unitId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteUnit = async (unitId) => {
  try {
    const response = await http.delete(`/units/${unitId}`)
    return response.data
  } catch(error) {
    throw error
  }
}


export const getListUnitConversion = async (params) => {
  try {
    const response = await http.get(`/unit-conversions`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addUnitConversion = async (payload) => {
  try {
    const response = await http.post(`/unit-conversions`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUnitConversion = async (unitId, payload) => {
  try {
    const response = await http.patch(`/unit-conversions/${unitId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteUnitConversion = async (unitId) => {
  try {
    const response = await http.delete(`/unit-conversions/${unitId}`)
    return response.data
  } catch(error) {
    throw error
  }
}