import React, { useEffect, useState } from 'react'
import { Box, Fieldset, Text, List, ListItem, Flex, Rating, Group, Button, Center, Loader, Image } from '@mantine/core'
import { getDataBenchmarkReportReview } from '../../../services/benchmark'
import TableCompanyBenchmark from './TableCompanyBenchmark'
import TableSelectedKlhkReport from '../Benchmark/TableSelectedKlhkReport'
import { decimalCheck, thousandFormat, decimalFormat } from '../../../plugins/addons'
import { IconDownload } from '@tabler/icons-react'
import PreviewChartBenchmark from '../Benchmark/PreviewChartBenchmark'
import { getImageFile } from '../../../services/file'

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Perusahaan',
    width: 300
  },
  {
    label: 'Konversi Pemakaian/Timbulan',
    width: 250
  },
  {
    label: 'Intensitas',
    width: 200
  },
  {
    label: 'Angka Distribusi',
    width: 'auto'
  },
]

const labelTableKlhk = [
  {
    label: 'No.',
    width: 20
  },
  {
    label: 'Data Perdirjen',
    width: 'auto'
  },
  {
    label: '50% Rata-Rata',
    width: 'auto'
  },
  {
    label: '25% Teratas',
    width: 'auto'
  },
  {
    label: '25% Terbawah',
    width: 'auto'
  },
]

const BenchmarkChart = ({ dataBenchmarkComparison }) => {
  const [dataPreview, setDataPreview] = useState(null)
  const [loadingChart, setLoadingChart] = useState(true)

  const handleDataChart = async (data) => {
    setLoadingChart(true)

    const filterDataCompare = data.benchmarkParameterComparisons.filter((val) => val.isMain === false)
    const filterDataKlhk = filterDataCompare.filter((val) => val.comparisonReportParameter !== null)
    const remapCompanyReportParameterId = filterDataKlhk.map((val) => {
      const remap = {
        id: val.comparisonReportParameter.id,
        customIntensity: val.comparisonReportParameter.customIntensity !== null ? val.comparisonReportParameter.customIntensity : val.comparisonReportParameter.intensity
      }
      return remap
    })

    const payload = {
      benchmarkParameterId: data.id,
      companyReportParameters: remapCompanyReportParameterId,
      klhkReportParameterId: null
    }

    if (payload.companyReportParameters.length > 0) {
      try {
        const response = await getDataBenchmarkReportReview(payload)
        setDataPreview(response.data)
        setLoadingChart(false)
      } catch (error) {
        console.log(error)
        setLoadingChart(false)
      }
    } else {
      setLoadingChart(false)
    }
    
  }
  useEffect(() => {
    handleDataChart(dataBenchmarkComparison)
    
  }, [dataBenchmarkComparison])

  const LoadingPreviewData = () => {
    return (
      <Center h={200}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }

  const mappingDataPreview = (data) => {
    if (data !== null) {
      return (
        <Box>
          <Text fw={600} mb={12}>Grafik Benchmark</Text>
          <Box>
            <PreviewChartBenchmark dataPreview={data} countDataPreview={dataBenchmarkComparison.benchmarkParameterComparisons.length} />
          </Box>
        </Box>
      )
    } else if (dataBenchmarkComparison.customChart !== null) {
      return (
        <Box>
          <Text fw={600} mb={12}>Grafik Benchmark</Text>
          <Box ta='center'>
            <Image radius='md' h={300} w='auto' fit='contain' src={getImageFile(dataBenchmarkComparison.customChart)} />
          </Box>
        </Box>
      )
    } else {
      return (
        <Box>
          <Text fw={600} mb={12}>Grafik Benchmark</Text>
          <Box ta='center'>
            <Text fz={13} my={40}>Data ini tidak memiliki grafik / gambar</Text>
          </Box>
        </Box>
      )
    }
  }

  return (
    <ListItem mb={18}>
      <Box miw={768}>
        {loadingChart ? (<LoadingPreviewData/>) : mappingDataPreview(dataPreview)}
      </Box>
    </ListItem>
  )
}

const DetailBenchmarkResult = ({ detailBenchmark, dataResult }) => {

  const compareDataBenchmark = (dataCompany, parameter, rankingBenchmark) => {
    if (dataCompany.length > 0) {
      const comparedDataCompany = dataCompany.filter(val => !val.isMain)
      const comparedDataKlhkParams = dataCompany.find(val => val.klhkReportParameter !== null)
      return (
        <ListItem mb={18}>
          <Box>
            <Text fw={600} mb={4}>Data Pembanding</Text>
            <List>
              <ListItem>
                <Box mb={16}>
                  <Text fz={15} fw={600} mb={12}>Data Perusahaan</Text>
                  <TableCompanyBenchmark label={labelTable} data={dataCompany} parameter={parameter} />
                  {comparedDataKlhkParams ? (<Box mt={12}><TableSelectedKlhkReport label={labelTableKlhk} data={[comparedDataKlhkParams.klhkReportParameter]} /></Box>) : ''}
                </Box>
              </ListItem>
              <ListItem>
                <Box>
                  <Text fz={15} fw={600} mb={12}>Ranking Benchmark</Text>
                  <Group gap='sm'>
                    <Rating defaultValue={rankingBenchmark} size={20} fractions={comparedDataCompany.length} readOnly />
                    <Text fz={13} fw={500}>/  dari {comparedDataCompany.length} Perusahaan</Text>
                  </Group>
                </Box>
              </ListItem>
            </List>
          </Box>
        </ListItem>
      )
    }
  }

  const mappingBenchmarkLevelResult = (array) => {
    if (array) {
      const mappingLevel = array.map((val, index) => {

        return (
          <Box key={index} mb={24}>
            <Text fz={18} fw='700' td='underline' tt='capitalize' mb={8}>Level {val.level}</Text>
            <Box mb={6}>
              <List type='ordered' fw={600}>
                <ListItem mb={18}>
                  <Box>
                    <Text fw={600} mb={4}>Hasil Benchmark</Text>
                    <List>
                      <ListItem>
                        <Flex>
                          <Text fz={15} fw={600} mr={24}>50% Rata - Rata</Text>
                          <Text fz={15} fw={500}>:  {val.mean !== null ? decimalCheck(val.mean) ? thousandFormat(val.mean) : decimalFormat(val.mean) : 0} {val.companyReportParameter.parameter.benchmarkParameterUnit}</Text>
                        </Flex>
                      </ListItem>
                      <ListItem>
                        <Flex>
                          <Text fz={15} fw={600} mr={50}>25% Teratas</Text>
                          <Text fz={15} fw={500}>:  {val.percentile25 !== null ? decimalCheck(val.percentile25) ? thousandFormat(val.percentile25) : decimalFormat(val.percentile25) : 0} {val.companyReportParameter.parameter.benchmarkParameterUnit}</Text>
                        </Flex>
                      </ListItem>
                      <ListItem>
                        <Flex>
                          <Text fz={15} fw={600} mr={35}>25% Terbawah</Text>
                          <Text fz={15} fw={500}>:  {val.percentile75 !== null ? decimalCheck(val.percentile75) ? thousandFormat(val.percentile75) : decimalFormat(val.percentile75) : 0} {val.companyReportParameter.parameter.benchmarkParameterUnit}</Text>
                        </Flex>
                      </ListItem>
                    </List>
                  </Box>
                </ListItem>
                {compareDataBenchmark(val.benchmarkParameterComparisons, val.companyReportParameter.parameter, val.ranking)}
                <BenchmarkChart dataBenchmarkComparison={val} />
              </List>
            </Box>
          </Box>
        )
      })
      return mappingLevel
    }
  }

  const mappingBenchmarkParameter = (array) => {
    if (array.length > 0) {
      const mappingData = array.map((val, index) => {
        return (
          <Fieldset key={index} mb={40} legend={<Text fz={22} fw='bold'>{val[0].companyReportParameter.parameter.name}</Text>} variant='filled'>
            {mappingBenchmarkLevelResult(val)}
          </Fieldset>
        )
      })
      return mappingData
    }
  }

  const openUrl = () => {
    // eslint-disable-next-line
    window.open(`${process.env.REACT_APP_SERVER_API_URL}/files/storage/` + `${detailBenchmark.file}`, '_blank')
  }

  return (
    <Box>
      <Text fz={24} fw='bold' mb={30}>Hasil Benchmarking "{detailBenchmark.name}"</Text>
      <Flex justify='flex-end'>
        {detailBenchmark !== null ? (<Button size="xs" leftSection={<IconDownload size="1rem" />} onClick={openUrl}>Download File Benchmark</Button>) : ''}
      </Flex>
      <Box>
        <Box mb={16}>
          <Text fz={13}>Nama Perusahaan:</Text>
          <Text fz={13} fw='600'>{detailBenchmark.companyReport.company.name}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Nama Laporan:</Text>
          <Text fz={13} fw='600'>{detailBenchmark.name}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Deskripsi:</Text>
          <Text fz={13} fw='600'>{detailBenchmark.description}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Benchmarker:</Text>
          <Text fz={13} fw='600'>{detailBenchmark.benchmarker.name}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Pembuat:</Text>
          <Text fz={13} fw='600'>{detailBenchmark.createdBy.fullName}</Text>
        </Box>
      </Box>
      <Box>
        {mappingBenchmarkParameter(dataResult)}
      </Box>
    </Box>
  )
}

export default DetailBenchmarkResult

