import http from '../plugins/axios'

export const getListAspect = async (params) => {
  try {
    const response = await http.get(`/aspects`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}
export const getDetailAspect = async (aspectId) => {
  try {
    const response = await http.get(`/aspects/${aspectId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addAspect = async (payload) => {
  try {
    const response = await http.post(`/aspects`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateAspect = async (aspectId, payload) => {
  try {
    const response = await http.patch(`/aspects/${aspectId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteAspect = async (aspectId) => {
  try {
    const response = await http.delete(`/aspects/${aspectId}`)
    return response.data
  } catch(error) {
    throw error
  }
}