import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../layouts/AuthLayout'
import { getListBenchmarkParameter } from '../../../../services/benchmark'
import { getDetailBenchmarkReport } from '../../../../services/benchmark-report'
import { useParams } from 'react-router-dom'
import { Box, Center, Loader, Affix, Transition, Button, rem } from '@mantine/core'
import DetailBenchmarkResult from '../../../../components/pages/BenchmarkResult/DetailBenchmarkResult'
import { groupBy } from '../../../../plugins/addons'
import { useViewportSize, useWindowScroll } from '@mantine/hooks'
import { IconArrowUp } from '@tabler/icons-react'

const defaultCompany = {
  name: '',
  description: '',
  companyReport: {
    company: {
      name: ''
    }
  },
  benchmarker: {
    name: ''
  },
  createdBy: {
    fullName: ''
  }
}

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader color='blue' size='xl' type='dots' />
    </Center>
  )
}

const DetailbenchmarkComparison = () => {
  const [scroll, scrollTo] = useWindowScroll()
  const params = useParams()
  const benchmarkId = params.id
  const [loading, setLoading] = useState(true)
  const [detailBenchmark, setDetailBenchmark] = useState(defaultCompany)
  const [dataBenchmarkResult, setDataBenchmarkResult] = useState([])

  const handleGetBenchmarkReport = async () => {
    const params = {
      benchmarkReport: benchmarkId,
      // chartPoints: true
    }
    try {
      const responseDetailbenchmark = await getDetailBenchmarkReport(benchmarkId)
      const responseDataBenchmarkParams = await getListBenchmarkParameter(params)
      const dataBenchmarkParams = responseDataBenchmarkParams.data
      setDetailBenchmark(responseDetailbenchmark)
      const groupParameter = groupBy(dataBenchmarkParams, val => val.companyReportParameter.parameter.id)
      setDataBenchmarkResult(Object.values(groupParameter))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetBenchmarkReport()
    // eslint-disable-next-line
  }, [benchmarkId])


  return (
    <AuthLayout>
      <Box>
        {loading ? <LoadingData/> : <DetailBenchmarkResult detailBenchmark={detailBenchmark} dataResult={dataBenchmarkResult} />}
      </Box>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftSection={<IconArrowUp style={{ width: rem(16), height: rem(16) }} />}
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              Scroll Ke Atas
            </Button>
          )}
        </Transition>
      </Affix>      
    </AuthLayout>
  )
}

export default DetailbenchmarkComparison