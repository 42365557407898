import React, { useState, useEffect } from 'react'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button, TextInput, Skeleton } from '@mantine/core'
import { getListCompanyReport, deleteCompanyReport } from '../../../../../services/company-report'
import { modalDeleteData } from '../../../../../components/ui/ModalManager/modalDeleteData'
import TableCompanyReport from './TableCompanyReport'
import FormCompanyReport from './FormCompanyReport'

const CompanyReportTable = ({ companyId }) => {
  const defaultParameter = {
    company: companyId,
    skip: 0,
    take: 5,
    search: '',
    type: 'comparison'
  }
  const [params, setParams] = useState(defaultParameter)
  const [reportList, setReportList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)

  const handleGetReportList = async () => {
    setLoading(true)
    try {
      const response = await getListCompanyReport(params)
      setReportList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    handleGetReportList()
    // eslint-disable-next-line
  }, [params]) 

  const handleSearchChange = (e) => {
    const params = {
      company: companyId,
      skip: 0,
      take: 5,
      search: e.target.value,
      type: 'comparison'
    }
    setParams(params)
  }

  const handleChangePage = (e) => {
    const params = {
      company: companyId,
      skip: (e - 1) * 5,
      take: 5,
      search: '',
      type: 'comparison'
    }
    setParams(params)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('laporan perusahaan', val.id, val.name, deleteCompanyReport, handleGetReportList)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i}>
        <Skeleton height={120} width="100%" radius="md" mb="md" />
      </Box>
    )
  })
  return (
    <Box>
      <Box>
        <Box>
          <Flex justify='flex-end' mb={14}>
            <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Laporan</Button>
          </Flex>
          <Flex justify='flex-start'>
            <TextInput
              size='xs'
              placeholder='Cari Laporan. . .'
              name="search"
              onChange={handleSearchChange}
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? loadingData(5) : <TableCompanyReport data={reportList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Laporan</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormCompanyReport companyId={companyId} dataReport={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetReportList} />
      </Modal>
    </Box>
  )
}

export default CompanyReportTable