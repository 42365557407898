import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, Button, TextInput, Select, Center, Loader } from '@mantine/core'
import classes from '../Auth.module.css'
import { getListKlhkReport, deleteKlhkReport, assignLockKlhkReport, getDetailKlhkReportById } from '../../../services/klhk-report'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { modalSetStatusReport } from '../../../components/ui/ModalManager/modalSetStatusReport'
import TableKlhk from '../../../components/pages/KlhkReport/TableKlhk'
import FormKlhk from '../../../components/pages/KlhkReport/FormKlhk'
import FormKlhkAttachment from '../../../components/pages/KlhkReport/FormKlhkAttachment'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc'
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
  {
    value: 'year',
    label: 'Tahun'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'No Laporan',
    width: 'auto'
  },
  {
    label: 'Nama Laporan',
    width: 'auto'
  },
  {
    label: 'Tahun Laporan',
    width: 'auto'
  },
  
  {
    label: 'Sektor Perusahaan',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 100
  },
  {
    label: 'Action',
    width: 100
  },
]
 
const KlhkReport = () => {
  const permissionState = useSelector(state => state.permission.access)
  const navigate = useNavigate()
  const [params, setParams] = useState(defaultParameter)
  const [klhkList, setKlhkList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingDetail, setLoadingDetail] = useState(false)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [openModalAttachment, setOpenModalAttachment] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState('createdAt')
  const [selectedOrder, setSelectedOrder] = useState('desc')

  const mappingOrder = [
    {
      value: 'asc',
      label: selectedFilter === 'name' ? 'A - Z' : 'Terlama - Terbaru'
    },
    {
      value: 'desc',
      label: selectedFilter === 'name' ? 'Z - A' : 'Terbaru - Terlama'
    },
  ]

  const handleGetKlhkList = async () => {
    setLoading(true)
    try {
      const response = await getListKlhkReport(params)
      setKlhkList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleGetDetailKlhk = async (klhkId) => {
    setLoadingDetail(true)
    try {
      const response = await getDetailKlhkReportById(klhkId)
      setDetailData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingDetail(false)
    }
  }

  useEffect(() => {
    handleGetKlhkList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectFilter = (filterOrder) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      type: 'comparison',
      orderBy: filterOrder,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      type: 'comparison',
      orderBy: params.orderBy,
      order: orders
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    report: (val) => {
      navigate(`/perdirjen/parameters/${val.id}`)
    },
    delete: (val) => {
      modalDeleteData('Laporan Perdirjen', val.id, val.name, deleteKlhkReport, handleGetKlhkList)
    },
    unlock: (val) => {
      modalSetStatusReport(val.id, val.name, false, 'klhk-report', assignLockKlhkReport, handleGetKlhkList)
    },
    lock: (val) => {
      modalSetStatusReport(val.id, val.name, true, 'klhk-report', assignLockKlhkReport, handleGetKlhkList)
    },
    attachment: (val) => {
      setOpenModalAttachment(true)
      handleGetDetailKlhk(val.id)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalAttachment = () => {
    setOpenModalAttachment(false)
    setDetailData(null)
  }

  const LoadingDetail = () => {
    return (
      <Center h={350}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }

  const handleCreate = () => {
    const permissionReport = permissionState['klhk-report']
    const createdPermission = permissionReport.find(val => val.alias === 'klhk-report.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Laporan</Button>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Data Perdirjen</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Laporan. . .'
              name="search"
              onChange={handleSearchChange}
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [setSelectedFilter(e), handleSelectFilter(e)]}
              value={selectedFilter}
              checkIconPosition="right"
              allowDeselect={false}
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableKlhk label={labelTable} data={klhkList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='xl' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Data Perdirjen</Text>} withCloseButton={false}>
        <FormKlhk dataKlhk={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetKlhkList} />
      </Modal>
      <Modal opened={openModalAttachment} onClose={onCloseModalAttachment} closeOnClickOutside={false} centered size='80%' title={<Text fw='Bold'>Lampiran Data Perdirjen</Text>} withCloseButton={false}>
        {loadingDetail ? <LoadingDetail/> : <FormKlhkAttachment detailKlhk={detailData} onCloseModal={onCloseModalAttachment} reloadList={handleGetKlhkList} />}
      </Modal>
    </AuthLayout>
    
  )
}

export default KlhkReport