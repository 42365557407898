import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import classes from './Benchmark.module.css'
import { Box, Text, Stepper } from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'
import FormCompanyBenchmark from '../../../components/pages/Benchmark/FormCompanyBenchmark'
import BenchmarkData from '../../../components/pages/Benchmark/Layout/BenchmarkData'
import OverviewDataBenchmark from '../../../components/pages/Benchmark/OverviewDataBenchmark'
import BenchmarkDataCompany from '../../../components/pages/Benchmark/BenchmarkDataCompany'
import { useSelector, useDispatch } from 'react-redux'
import { updatedDataBenchmark } from '../../../store/benchmarkData'

const Benchmark = () => {
  const dispatch = useDispatch()
  const stepBenchmark = useSelector(state => state.benchmark.layoutStep)
  const [activeStep, setActiveStep] = useState(0)
  const nextStep = () => {
    setActiveStep((current) => (current < 5 ? current + 1 : current))
  }
  
  const prevStep = () => {
    setActiveStep((current) => (current > 0 ? current - 1 : current))
  }

  const resetStep = () => {
    setActiveStep(0)
  }

  useEffect(() => {
    if (stepBenchmark > 0) {
      setActiveStep(stepBenchmark)
    } else {
      setActiveStep(0)
    }
  }, [stepBenchmark])

  useEffect(() => {
    return () => {
      dispatch(updatedDataBenchmark({ 'layoutStep': 0, 'dataBenchmark': null }))
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Benchmark Perusahaan</Text>
        <Box my={40}>
          <Stepper
            size='sm'
            active={activeStep}
            onStepClick={setActiveStep}
            
            classNames={{ 
              step: classes.stepProgress,
              content: classes.contentStepper,
              stepBody: classes.stepBody
            }}
            completedIcon={<IconCircleCheck stroke={1.5} size={18} />}
          > 
            <Stepper.Step label="Pilih Perusahaan" allowStepSelect={false}>
              <FormCompanyBenchmark prevStep={prevStep} nextStep={nextStep} activeStep={activeStep} />
            </Stepper.Step>
            <Stepper.Step label="Data Nasional" allowStepSelect={false}>
              <BenchmarkData prevStep={prevStep} nextStep={nextStep} activeStep={activeStep} levelBenchmark='national' />
            </Stepper.Step>
            <Stepper.Step label="Data Asia" allowStepSelect={false}>
              <BenchmarkData prevStep={prevStep} nextStep={nextStep} activeStep={activeStep} levelBenchmark='asia' />
            </Stepper.Step>
            <Stepper.Step label="Data Dunia" allowStepSelect={false}>
              <BenchmarkData prevStep={prevStep} nextStep={nextStep} activeStep={activeStep} levelBenchmark='international' />
            </Stepper.Step>
            <Stepper.Step label="Overview" allowStepSelect={false}>
              <OverviewDataBenchmark prevStep={prevStep} nextStep={nextStep} activeStep={activeStep} />
            </Stepper.Step>
            <Stepper.Completed>
              <BenchmarkDataCompany resetStep={resetStep} />
            </Stepper.Completed>
          </Stepper>
        </Box>
        
      </Box>
    </AuthLayout>
    
  )
}

export default Benchmark