import React, { useState, useEffect } from 'react'
import { Box, Flex, Group, Button, Input } from '@mantine/core'
import { updateKlhkReport } from '../../../services/klhk-report'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import dayjs from 'dayjs'

const defaultVal = {
  issue: '',
  name: '',
  year: null,
  industryId: null,
  sourceType: 'file',
  source: null,
  attachment: ''
}

const FormKlhkAttachment = ({ detailKlhk, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [loadingForm, setLoadingForm] = useState(false)
  const editorConfig = {
    extraPlugins: [uploadPlugin]
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file
        const base64 = await toBase64(file)
        return {
          default: base64
        }
      },
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  useEffect(() => {
    if (detailKlhk !== null) {
      handleSetForm(detailKlhk)
    }
    // eslint-disable-next-line
  }, [detailKlhk])

  const handleChange = (name, val) => {
    setFormData((old) => ({ ...old, [name]: val }))
  }

  const handleSetForm = (detail) => {
    const dataYear = new Date()
    dataYear.setFullYear(detail.year)
    const dataDetail = {
      id: detail.id,
      issue: detail.issue,
      industryId: detail.industry.id,
      name: detail.name,
      year: dataYear,
      sourceType: detail.sourceType,
      source: detail.source,
      attachment: detail.attachment === null ? '' : detail.attachment
    }
    setFormData(dataDetail)
  }

  const submitReport = async (payloadReport) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    const body = {
      issue: payloadReport.issue,
      name: payloadReport.name,
      industryId: payloadReport.industryId,
      year: Number(dayjs(payloadReport.year).format('YYYY')),
      sourceType: 'file',
      source: payloadReport.source,
      attachment: payloadReport.attachment
    }
    if (detailKlhk !== null) {
      methodFunction = updateKlhkReport(payloadReport.id, body)
      titleMessageSuccess = 'Update Laporan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate laporan'
      titleMessageError = 'Gagal Mengupdate Laporan'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
    }
  }
  
  return (
    <Box>
      <Box mb='md'>
        <Input.Wrapper label="Lampiran">
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={formData.attachment}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  'height',
                  '500px',
                  editor.editing.view.document.getRoot()
                )
              })
            }}
            onChange={( event, editor ) => {
              const valueEditor = editor.getData()
              handleChange('attachment', valueEditor)
            }}
          />
        </Input.Wrapper>
        
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitReport(formData)}>Update Lampiran</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormKlhkAttachment