import React, { useState, useEffect } from 'react'
import { Box, TextInput, PasswordInput, Select, Button, Checkbox, Progress, Popover, Group, Flex } from '@mantine/core'
import { getListRoles } from '../../../services/roles'
import { addUser, updateUser, updateUserPassword } from '../../../services/users'
import PasswordRequired from '../../../components/ui/PasswordRequired'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  fullName: '',
  email: '',
  roleId: null,
  password: '',
  verifyPassword: ''
}

const formValidation = {
  fullName: {
    isError: false,
    message: ''
  },
  email: {
    isError: false,
    message: ''
  },
  roleId: {
    isError: false,
    message: ''
  },
  password: {
    isError: false,
    message: ''
  },
  verifyPassword: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const FormUser = ({ dataUser, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [selectedRoleId, setSelectedRoleId] = useState(null)
  const [ roleList, setRoleList ] = useState([])
  const [ userPassword, setUserPassword ] = useState(false)
  const [popoverPassword, setPopoverPassword] = useState(false)
  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />
  ))
  const strengthPassword = getStrength(formData.password)

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(formData.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const submitUser = async (payloadUser) => {
    if (dataUser === null) {
      await handleAddUser(payloadUser)
    } else {
      await handleUpdateUser(payloadUser)
    }
  }

  const handleAddUser = async (payloadUser) => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      fullName: payloadUser.fullName,
      email: payloadUser.email, 
      roleId: selectedRoleId,
      password: payloadUser.verifyPassword
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (formData.password !== formData.verifyPassword) {
      setValidationForm((old) => ({
        ...old,
        verifyPassword: {
          isError: true,
          message: 'Konfirmasi password harus sama dengan password anda'
        }
      }))
      setLoadingForm(false)
      return 
    }
    try {
      const response = await addUser(payload)
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess('Tambah User Berhasil', 'Anda telah berhasil menambahkan user baru')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Gagal Menambahkan User', `${Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  const handleUpdateUser = async (payloadUser) => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      fullName: payloadUser.fullName,
      email: payloadUser.email, 
      roleId: selectedRoleId,
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await updateUser(payloadUser.id, payload)
      if (response) {
        if (userPassword) {
          const updatePassword = await handleUpdatePasswordUser(payloadUser.id, payloadUser)
          if (updatePassword) {
            onCloseModal()
            reloadList()
            notificationSuccess('Update User Berhasil', 'Anda telah berhasil mengupdate user baru')
          }
        } else {
          onCloseModal()
          reloadList()
          notificationSuccess('Update User Berhasil', 'Anda telah berhasil mengupdate user baru')
        }
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      notificationError('Gagal Update User', `${Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleUpdatePasswordUser = async (userId, payloadUser) => {
    const payload = {
      oldPassword: payloadUser.password,
      newPassword: payloadUser.verifyPassword
    }
    try {
      const response = await updateUserPassword(userId, payload)
      return response
    } catch (error) {
      console.log(error)
    }
  }


  const handleGetListRole = async () => {
    try {
      const response = await getListRoles()
      const dataRole = response.data
      const mappingRoles = dataRole.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setRoleList(mappingRoles)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetForm = (val) => {
    const dataDetailUser = {
      id: val.id,
      fullName: val.fullName,
      nickname: val.nickname,
      email: val.email,
      roleId: val.role.id,
      password: '',
      verifyPassword: ''
    }
    setFormData(dataDetailUser)
    setSelectedRoleId(val.role.id)
  }

  useEffect(() => {
    handleGetListRole()
    if (dataUser !== null) {
      handleSetForm(dataUser)
    } else {
      setUserPassword(true)
    }
  }, [dataUser])

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const mappingFormPassword = (setPassword) => {
    if (setPassword) {
      return (
        <Box>
          <Box mb="md">
            <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
              <Popover.Target>
                <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                  <PasswordInput
                    name="password"
                    value={formData.password}
                    placeholder="Masukkan password"
                    label="Password"
                    error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                    onChange={handleChange}
                    withAsterisk
                  />
                </Box>
              </Popover.Target>
              <Popover.Dropdown>
                <Group gap={5} grow mt="xs" mb="md">
                  {progressBar}
                </Group>
                <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
                {checkPassword}
              </Popover.Dropdown>
            </Popover>
          </Box>
          <Box mb="md">
            <PasswordInput
              value={formData.verifyPassword}
              name="verifyPassword"
              placeholder="Ulangi password anda"
              label="Konfirmasi Password"
              error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ''}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
        </Box>
      )
    }
  }
  
  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='fullName'
          value={formData.fullName}
          label='Nama'
          placeholder='Masukkan nama'
          error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='email'
          value={formData.email}
          label='Email'
          placeholder='Masukkan email'
          error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
          onChange={handleChange}
          withAsterisk
          disabled={dataUser !== null}
        />
      </Box>
      <Box mb='md'>
        <Select
          name="roleId"
          label="Role User"
          placeholder="Pilih salah satu role"
          searchable
          nothingFoundMessage='Role tidak ditemukan'
          data={roleList}
          onChange={(e) => setSelectedRoleId(e)}
          error={validationForm.roleId.isError ? `${validationForm.roleId.message}` : ''}
          value={selectedRoleId}
          withAsterisk
          checkIconPosition="right"
        />
      </Box>
      <Box mb='md'>
        <Checkbox checked={userPassword} onChange={(e) => setUserPassword(e.currentTarget.checked)} label="Set User Password" disabled={dataUser === null} />
      </Box>
      {mappingFormPassword(userPassword)}
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitUser(formData)}>{dataUser === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormUser