import React from 'react'
import { Box, Text, Paper } from '@mantine/core'
import { CartesianGrid, Line, Scatter, ResponsiveContainer, XAxis, YAxis, ComposedChart, Tooltip, ReferenceLine, ReferenceDot } from 'recharts'

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const defaultName = {
      mean: '50% rata - rata',
      percentile25: '25% Teratas',
      percentile75: '25% Terbawah'
    }

    const mappingLabel = (label) => {
      let labelName = ''
      if (label === 'mean' || label === 'percentile25' || label === 'percentile75') {
        labelName = defaultName[label]
      } else {
        labelName = label
      }
      return labelName
    }
    return (
      <Paper shadow="sm" withBorder p="sm">
        <Text fz={12} fw={700} mb={8}>{mappingLabel(payload[0].payload.label)}</Text>
        <Text fz={11} c='gray.7'>Intensitas: {payload[0].payload.x}</Text>
      </Paper>
    )
  }
}

const PreviewChartBenchmark = ({ dataPreview, countDataPreview }) => {
  if (dataPreview !== null && countDataPreview > 0) {
    const dataScatter = dataPreview.chartPoints.scatters.map((val) => {
      const remap = {
        x: val.x,
        y: val.y,
        label: val.label,
        isMain: val.isMain === undefined ? false : true
      }
      return remap
    })
    const dataLine = dataPreview.chartPoints.lines.map((val) => {
      const remap = {
        x: val.x,
        y: val.y,
        label: val.label,
        isMain: val.isMain === undefined ? false : true
      }
      return remap
    })
    const findMean = dataLine.find((val) => val.label === 'mean')
    const findPercentile25 = dataLine.find((val) => val.label === 'percentile25')
    const findPercentile75 = dataLine.find((val) => val.label === 'percentile75')
    const dataMain = dataLine.find((val) => val.isMain)

    return (
      <Box>
        <ResponsiveContainer height={400}>
          <ComposedChart margin={{ top: 10, right: 30, left: 30, bottom: 10, }}>
            <CartesianGrid strokeDasharray="4 4" />
            <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
            <YAxis dataKey="y" label={{ value: '', angle: -90, position: 'insideLeft', offset: -20 }} domain={['auto', 'auto']} type="number" interval={0} allowDataOverflow={true} />
            <XAxis dataKey="x" label={{ value: 'Intensitas', position: 'insideBottomRight', offset: -10 }} domain={['auto', 'auto']} type="number" interval={0} allowDataOverflow={true} />
            <ReferenceLine x={findMean.x} stroke='red' label="50% rata - rata" position='left' strokeDasharray="3 3" />
            <ReferenceLine x={findPercentile25.x} stroke='red' label="25% Teratas" strokeDasharray="3 3" />
            <ReferenceLine x={findPercentile75.x} stroke='red' label="25% Terbawah" strokeDasharray="3 3" />
            <ReferenceDot x={dataMain.x} y={dataMain.y} r={7} fill='red' />
            <Line strokeWidth={1} data={dataLine} dot={false} activeDot={false} type="monotone" dataKey="y" stroke="black" legendType="none" />
            <Scatter data={dataScatter} fill="#364fc7" />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    )
  } else {
    return (
      <Box ta='center' my={50}>
        <Text fz={13} mb={6} c='gray.6'>"Tidak dapat menampilkan simulasi grafik"</Text>
      </Box>
    )
  }
}

export default PreviewChartBenchmark