import React, { useState } from 'react'
import { Table, rem, Badge, Flex, Button, Box } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { IconGripVertical } from '@tabler/icons-react'
import classes from './TableOrder.module.css'
import { assignOrderParameter } from '../../../services/parameter'
import { notificationSuccess, notificationError } from '../../ui/Notifications'

const TableOrderParameter = ({ label, data, industryId, cancelSubmit, handleReloadList }) => {
  const [state, handlers] = useListState(data)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const handleSubmitOrder = async (data) => {
    setLoadingSubmit(true)
    const payload = {
      industryId: industryId,
      parameterIds: []
    }
    const remapOrderParameter = data.map((val) => {
      return val.id
    })
    payload.parameterIds = remapOrderParameter
    try {
      const response = await assignOrderParameter(payload)
      if (response.length > 0) {
        notificationSuccess('Perubahan Order Berhasil', 'Anda telah berhasil merubah order parameter')
        handleReloadList()
      }
    } catch (error) {
      console.log(error)
      notificationError('Perubahan Order Gagal', 'Anda gagal merubah order parameter')
    }
  }

  const items = state.map((item, index) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided) => (
        <Table.Tr className={classes.item} ref={provided.innerRef} {...provided.draggableProps}>
          <Table.Td>
            <div className={classes.dragHandle} {...provided.dragHandleProps}>
              <IconGripVertical style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </div>
          </Table.Td>
          <Table.Td>{item.name}</Table.Td>
          <Table.Td>{item.aspect.name}</Table.Td>
          <Table.Td>{item.unit.name} ({item.unit.symbol})</Table.Td>
          <Table.Td>{item.multiplier === null ? '-' : item.multiplier}</Table.Td>
          <Table.Td><Badge size="xs" radius="sm" variant="filled" color={item.aspect.isProduction ? 'green' : 'red'}>{item.aspect.isProduction ? 'Ya' : 'Tidak'}</Badge></Table.Td>
        </Table.Tr>
      )}
    </Draggable>
  ))
  
  return (
    <Box>
      <Table.ScrollContainer minWidth={768} type='native'>
        <DragDropContext
          onDragEnd={({ destination, source }) =>
            handlers.reorder({ from: source.index, to: destination.index || 0 })
          }
        >
          <Table horizontalSpacing='sm' verticalSpacing='sm' withTableBorder style={{ fontSize: '12.5px' }}>
            <Table.Thead>
              <Table.Tr>
                {mappingTableHead(label)}
              </Table.Tr>
            </Table.Thead>
            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => (
                <Table.Tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {items}
                  {provided.placeholder}
                </Table.Tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </Table.ScrollContainer>
      <Box mt={14}>
        <Flex justify='flex-end' mb={14}>
          <Button color='red.9' size='xs' mr={6} onClick={cancelSubmit}>Batal</Button>
          <Button color='indigo.9' size='xs' loading={loadingSubmit} onClick={() => handleSubmitOrder(state)}>Simpan Order</Button>
        </Flex>
      </Box>
    </Box>
    
  )
}

export default TableOrderParameter