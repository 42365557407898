import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Input, Select } from '@mantine/core'
import { addAttachment, updateAttachment } from '../../../services/attachment.js'
import { validation } from '../../../plugins/validation.js'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


const FormAttachment = ({ dataAttachment, benchmarkReportId, klhkList, onCloseModal, reloadList }) => {
  const defaultVal = {
    name: '',
    benchmarkReportId: benchmarkReportId,
    selectedAttachment: 'content',
    klhkReportId: null,
    content: '',
  }
  const formValidation = {
    name: {
      isError: false,
      message: ''
    },
    content: {
      isError: false,
      message: ''
    },
    klhkReportId: {
      isError: false,
      message: ''
    }
  }

  const defaultAttachment = [
    {
      value: 'content',
      label: 'Konten'
    },
    {
      value: 'klhkReport',
      label: 'Data Perdirjen'
    }
  ]
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [klhkReportList, setKlhkReportList] = useState([])

  useEffect(() => {
    if (dataAttachment !== null) {
      handleGetDetail(dataAttachment)
    }
    // eslint-disable-next-line
  }, [dataAttachment])

  useEffect(() => {
    if (klhkList.length > 0) {
      handleDataKlhk(klhkList)
    }
    // eslint-disable-next-line
  }, [klhkList])

  const handleGetDetail = (data) => {
    const payload = {
      id: data.id,
      name: data.name,
      benchmarkReportId: benchmarkReportId,
      selectedAttachment: data.klhkReport !== null ? 'klhkReportId' : 'content',
      content: '',
      klhkReportId: null,
    }

    if (data.klhkReport !== null) {
      payload.klhkReportId = data.klhkReport.id.toString()
    } else {
      payload.content = data.content === null ? '' : data.content
    }
    setFormData(payload)
  }

  const handleDataKlhk = (klhkReport) => {
    const dataKlhk = klhkReport.map((val) => {
      const remap = {
        label: val.name,
        value: val.id.toString()
      }
      return remap
    })
    setKlhkReportList(dataKlhk)
  }

  const submitAttachment = async (payloadAttachment) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    // let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    let payload = {}
    if (formData.selectedAttachment === 'content') {
      delete formData.klhkReportId
      payload = {
        name: payloadAttachment.name,
        benchmarkReportId: payloadAttachment.benchmarkReportId,
        content: payloadAttachment.content,
        klhkReportId: null
      }
    } else {
      delete formData.content
      payload = {
        name: payloadAttachment.name,
        benchmarkReportId: payloadAttachment.benchmarkReportId,
        content: null,
        klhkReportId: payloadAttachment.klhkReportId
      }
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }

    if (payloadAttachment.id !== undefined) {
      methodFunction = updateAttachment(payloadAttachment.id, payload)
      titleMessageSuccess = 'Update Attachment Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate attachment'
      titleMessageError = 'Gagal Mengupdate Attachment'
      // captionMessageError = 'Silahkan cek kembali form anda'
    } else {      
      methodFunction = addAttachment(payload)
      titleMessageSuccess = 'Tambah Attachment Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan attachment baru'
      titleMessageError = 'Gagal Menambahkan Attachment'
      // captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${errorMessage}`)
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSelectChange = (name, val) => {
    setFormData((old) => ({ ...old, [name]: val }))
  }

  const handleChangeEditor = (name, val) => {
    setFormData((old) => ({ ...old, [name]: val }))
  }

  const editorConfig = {
    extraPlugins: [uploadPlugin]
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file
        const base64 = await toBase64(file)
        return {
          default: base64
        }
      },
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  const mappingSelectedAttachment = (value) => {
    if (value === 'content') {
      return (
        <Box mb='md'>
          <Input.Wrapper 
            label="Deskripsi Attachment"
            withAsterisk
            error={validationForm.content.isError ? `${validationForm.content.message}` : ''}
          >
            <CKEditor
              editor={ClassicEditor}
              config={editorConfig}
              data={formData.content}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    'height',
                    '500px',
                    editor.editing.view.document.getRoot()
                  )
                })
              }}
              onChange={( event, editor ) => {
                const valueEditor = editor.getData()
                handleChangeEditor('content', valueEditor)
              }}
            />
          </Input.Wrapper>
        </Box>
      )
    } else {
      return (
        <Box mb='md'>
          <Select
            name="klhkReportId"
            label="Laporan Perdirjen"
            placeholder="Pilih salah satu laporan"
            searchable
            clearable
            data={klhkReportList}
            onChange={(val) => handleSelectChange('klhkReportId', val)}
            error={validationForm.klhkReportId.isError ? `${validationForm.klhkReportId.message}` : ''}
            value={formData.klhkReportId}
            withAsterisk
            checkIconPosition="right"
          />
        </Box>
      )
    }
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Attachment'
          placeholder='Masukkan nama attachment'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Select
          name="selectedAttachment"
          label="Tipe Lampiran"
          placeholder="Pilih salah satu tipe"
          searchable
          data={defaultAttachment}
          onChange={(val) => handleSelectChange('selectedAttachment', val)}
          value={formData.selectedAttachment}
          withAsterisk
          allowDeselect={false}
          checkIconPosition="right"
        />
      </Box>
      {mappingSelectedAttachment(formData.selectedAttachment)}
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitAttachment(formData)}>{dataAttachment === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormAttachment