import React from 'react'
import { modals } from '@mantine/modals'
import { ActionIcon, Box, Text } from '@mantine/core'
import { IconAlertTriangleFilled } from '@tabler/icons-react'
import { notificationSuccess, notificationError } from '../Notifications'


export const modalRevisionData = ( id, name, functionUpdate, reloadData ) => {

  const handleUpdateData = async (id) => {
    const payload = {
      status: 'revision'
    }
    try {
      const response = await functionUpdate(id, payload)
      if (response) {
        notificationSuccess(`Update Laporan berhasil`,  `Data Laporan berhasil direvisi`)
        reloadData()
        modals.closeAll()
      }
    } catch (error) {
      console.log(error)
      notificationError(`Update Laporan gagal`, `Data laporan gagal diupdate`)
    }
  }

  return modals.openConfirmModal({
    centered: 'true',
    closeOnConfirm: false,
    children: (
      <Box>
        <Box ta='center'>
          <ActionIcon variant='transparent' color='red' size={120}>
            <IconAlertTriangleFilled stroke={1.5} size={120} />
          </ActionIcon>
          <Text fw={500} mb={4}>Apakah anda yakin ingin merevisi ? </Text>
          <Text fw='bold' mb={14}>"{name}"</Text>
          <Text fz='xs' c='gray.6' mb={20}>Tolong diperhatikan. data yang sudah di revisi tidak dapat di kembalikan lagi.</Text>
        </Box>
      </Box>
    ),
    labels: { confirm: 'Revisi', cancel: "Batal" },
    confirmProps: { color: 'red' },
    onConfirm: () => handleUpdateData(id),
  })
}
