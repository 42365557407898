import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import LoadingPage from '../components/ui/LoadingPage'

const ProtectedRoute = ({ children }) => {
  const { isLoading, isLogin } = useSelector(state => state.auth)
  if (isLoading) {
    return <LoadingPage />
  }
  if (!isLogin) {
    return <Navigate to='/login' replace />
  }
  return children
}

export default ProtectedRoute