import http from '../plugins/axios'

export const getListKlhkReport = async (params) => {
  try {
    const response = await http.get(`/klhk-reports`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailKlhkReportById = async (klhkId) => {
  try {
    const response = await http.get(`/klhk-reports/${klhkId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addKlhkReport = async (payload) => {
  try {
    const response = await http.post(`/klhk-reports`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateKlhkReport = async (klhkId, payload) => {
  try {
    const response = await http.patch(`/klhk-reports/${klhkId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteKlhkReport = async (klhkId) => {
  try {
    const response = await http.delete(`/klhk-reports/${klhkId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignLockKlhkReport = async (payload) => {
  try {
    const response = await http.patch(`/klhk-reports/lock`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadFileKlhkReport = async (reportId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/klhk-reports/${reportId}/file`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}