import React from 'react'
import { Table, Menu, ActionIcon, Tooltip } from '@mantine/core'
import { IconDotsVertical, IconTrash, IconPencil, IconTransform, IconInfoCircle } from '@tabler/icons-react'
import { useSelector } from 'react-redux' 

const ActionMenu = ({ handleAction }) => {
  const permissionState = useSelector(state => state.permission.access)

  const permissionUpdate = () => {
    const permissionUnit = permissionState['unit']
    const updatedPermission = permissionUnit.find(val => val.alias === 'unit.update')
    if (updatedPermission !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={12} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={12}
        >
          Edit
        </Menu.Item>
      )
    }
  }

  const permissionUnitConversion = () => {
    const permissionUnit = permissionState['unit']
    if (permissionUnit !== undefined) {
      return (
        <Menu.Item
          leftSection={<IconTransform stroke={1.5} size={12} />}
          onClick={() => handleAction('conversion')}
          fw='600'
          fz={12}
        >
          Konversi
        </Menu.Item>
      )
    }
  }

  const permissionDelete = () => {
    const permissionUnit = permissionState['unit']
    const deletedPermission = permissionUnit.find(val => val.alias === 'unit.delete')
    if (deletedPermission !== undefined) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={12} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={12}
        >
          Hapus
        </Menu.Item>
      )
    }
  }


  return (
    <Menu width={170} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='xs' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {permissionState !== null ? permissionUpdate() : ''}
        {permissionState !== null ? permissionUnitConversion() : ''}
        {permissionState !== null ? permissionDelete() : ''}
      </Menu.Dropdown>
    </Menu>
  )
}

const TableUnit = ({ label, data, countData, actionMethod }) => {
  const permissionState = useSelector(state => state.permission.access)

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    const mappingMenu = () => {
      const permissionUnit = permissionState['unit']
      const permissionUnitConversion = permissionState['unit']
      const updatedPermission = permissionUnit.find(val => val.alias === 'unit.update')
      const deletedPermission = permissionUnit.find(val => val.alias === 'unit.delete')
      if (updatedPermission === undefined && deletedPermission === undefined && permissionUnitConversion === undefined) {
        return (
          <Tooltip label='anda tidak diizinkan untuk mengakses menu ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs' color='rgba(0, 0, 0, 1)'>
              <IconInfoCircle stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        )
      } else {
        return (<ActionMenu handleAction={(type) => actionMethod(val, type)} />)
      }
    }

    return (
      <Table.Tr key={index}>
        <Table.Td>{index + countData + 1}.</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td>{val.symbol}</Table.Td>
        <Table.Td>{permissionState !== null ? mappingMenu() : ''}</Table.Td>
      </Table.Tr>
    )
  })
  return (
    <Table.ScrollContainer minWidth={768} type='native'>
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '12.5px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableUnit