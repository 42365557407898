import { configureStore } from '@reduxjs/toolkit'
import authData from '../store/authData'
import permissionRoute from '../store/permissionRoute'
import benchmarkData from '../store/benchmarkData'

export const store = configureStore({
  reducer: {
    auth: authData,
    permission: permissionRoute,
    benchmark: benchmarkData
  },
})
