import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, FileInput, Text, Image } from '@mantine/core'
import { addBenchmarker, updateBenchmarker, attachImageBenchmarker } from '../../../services/benchmarker'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import NoImage from '../../../assets/img/no-image.webp'
import { getImageFile } from '../../../services/file'

const defaultVal = {
  name: '',
  file: null
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  file: {
    isError: false,
    message: ''
  }
}

const FormBenchmarker = ({ dataBenchmarker, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [imageBase64, setImageBase64] = useState(null)


  useEffect(() => {
    if (dataBenchmarker !== null) {
      handleSetForm(dataBenchmarker)
    }
    // eslint-disable-next-line
  }, [dataBenchmarker])

  const handleSetForm = (val) => {
    const dataDetail = {
      id: val.id,
      name: val.name,
    }
    if (val.image !== null) {
      setImageBase64(getImageFile(val.image))
    }
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleFilePhoto = (fileUpload) => {
    setValidationForm(formValidation)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationForm({
        file: {
          isError: true,
          message: 'File foto terlalu besar'
        }
      })
      // eslint-disable-next-line
      setFormData({ ...formData, ['file']: null })
      return
    }
    // eslint-disable-next-line
    setFormData({ ...formData, ['file']: fileUpload })
    convertfileBase64(fileUpload)
  }

  const convertfileBase64 = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBase64(reader.target.result)
    }
  }

  const submitBenchmarker = async (payloadBenchmarker) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadBenchmarker.name,
      file: payloadBenchmarker.file
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    const body = {
      name: payload.name
    }
    if (dataBenchmarker !== null) {
      methodFunction = updateBenchmarker(payloadBenchmarker.id, body)
      titleMessageSuccess = 'Update Data Benchmarker Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate data benchmarker'
      titleMessageError = 'Gagal Mengupdate Data Benchmarker'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addBenchmarker(body)
      titleMessageSuccess = 'Tambah Data Benchmarker Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan data benchmarker baru'
      titleMessageError = 'Gagal Menambahkan Data Benchmarker'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        if (payloadBenchmarker.file !== null) {
          const reportId = response.id !== undefined ? response.id : payloadBenchmarker.id
          const upload = await handleUploadImage(reportId)
          if (upload) {
            onCloseModal()
            reloadList()
            notificationSuccess(titleMessageSuccess, captionMessageSuccess)
            setLoadingForm(false)
          }
        } else {
          onCloseModal()
          reloadList()
          notificationSuccess(titleMessageSuccess, captionMessageSuccess)
          setLoadingForm(false)
        }
      }
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleUploadImage = async (benchmarkerId) => {
    const form = new FormData()
    form.set('file', formData.file)
    try {
      const response = await attachImageBenchmarker(benchmarkerId, form)
      return response
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Bendera Kontrak'
          placeholder='Masukkan nama bendera kontrak'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <FileInput
          name='file'
          value={formData.file}
          accept="image/png,image/jpeg"
          label='Upload Gambar'
          placeholder='Upload file gambar'
          error={validationForm.file.isError ? `${validationForm.file.message}` : ''}
          onChange={handleFilePhoto}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Text fw='bold' mb={12} fz={13}>Tinjauan Gambar</Text>
        <Image src={imageBase64 === null ? NoImage : imageBase64} radius="md" h={250} fit="contain" />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitBenchmarker(formData)}>{dataBenchmarker === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormBenchmarker