import React, { useState, useEffect } from 'react'
import { assignRolePermission, getDetailPermissionRole } from '../../../services/roles'
import { Accordion, Box, Flex, Group, Skeleton, Switch, Text, Button, ScrollArea } from '@mantine/core'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultName = {
  dashboard: 'Dasbor',
  permission: 'Izin Pengguna',
  user: 'Pengguna',
  role: 'Peran Pengguna',
  company: 'Data Perusahaan',
  'company-report': 'Data Pembanding',
  'klhk-report': 'Data Perdirjen',
  industry: 'Sektor Perusahaan',
  unit: 'Satuan Unit / Konverter Unit',
  parameter: 'Parameter',
  benchmark: 'Benchmark',
  benchmarker: 'Bendera Kontrak',
  aspect: 'Aspek Perusahaan'
}

const FormPermission = ({ roleId, permissionList, onCloseModal }) => {
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [selectedPermissionRoleId, setSelectedPermissionRoleId] = useState([])

  const handleGetPermissionList = async () => {
    setLoading(true)
    try {
      const detailRolePermission = await getDetailPermissionRole(roleId)
      const dataPermission = Object.values(detailRolePermission)
      const permissionId = []
      for (let i = 0; i < dataPermission.length; i++) {
        const permissionData = dataPermission[i]
        for (let j = 0; j < permissionData.length ; j++) {
          permissionId.push(permissionData[j])
        }
      }
      setSelectedPermissionRoleId(permissionId)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  const submitPermission = async (dataPermission) => {
    setLoadingSubmit(true)
    const payload = {
      permissionIds: []
    }
    const permissionId = dataPermission.map((val) => {
      return val.id
    })
    payload.permissionIds = permissionId
    try {
      const response = await assignRolePermission(roleId, payload)
      if (response) {
        notificationSuccess('Update Permission Berhasil', `Anda telah merubah akses role permission ini`)
      }
    } catch (error) {
      console.log(error)
      notificationError('Update Permission Gagal', `Perubahan role permission ini gagal di update`)
    } finally {
      setLoadingSubmit(false)
      onCloseModal()
    }
  }

  useEffect(() => {
    handleGetPermissionList()
    // eslint-disable-next-line
  }, [])

  const loadingPermission = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i}>
        <Skeleton height={50} width="100%" radius="md" mb="md" />
      </Box>
    )
  })

  const selectPermission = (eventChecked, valuePermission) => {
    const data = selectedPermissionRoleId
    if (eventChecked) {
      const dataSelected = {
        id: valuePermission.id,
        name: valuePermission.name,
        alias: valuePermission.alias
      }
      data.push(dataSelected)
      setSelectedPermissionRoleId(selectedPermissionRoleId)
    } else {
      for (let i = 0; i < selectedPermissionRoleId.length; i++) {
        if (selectedPermissionRoleId[i].id === valuePermission.id) {
          data.splice(i,1)
        }
      }
      setSelectedPermissionRoleId([...selectedPermissionRoleId])
    }
  }

  // eslint-disable-next-line
  const mappingPermissionState = (statePermission, valueName) => {
    const mappingStateName = (value) => {
      if (value.includes('Create')) {
        return 'Tambah'
      } else if (value.includes('Delete')) {
        return 'Hapus'
      } else if (value.includes('Update')) {
        return 'Memperbarui'
      } else if (value.includes('Read')) {
        return 'Tampil'
      }
    }
    const mappingDataPermission = statePermission.map((val, index) => {
      return (
        <Flex justify='space-between' align='center' mb={12} key={index}>
            <Text fz={13} fw='400'>{mappingStateName(val.name)} {defaultName[valueName]}</Text>
            <Switch onLabel="ON" offLabel="OFF" checked={selectedPermissionRoleId.find((value) => value.id === val.id)} onChange={(e) => selectPermission(e.currentTarget.checked, val)} />
        </Flex>
      )
    })
    return mappingDataPermission
  }

  const mappingPermissionRole = (dataList) => Object.keys(dataList).map((val, index) => {
    return (
      <Accordion.Item key={index} value={val}>
        <Accordion.Control>
          <Text fz='sm' fw='600' tt='capitalize'>{defaultName[val]}</Text>
        </Accordion.Control>
        <Accordion.Panel>
          {mappingPermissionState(Object.values(dataList)[index], val)}
        </Accordion.Panel>
      </Accordion.Item>
    )
  })

  return (
    <Box>
      <ScrollArea my={16} h={500} offsetScrollbars scrollbarSize={6}>
        {loading ? loadingPermission(8) : (<Accordion variant='separated'>{mappingPermissionRole(permissionList)}</Accordion>)}
      </ScrollArea>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingSubmit} variant="filled" color='indigo.9' onClick={() => submitPermission(selectedPermissionRoleId)}>Simpan</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormPermission