import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Select, Text, Skeleton } from '@mantine/core'
import { updateCompanyReportParams } from '../../../../../../services/company-report-params'
import { getListUnitConversion } from '../../../../../../services/unit-conversion'
import { getListParameter } from '../../../../../../services/parameter'
import { validation } from '../../../../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../../../../components/ui/Notifications'



const FormReportParams = ({ dataReportParams, reportId, reportName, onCloseModal, reloadList }) => {
  const defaultVal = {
    value: '',
    companyReportId: reportId,
    parameterId: '',
    unitConversionId: ''
  }

  const formValidation = {
    value: {
      isError: false,
      message: ''
    },
    parameterId: {
      isError: false,
      message: ''
    }
  }

  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingUnit, setLoadingUnit] = useState(true)
  const [loadingParameter, setLoadingParameter] = useState(true)
  const [unitList, setUnitList] = useState([])
  const [parameterList, setParameterList] = useState([])
  const [selectedParameterId, setSelectedParameterId] = useState(null)
  const [selectedUnitId, setSelectedUnitId] = useState(null)

  const handleGetUnitConversionList = async (valueDataCompany) => {
    setLoadingUnit(true)
    const params = {
      unit: valueDataCompany.parameter.unit.id
    }
    try {
      const response = await getListUnitConversion(params)
      const dataUnit = response.data
      const remapUnit = dataUnit.map((val) => {
        const map = {
          value: val.id,
          label: dataReportParams !== null ? dataReportParams.parameter.unit.name === val.unitFrom.name ? `${val.unitTo.name} (${val.unitTo.symbol})` : `${val.unitFrom.name} (${val.unitFrom.symbol})` : ''
        }
        return map
      })
      setUnitList(remapUnit)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingUnit(false)
    }
  }

  const handleGetParameterList = async () => {
    setLoadingParameter(true)
    try {
      const response = await getListParameter()
      const dataParameter = response.data
      const remapParameter = dataParameter.map((val) => {
        const map = {
          value: val.id,
          label: val.name
        }
        return map
      })
      setParameterList(remapParameter)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingParameter(false)
    }
  }

  useEffect(() => {
    if (dataReportParams !== null) {
      handleSetForm(dataReportParams)
      handleGetParameterList()
      handleGetUnitConversionList(dataReportParams)
    }
    // eslint-disable-next-line
  }, [dataReportParams])

  const handleSetForm = (val) => {
    const dataDetail = {
      id: val.id,
      value: val.value,
      companyReportId: reportId,
      parameterId: val.parameter.id,
      unitConversionId: val.unitConversion !== null ? val.unitConversion.id : null
    }
    setFormData(dataDetail)
    setSelectedParameterId(val.parameter.id)
    setSelectedUnitId(val.unitConversion !== null ? val.unitConversion.id : null)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitReportParams = async (payloadForm) => {
    const titleMessageSuccess = 'Update Laporan Parameter Berhasil'
    const captionMessageSuccess = 'Anda telah berhasil mengupdate laporan parameter'
    const titleMessageError = 'Gagal Mengupdate Laporan Parameter'
    const captionMessageError = 'Silahkan cek kembali form anda'
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      value: Number(payloadForm.value),
      companyReportId: reportId,
      parameterId: selectedParameterId,
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    payload.unitConversionId = selectedUnitId !== null ? selectedUnitId : null
    try {
      const response = await updateCompanyReportParams(payloadForm.id, payload)
      if (response) {
        onCloseModal()
        reloadList(reportId)
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  return (
    <Box>
      <Box mb='lg'>
        <Text fz={12}>Nama Laporan: <Text fz={12} fw='600'>{reportName}</Text></Text>
      </Box>
      <Box mb='md'>
        {loadingParameter ? 
          (<Skeleton h={45}/>) : 
          (<Select
            name="parameterId"
            label="Parameter"
            placeholder="Pilih salah satu parameter"
            searchable
            nothingFoundMessage='Parameter tidak ditemukan'
            data={parameterList}
            onChange={(e) => setSelectedParameterId(e)}
            error={validationForm.parameterId.isError ? `${validationForm.parameterId.message}` : ''}
            value={selectedParameterId}
            withAsterisk
            checkIconPosition="right"
            disabled
          />)
        }
      </Box>
      <Box mb='md'>
        {loadingUnit ?
          (<Skeleton h={45} />) :
          (<Select
            name="unitConversionId"
            label="Unit Standar"
            placeholder="Pilih salah satu unit standar"
            searchable
            nothingFoundMessage='Unit Standar tidak ditemukan'
            data={unitList}
            onChange={(e) => setSelectedUnitId(e)}
            value={selectedUnitId}
            checkIconPosition="right"
          />)
        }
      </Box>
      <Box mb='md'>
        <TextInput
          name='value'
          value={formData.value}
          label='Jumlah Unit'
          placeholder='Masukkan jumlah'
          error={validationForm.value.isError ? `${validationForm.value.message}` : ''}
          onChange={handleChange}
          withAsterisk
          type='number'
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitReportParams(formData)}>{dataReportParams === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormReportParams