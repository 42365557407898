import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Select } from '@mantine/core'
import { updateKlhkReportParams } from '../../../../services/klhk-report-params'
import { validation } from '../../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../../components/ui/Notifications' 

const FormKlhkReportParams = ({ dataKlhkParams, klhkReportId, parameterList, onCloseModal, reloadList }) => {
  const defaultVal = {
    mean: '',
    percentile25: '',
    percentile75: '',
    klhkReportId: klhkReportId,
    parameterId: '',
  }

  const formValidation = {
    mean: {
      isError: false,
      message: ''
    },
    percentile25: {
      isError: false,
      message: ''
    },
    percentile75: {
      isError: false,
      message: ''
    },
    parameterId: {
      isError: false,
      message: ''
    }
  }

  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [selectedParameterId, setSelectedParameterId] = useState(null)

  useEffect(() => {
    if (dataKlhkParams !== null) {
      handleSetForm(dataKlhkParams)
    }
    // eslint-disable-next-line
  }, [dataKlhkParams])

  const handleSetForm = (val) => {
    const dataDetail = {
      id: val.id,
      mean: val.mean,
      percentile25: val.percentile25,
      percentile75: val.percentile75,
      klhkReportId: klhkReportId,
      parameterId: val.parameter.id,
    }
    setFormData(dataDetail)
    setSelectedParameterId(val.parameter.id)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const dataParameter = (parameterList) => parameterList.map((val) => {
    return {
      value: val.id,
      label: val.name
    }
  })

  const submitReportParams = async (payloadForm) => {
    let titleMessageSuccess = 'Update Laporan KLHK Parameter Berhasil'
    let captionMessageSuccess = 'Anda telah berhasil mengupdate laporan klhk parameter'
    let titleMessageError = 'Gagal Mengupdate Laporan KLHK Parameter'
    let captionMessageError = 'Silahkan cek kembali form anda'
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      mean: Number(payloadForm.mean),
      percentile25: Number(payloadForm.percentile25),
      percentile75: Number(payloadForm.percentile75),
      klhkReportId: klhkReportId,
      parameterId: selectedParameterId,
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await updateKlhkReportParams(payloadForm.id, payload)
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='mean'
          value={formData.mean}
          label='Jumlah 50% Rata-Rata'
          placeholder='Masukkan jumlah'
          error={validationForm.mean.isError ? `${validationForm.mean.message}` : ''}
          onChange={handleChange}
          withAsterisk
          type='number'
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='percentile25'
          value={formData.percentile25}
          label='Jumlah 25% Teratas'
          placeholder='Masukkan jumlah'
          error={validationForm.percentile25.isError ? `${validationForm.percentile25.message}` : ''}
          onChange={handleChange}
          withAsterisk
          type='number'
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='percentile75'
          value={formData.percentile75}
          label='Jumlah 25% Terbawah'
          placeholder='Masukkan jumlah'
          error={validationForm.percentile75.isError ? `${validationForm.percentile75.message}` : ''}
          onChange={handleChange}
          withAsterisk
          type='number'
        />
      </Box>
      <Box mb='md'>
        <Select
          name="parameterId"
          label="Parameter"
          placeholder="Pilih salah satu parameter"
          searchable
          nothingFoundMessage='Parameter tidak ditemukan'
          data={dataParameter(parameterList)}
          onChange={(e) => setSelectedParameterId(e)}
          error={validationForm.parameterId.isError ? `${validationForm.parameterId.message}` : ''}
          value={selectedParameterId}
          withAsterisk
          checkIconPosition="right"
          disabled
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitReportParams(formData)}>{dataKlhkParams === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormKlhkReportParams