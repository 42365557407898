import http from '../plugins/axios'

export const getListKlhkReportParams = async (params) => {
  try {
    const response = await http.get(`/klhk-report-parameters`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addSingleKlhkReportParams = async (payload) => {
  try {
    const response = await http.post(`/klhk-report-parameters`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addMultipleKlhkReportParams = async (payload) => {
  try {
    const response = await http.post(`/klhk-report-parameters/batch`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const generateKlhkReportParams = async (payload) => {
  try {
    const response = await http.post(`/klhk-report-parameters/batch/generate`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}


export const updateKlhkReportParams = async (reportParamsId, payload) => {
  try {
    const response = await http.patch(`/klhk-report-parameters/${reportParamsId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteKlhkReportParams = async (reportParamsId) => {
  try {
    const response = await http.delete(`/klhk-report-parameters/${reportParamsId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadImageKlhkReport = async (klhkReportId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/klhk-report-parameters/${klhkReportId}/image`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}