import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Center, Loader, Group, Button, Affix, Transition, rem } from '@mantine/core'
import { getListBenchmarkParameter} from '../../../services/benchmark'
import { getDetailBenchmarkReport } from '../../../services/benchmark-report'
import { assignBenchmarkReportComparison } from '../../../services/benchmark-report'
import { notificationSuccess, notificationError } from '../../ui/Notifications'
import { updatedDataBenchmark } from '../../../store/benchmarkData'
import DetailBenchmarkResult from '../BenchmarkResult/DetailBenchmarkResult'
import { groupBy } from '../../../plugins/addons'
import { IconArrowUp } from '@tabler/icons-react'
import { useWindowScroll } from '@mantine/hooks'

const defaultCompany = {
  name: '',
  description: '',
  companyReport: {
    company: {
      name: ''
    }
  },
  benchmarker: {
    name: ''
  },
  createdBy: {
    fullName: ''
  }
}

const BenchmarkDataCompany = ({ resetStep }) => {
  const [scroll, scrollTo] = useWindowScroll()
  const dispatch = useDispatch()
  const dataBenchmark = useSelector(state => state.benchmark.dataBenchmark)
  const [dataBenchmarkResult, setDataBenchmarkResult] = useState([])
  const [detailBenchmark, setDetailBenchmark] = useState(defaultCompany)
  const [loading, setLoading] = useState(true)

  const setValueKlhkParamsId = (data) => {
    let val = null
    if (data !== null) {
      if (typeof data === 'object') {
        val = data.id
      } else {
        val = data
      }
    }
    return val
  }

  const handleAssignBenchmark = async (detailBenchmark) => {
    const payload = {
      isFinished: true,
      benchmarkParameterComparisons: [],
      isChangeParaphrase: dataBenchmark.changeParaphrase
    }

    const mappingPayload = (data) => {
      if (data !== null) {
        for (let i = 0; i < data.length; i++) {
          const remapDataBenchmark = {
            benchmarkParameterId: data[i].benchmarkParameterId,
            companyReportParameters: data[i].companyReportParameterIds.length > 0 ? remapParameterId(data[i].companyReportParameterIds) : [],
            klhkReportParameterId: setValueKlhkParamsId(data[i].klhkReportParameterId)
          }
          payload.benchmarkParameterComparisons.push(remapDataBenchmark)
        }
      }
    }

    const remapParameterId = (parameter) => parameter.map((val) => {
      const remap = {
        id: val.id,
        customIntensity: val.intensity
      }
      return remap
    })
 
    mappingPayload(JSON.parse(dataBenchmark.compareBenchmarkLevelAsia))
    mappingPayload(JSON.parse(dataBenchmark.compareBenchmarkLevelNational))
    mappingPayload(JSON.parse(dataBenchmark.compareBenchmarkLevelInternational))

    try {
      const response = await assignBenchmarkReportComparison(dataBenchmark.benchmarkReportId, payload)
      if (response.affected === 1) {
        notificationSuccess('Benchmark Report Berhasil', 'Anda telah berhasil melakukan benchmark')
      }
    } catch (error) {
      console.log(error)
      notificationError('Benchmark Report Gagal', 'Anda gagal melakukan benchmark, silahkan ulangi kembali')
    } finally {
      setLoading(false)
    }
  }

  const handleGetBenchmarkReport = async (benchmarkReportId) => {
    setLoading(true)
    const params = {
      benchmarkReport: dataBenchmark.benchmarkReportId
    }
    try {
      const responseDetailbenchmark = await getDetailBenchmarkReport(dataBenchmark.benchmarkReportId)
      const responseDataBenchmarkParams = await getListBenchmarkParameter(params)
      const dataBenchmarkParams = responseDataBenchmarkParams.data
      setDetailBenchmark(responseDetailbenchmark)
      const groupParameter = groupBy(dataBenchmarkParams, val => val.companyReportParameter.parameter.id)
      setDataBenchmarkResult(Object.values(groupParameter))
      await handleAssignBenchmark(responseDetailbenchmark)
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    handleGetBenchmarkReport()
    // eslint-disable-next-line
  }, [])


  const LoadingData = () => {
    return (
      <Center h={500}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }

  const toStartPage = () => {
    dispatch(updatedDataBenchmark({ 'layoutStep': 0, 'dataBenchmark': null }))
    resetStep()
  }


  return (
    <Box>
      <Box>
        {loading ? <LoadingData /> : <DetailBenchmarkResult detailBenchmark={detailBenchmark} dataResult={dataBenchmarkResult} />}
      </Box>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftSection={<IconArrowUp style={{ width: rem(16), height: rem(16) }} />}
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              Scroll Ke Atas
            </Button>
          )}
        </Transition>
      </Affix>    
      <Group justify='center' mt="xl">
        <Button variant="default" onClick={toStartPage}>Kembali Ke Awal</Button>
      </Group>
    </Box>
  )
}

export default BenchmarkDataCompany