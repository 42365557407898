import http from '../plugins/axios'

export const getListAttachment = async (params) => {
  try {
    const response = await http.get(`/attachments`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailAttachment = async (attachmentId) => {
  try {
    const response = await http.get(`/attachments/${attachmentId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addAttachment = async (payload) => {
  try {
    const response = await http.post(`/attachments`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateAttachment = async (attachmentId, payload) => {
  try {
    const response = await http.patch(`/attachments/${attachmentId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteAttachment = async (attachmentId) => {
  try {
    const response = await http.delete(`/attachments/${attachmentId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadAttachmentImage = async (attachmentId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/attachments/${attachmentId}/file`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}