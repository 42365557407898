import http from '../plugins/axios'

export const getListUsers = async (params) => {
  try {
    const response = await http.get(`/users`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addUser = async (payload) => {
  try {
    const response = await http.post(`/users`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUser = async (userId, payload) => {
  try {
    const response = await http.patch(`/users/${userId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}
export const deleteUser = async (userId) => {
  try {
    const response = await http.delete(`/users/${userId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUserPassword = async (userId, payload) => {
  try {
    const response = await http.post(`/users/${userId}/password`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateUserImage = async (userId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/users/${userId}/image`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}