import Login from './pages/public/Login'
import ForgotPassword from './pages/public/ForgotPassword'
import VerifyPassword from './pages/public/VerifyPassword'

// authorization menu
import Dashboard from './pages/administrator/Dashboard'
import Users from './pages/administrator/Users'
import Roles from './pages/administrator/Roles'
// import Permission from './pages/administrator/Permission'
import Industries from './pages/administrator/Industries'
import Companies from './pages/administrator/Companies'
import UnitConversion from './pages/administrator/UnitConversion'
import Parameter from './pages/administrator/Parameter'
import CompanyReport from './pages/administrator/Companies/CompanyReport'
import KlhkReport from './pages/administrator/KlhkReport'
import KlhkReportParams from './pages/administrator/KlhkReport/KlhkReportParams'
import CompanyReportGlobal from './pages/administrator/CompanyReport'
import CompanyReportParamsGlobal from './pages/administrator/CompanyReport/CompanyReportParams'
import BenchmarkReport from './pages/administrator/BenchmarkReport'
import Benchmark from './pages/administrator/Benchmark'
import BenchmarkResult from './pages/administrator/BenchmarkResult'
import DetailbenchmarkComparison from './pages/administrator/BenchmarkResult/DetailBenchmarkComparison'
import Unit from './pages/administrator/Unit'
import Aspect from './pages/administrator/Aspect'
import Benchmarker from './pages/administrator/Benchmarker'

// icon dashboard route
import { 
  IconGauge,
  IconUser,
  // IconListCheck,
  IconUserCheck,
  IconBuildingFactory,
  IconCategory,
  IconTransform,
  IconAccessible,
  IconReport,
  IconReportAnalytics,
  IconBrandSpeedtest,
  IconPresentationAnalytics,
  IconAtom,
  IconLayersIntersect2
} from "@tabler/icons-react"

// permission
import { MENU_PERMISSION } from './plugins/helper'

const routes = [
  {
    name: 'login-page',
    route: '/login',
    hasChildren: false,
    component: <Login />
  },
  {
    name: 'forgot-password',
    route: '/password-resets',
    hasChildren: false,
    component: <ForgotPassword />
  },
  {
    name: 'forgot-password-verify',
    route: '/password-resets/verify',
    hasChildren: false,
    component: <VerifyPassword />
  },
  {
    name: 'dashboard',
    route: '/',
    hasChildren: false,
    component: <Dashboard />,
  },
]

const authRoutes = [
  {
    name: 'dashboard',
    route: '/dashboard',
    hasChildren: false,
    component: <Dashboard />,
    icon: <IconGauge stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.dashboard
  },
  {
    name: 'feature',
    type: 'title',
    permission: MENU_PERMISSION.benchmark
  },
  {
    name: 'benchmark',
    route: '/benchmark',
    hasChildren: false,
    component: <Benchmark />,
    icon: <IconBrandSpeedtest stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.benchmark
  },
  {
    name: 'hasil benchmark',
    route: '/benchmark-results',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <BenchmarkResult />
      },
      {
        name: 'Company Report',
        route: '/benchmark-results/:id',
        component: <DetailbenchmarkComparison />
      },
    ],
    icon: <IconPresentationAnalytics stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.benchmarker
  },
  {
    name: 'bendera kontrak',
    route: '/benchmarker',
    hasChildren: false,
    component: <Benchmarker />,
    icon: <IconBrandSpeedtest stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.benchmarker
  },
  {
    name: 'industries management',
    type: 'title',
    permission: MENU_PERMISSION.company
  },
  {
    name: 'sektor perusahaan',
    route: '/corporate-sector',
    hasChildren: false,
    component: <Industries />,
    icon: <IconCategory stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.industry
  },
  {
    name: 'perusahaan',
    route: '/company',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Companies />
      },
      {
        name: 'laporan perusahaan',
        route: '/company/report/:id',
        component: <CompanyReport />
      },
    ],
    icon: <IconBuildingFactory stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.company
  },
  {
    name: 'Report Management',
    type: 'title',
    permission: MENU_PERMISSION.klhkReport
  },
  {
    name: 'data pembanding',
    route: '/company-report',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <CompanyReportGlobal />
      },
      {
        name: 'Company Report Parameter',
        route: '/company-report/parameters/:id',
        component: <CompanyReportParamsGlobal />
      },
    ],
    icon: <IconReport stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.companyReport
  },
  {
    name: 'data client',
    route: '/benchmark-report',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <BenchmarkReport />
      },
      {
        name: 'laporan benchmark parameter',
        route: '/benchmark-report/parameters/:id',
        component: <CompanyReportParamsGlobal />
      },
    ],
    icon: <IconReportAnalytics stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.companyReport
  },
  {
    name: 'data perdirjen',
    route: '/perdirjen',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <KlhkReport />
      },
      {
        name: 'data perdirjen parameter',
        route: '/perdirjen/parameters/:id',
        component: <KlhkReportParams />
      },
    ],
    icon: <IconReport stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.klhkReport
  },
  {
    name: 'common',
    type: 'title',
    permission: MENU_PERMISSION.parameter
  },
  {
    name: 'aspek perusahaan',
    route: '/aspect',
    hasChildren: false,
    component: <Aspect />,
    icon: <IconLayersIntersect2 stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.aspect
  },
  {
    name: 'parameter',
    route: '/unit-parameter',
    hasChildren: false,
    component: <Parameter />,
    icon: <IconAccessible stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.parameter
  },
  {
    name: 'converter',
    type: 'title',
    permission: MENU_PERMISSION.unit
  },
  {
    name: 'satuan unit',
    route: '/units',
    hasChildren: false,
    component: <Unit />,
    icon: <IconAtom stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.unit
  },
  {
    name: 'konversi unit',
    route: '/unit-conversion',
    hasChildren: false,
    component: <UnitConversion />,
    icon: <IconTransform stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.unit
  },
  {
    name: 'account management',
    type: 'title',
    permission: MENU_PERMISSION.user
  },
  {
    name: 'user',
    route: '/users',
    hasChildren: false,
    component: <Users />,
    icon: <IconUser stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.user
  },
  {
    name: 'role',
    route: '/roles',
    hasChildren: false,
    component: <Roles />,
    icon: <IconUserCheck stroke={1.5} size={18} />,
    type: 'route',
    permission: MENU_PERMISSION.role
  },
  // disable route for all user (developer only)
  // {
  //   name: 'permission',
  //   route: '/permission',
  //   hasChildren: false,
  //   component: <Permission />,
  //   icon: <IconListCheck stroke={1.5} size={18} />,
  //   type: 'route',
  //   permission: MENU_PERMISSION.permission
  // },
]



export { routes, authRoutes }