import http from '../plugins/axios'

export const getListCompanyReportParams = async (params) => {
  try {
    const response = await http.get(`/company-report-parameters`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addSingleCompanyReportParams = async (payload) => {
  try {
    const response = await http.post(`/company-report-parameters`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addMultipleCompanyReportParams = async (payload) => {
  try {
    const response = await http.post(`/company-report-parameters/batch`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const generateCompanyReportParams = async (payload) => {
  try {
    const response = await http.post(`/company-report-parameters/batch/generate`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateCompanyReportParams = async (reportParamsId, payload) => {
  try {
    const response = await http.patch(`/company-report-parameters/${reportParamsId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteCompanyReportParams = async (reportParamsId) => {
  try {
    const response = await http.delete(`/company-report-parameters/${reportParamsId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadImageCompanyReport = async (reportParamsId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/company-report-parameters/${reportParamsId}/image`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}