import http from '../plugins/axios'

export const getListBenchmarkParameter = async (params) => {
  try {
    const response = await http.get(`/benchmark-parameters`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailBenchmarkParameter = async (benchmarkParamsId) => {
  try {
    const response = await http.get(`/benchmark-parameters/${benchmarkParamsId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDataBenchmarkReportReview = async (payload) => {
  try {
    const response = await http.post(`/benchmark-parameters/preview`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignBenchmarkParameter = async (payload) => {
  try {
    const response = await http.post(`/benchmark-parameters/comparison`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}


export const uploadBenchmarkParameterCustomChart = async (benchmarkParamsId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`benchmark-parameters/${benchmarkParamsId}/custom-chart`, payload, config)
    return response.data
  } catch(error) {
    throw error
  }
}