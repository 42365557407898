import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Select, Skeleton } from '@mantine/core'
import { addUnitConversion, updateUnitConversion, getListUnit } from '../../../services/unit-conversion'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  unitFromId: '',
  unitToId: '',
  value: ''
}

const formValidation = {
  unitFromId: {
    isError: false,
    message: ''
  },
  unitToId: {
    isError: false,
    message: ''
  },
  value: {
    isError: false,
    message: ''
  }
}

const FormUnitConversion = ({ defaultUnit, dataUnitConversion, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [selectedDefaultUnit, setSelectedDefaultUnit] = useState(null)
  const [selectedConvertUnit, setSelectedConvertUnit] = useState(null)
  const [unitList, setUnitList] = useState([])
  const [loadingUnit, setLoadingUnit] = useState(true)

  const handleGetListUnit = async () => {
    setLoadingUnit(true)
    try {
      const response = await getListUnit()
      const dataUnit = response.data
      const mappingUnit = dataUnit.map((val) => {
        return {
          value: val.id,
          label: `${val.name} (${val.symbol})`
        }
      })
      setUnitList(mappingUnit)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingUnit(false)
    }
  }

  useEffect(() => {
    handleGetListUnit()
    handleSetForm(defaultUnit, dataUnitConversion)
  }, [dataUnitConversion, defaultUnit])

  const handleSetForm = (defaultUnit, detailValue) => {
    if (detailValue !== null) {
      const dataDetail = {
        id: detailValue.id,
        unitFromId: detailValue.unitFrom.id,
        unitToId: detailValue.unitTo.id,
        value: detailValue.value
      }
      setSelectedDefaultUnit(detailValue.unitFrom.id)
      setSelectedConvertUnit(detailValue.unitTo.id)
      setFormData(dataDetail)
    } else if (defaultUnit !== undefined) {
      const defaultVal = {
        unitFromId: defaultUnit.id,
        unitToId: '',
        value: ''
      }
      setSelectedDefaultUnit(defaultUnit.id)
      setSelectedConvertUnit(null)
      setFormData(defaultVal)
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitUnitConversion = async (payloadUnitConversion) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)

    let value = Number(payloadUnitConversion.value);
    if (dataUnitConversion && dataUnitConversion.isReversed) {
      value = 1 / value;
    }
    const payload = {
      unitFromId: selectedDefaultUnit,
      unitToId: selectedConvertUnit,
      value,
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (selectedDefaultUnit === selectedConvertUnit) {
      setValidationForm((old) => ({
        ...old,
        unitToId: {
          isError: true,
          message: 'Unit konversi tidak boleh sama dengan unit utama'
        }
      }))
      setLoadingForm(false)
      return 
    }
    if (defaultUnit !== null && dataUnitConversion !== null) {
      const payloadUpdate = {
        value: payload.value
      }
      methodFunction = updateUnitConversion(payloadUnitConversion.id, payloadUpdate)
      titleMessageSuccess = 'Update Unit Konversi Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate unit konversi'
      titleMessageError = 'Gagal Mengupdate Unit Konversi'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addUnitConversion(payload)
      titleMessageSuccess = 'Tambah Unit Konversi Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan unit konversi baru'
      titleMessageError = 'Gagal Menambahkan Unit Konversi'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  const loadingUnitForm = () => {
    return (
      <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ sm: 'center' }}>
        <Skeleton h={30} />
        <Skeleton h={30} />
      </Flex>
    )
  }

  const loadUnitForm = () => {
    return (
      <Flex mb='md' direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }}>
        <Box w='100%'>
          <Select
            name="unitFromId"
            label="Unit Utama"
            placeholder="Pilih salah satu unit"
            searchable
            nothingFoundMessage='satuan unit tidak ditemukan'
            data={unitList}
            onChange={(e) => setSelectedDefaultUnit(e)}
            error={validationForm.unitFromId.isError ? `${validationForm.unitFromId.message}` : ''}
            value={selectedDefaultUnit}
            withAsterisk
            checkIconPosition="right"
            fullWidth
            disabled={defaultUnit || dataUnitConversion} 
          />
        </Box>
        <Box w='100%'>
          <Select
            name="unitToId"
            label="Unit Konversi"
            placeholder="Pilih salah satu unit"
            searchable
            nothingFoundMessage='satuan unit tidak ditemukan'
            data={unitList}
            onChange={(e) => setSelectedConvertUnit(e)}
            error={validationForm.unitToId.isError ? `${validationForm.unitToId.message}` : ''}
            value={selectedConvertUnit}
            withAsterisk
            checkIconPosition="right"
            disabled={dataUnitConversion !== null}
          />
        </Box>
      </Flex>
    )
  }


  return (
    <Box>
      <Box mb='md'>
        {loadingUnit ? loadingUnitForm() : loadUnitForm()}
      </Box>
      <Box mb='md'>
        <Flex mb='md' direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }}>
          <Box w='100%'>
            <TextInput
              value={1}
              label='Jumlah Satuan Unit'
              disabled
            />
          </Box>
          <Box w='100%'>
            <TextInput
              name='value'
              value={formData.value}
              label='Jumlah Konversi Unit'
              placeholder='Masukkan jumlah konversi unit'
              error={validationForm.value.isError ? `${validationForm.value.message}` : ''}
              onChange={handleChange}
              withAsterisk
              type='number'
            />
          </Box>
        </Flex>
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitUnitConversion(formData)}>Simpan</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormUnitConversion