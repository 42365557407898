import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { getListUsers, deleteUser } from '../../../services/users'
import classes from '../Auth.module.css'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button, TextInput, Select } from '@mantine/core'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TableUsers from '../../../components/pages/Users/TableUsers'
import FormUser from '../../../components/pages/Users/FormUser'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'fullName',
  order: 'asc'
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'fullName',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Email',
    width: 'auto'
  },
  {
    label: 'Role',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const Users = () => {
  const [ params, setParams ] = useState(defaultParameter)
  const [ userList, setUserList ] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState('createdAt')
  const [selectedOrder, setSelectedOrder] = useState('desc')

  const mappingOrder = [
    {
      value: 'asc',
      label: selectedFilter === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: selectedFilter === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetUserList = async () => {
    setLoading(true)
    try {
      const response = await getListUsers(params)
      setUserList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetUserList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectFilter = (filterOrder) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: filterOrder,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: orders
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('pengguna', val.id, val.fullName, deleteUser, handleGetUserList)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Manajemen Pengguna</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            <Button color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah User</Button>
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari User. . .'
              name="search"
              onChange={handleSearchChange}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [setSelectedFilter(e), handleSelectFilter(e)]}
              value={selectedFilter}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={5} /> : <TableUsers label={labelTable} data={userList} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} User</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormUser dataUser={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetUserList} />
      </Modal>
    </AuthLayout>
    
  )
}

export default Users