import http from "../plugins/axios"

export const getPermissionList = async () => {
  try {
    const response = await http.get(`/permissions`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addPermission = async (payload) => {
  try {
    const response = await http.post(`/permissions`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}