import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button } from '@mantine/core'
import classes from '../../Auth.module.css'
import { getListCompanyReportParams, deleteCompanyReportParams, generateCompanyReportParams } from '../../../../services/company-report-params'
import { getDetailCompanyReportById } from '../../../../services/company-report'
import TableSkeleton from '../../../../components/ui/TableSkeleton'
import { modalDeleteData } from '../../../../components/ui/ModalManager/modalDeleteData'
import TableCompanyReportParams from '../../../../components/pages/CompanyReport/ReportParams/TableCompanyReportParams'
import FormCompanyReportParams from '../../../../components/pages/CompanyReport/ReportParams/FormCompanyReportParams'
import { useParams } from 'react-router-dom'
import AttachImageCompanyReport from '../../../../components/pages/CompanyReport/ReportParams/AttachImageCompanyReport'
import { notificationSuccess, notificationError } from '../../../../components/ui/Notifications'
import { useLocation } from 'react-router-dom'
const defaultReport = {
  id: null,
  company: {
    name: '',
    level: '',
    address: ''
  },
  isBenchmark: null,
  name: '',
  year: null,
  isLocked: false
}

const defaultLevel = {
  'national': 'Nasional',
  'asia': 'Asia',
  'international': 'Internasional'
}

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Parameter',
    width: 'auto'
  },
  {
    label: 'Aspek',
    width: 'auto'
  },
  {
    label: 'Pemakaian/Timbulan',
    width: 'auto'
  },
  {
    label: 'konversi Pemakaian/Timbulan',
    width: 'auto'
  },
  {
    label: 'Jumlah Pengkali',
    width: 'auto'
  },
  {
    label: 'Intensitas',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const CompanyReportParams = () => {
  const parameter = useParams()
  const companyReportId = parameter.id
  const { pathname } = useLocation()
  const defaultParameter = {
    skip: 0,
    take: 10,
    search: '',
    companyReport: companyReportId,
    orderBy: 'order',
    order: 'asc'
  }
  const [params, setParams] = useState(defaultParameter)
  const [detailCompanyReport, setDetailCompanyReport] = useState(defaultReport)
  const [reportParamsList, setReportParamsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [count, setCount] = useState(0)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [openModalAttachImage, setOpenModalAttachImage] = useState(false)
  
  const handleGetDetailCompany = async (companyId) => {
    try {
      const response = await getDetailCompanyReportById(companyId)
      setDetailCompanyReport(response)
    } catch (error) {
      console.log(error)
    }
  }

  const initDataReportParams = async () => {
    setLoading(true)
    try {
      const response = await getListCompanyReportParams(params)
      setReportParamsList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const updateRecentParameter = async () => {
    setLoadingUpdate(true)
    const payload = {
      companyReportId: companyReportId
    }
    try {
      const response = await generateCompanyReportParams(payload)
      if (response) {
        initDataReportParams()
        notificationSuccess('Update Parameter Berhasil', 'Anda telah berhasil melakukan update parameter')
      }
    } catch (error) {
      console.log(error)
      notificationError('Update Parameter Gagal', 'Silahkan menghubungi admin sistem untuk mengatasi masalah ini')
    } finally {
      setLoadingUpdate(false)
    }
  }
  
  useEffect(() => {
    handleGetDetailCompany(companyReportId)
    // eslint-disable-next-line
  }, [companyReportId])

  useEffect(() => {
    initDataReportParams()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      companyReport: companyReportId,
      orderBy: 'order',
      order: 'asc'
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('Laporan Perusahaan Parameter', val.id, val.parameter.name, deleteCompanyReportParams, initDataReportParams)
    },
    attach: (val) => {
      setOpenModalAttachImage(true)
      setDetailData(val)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalAttach = () => {
    setOpenModalAttachImage(false)
    setDetailData(null)
  }

  return (
    <AuthLayout>
      <Box>
        <Box mb={40}>
          <Text className={classes.titlePage} mb={40} ta='center'>Informasi Laporan Perusahaan</Text>
          <Box>
            <Box mb={12}>
              <Text fz={13}>Nama Perusahaan:</Text>
              <Text fz={13} fw='600'>{detailCompanyReport.company.name}</Text>
            </Box>
            <Box mb={12}>
              <Text fz={13}>Level Perusahaan:</Text>
              <Text fz={13} fw='600'>{defaultLevel[detailCompanyReport.company.level]}</Text>
            </Box>
            <Box mb={12}>
              <Text fz={13}>Alamat Perusahaan:</Text>
              <Text fz={13} fw='600'>{detailCompanyReport.company.address}</Text>
            </Box>
            <Box mb={12}>
              <Text fz={13}>Nama Laporan:</Text>
              <Text fz={13} fw='600'>{detailCompanyReport.name}</Text>
            </Box>
            <Box mb={12}>
              <Text fz={13}>Tahun Laporan:</Text>
              <Text fz={13} fw='600'>{detailCompanyReport.year}</Text>
            </Box>
          </Box>
        </Box>
        <Text className={classes.titlePage} mb={20}>{pathname.includes('company-report') ? 'Intensitas Data Pembanding' : 'Intensitas Perusahaan'}</Text>
        <Flex justify='flex-end' mb={14}>
          <Button color='indigo.9' size='xs' loading={loadingUpdate} onClick={updateRecentParameter} disabled={detailCompanyReport.isLocked}>Update Parameter Terbaru</Button>
        </Flex>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableCompanyReportParams label={labelTable} data={reportParamsList} countData={params.skip} actionMethod={handleAction} />}
          <Text fw={600} fz={12} c='red'>Catatan: Silahkan isi jumlah produksi terlebih dahulu sebelum mengisi parameter yang lain</Text>
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage}  total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>Update Laporan Perusahaan Parameter</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormCompanyReportParams dataCompanyParams={detailData} reportId={companyReportId} onCloseModal={onCloseModalForm} reloadList={initDataReportParams} />
      </Modal>
      <Modal opened={openModalAttachImage} onClose={onCloseModalAttach} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>Lampirkan File Gambar Laporan</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <AttachImageCompanyReport dataCompanyParams={detailData} onCloseModal={onCloseModalAttach} reloadList={initDataReportParams} />
      </Modal>
    </AuthLayout>
  )
}

export default CompanyReportParams