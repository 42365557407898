import React, { useEffect, useState } from 'react'
import AuthLayout from '../../../../layouts/AuthLayout'
import { Box, Text } from '@mantine/core'
import { getDetailCompany } from '../../../../services/companies'
import classes from '../../Auth.module.css'
import { useParams } from 'react-router-dom'
import CompanyReportTable from './CompanyReportTable'
const defaultLevel = {
  'national': 'Nasional',
  'asia': 'Asia',
  'international': 'Internasional'
}

const defaultValue = {
  name: '',
  level: '',
  address: '',
  industry: {},
  country: {},
  profile: '',
  operational: '',
  contactPerson: '',
  isClient: false
}


const CompanyReport = () => {
  const params = useParams()
  const companyId = params.id
  const [detailCompany, setDetailCompany] = useState(defaultValue)

  const handleDetailCompany = async () => {
    try {
      const response = await getDetailCompany(companyId)
      setDetailCompany(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleDetailCompany()
    // eslint-disable-next-line
  }, [])

  const mappingInfoCompany = (dataClient) => {
    if (dataClient) {
      const innerHtmlProfile = { __html: detailCompany.profile }
      const innerHtmlOperational = { __html: detailCompany.operational }
      const innerHtmlContactPerson = { __html: detailCompany.contactPerson }
      return (
        <Box>
          <Box mb={16}>
            <Text fz={13} fw='600'>Alamat Perusahaan:</Text>
            <Text fz={13}>{detailCompany.address}</Text>
          </Box>
          <Box mb={16}>
            <Text fz={13} fw='600'>Profil Perusahaan:</Text>
            <Box dangerouslySetInnerHTML={innerHtmlProfile} />
          </Box>
          <Box mb={16}>
            <Text fz={13} fw='600'>Operasional Perusahaan:</Text>
            <Box dangerouslySetInnerHTML={innerHtmlOperational} />
          </Box>
          <Box mb={16}>
            <Text fz={13} fw='600'>Kontak Perusahaan:</Text>
            <Box dangerouslySetInnerHTML={innerHtmlContactPerson} />
          </Box>
        </Box>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        <Box mb={40}>
          <Text className={classes.titlePage} mb={40} ta='center'>Informasi Perusahaan</Text>
          <Box>
            <Box mb={16}>
              <Text fz={13} fw='600'>Nama Perusahaan:</Text>
              <Text fz={13}>{detailCompany.name}</Text>
            </Box>
            <Box mb={16}>
              <Text fz={13} fw='600'>Level Perusahaan:</Text>
              <Text fz={13}>{defaultLevel[detailCompany.level]}</Text>
            </Box>
            <Box mb={16}>
              <Text fz={13} fw='600'>Kategori Perusahaan:</Text>
              <Text fz={13}>{detailCompany.industry ? detailCompany.industry.name : '-'}</Text>
            </Box>
            <Box mb={16}>
              <Text fz={13} fw='600'>Negara:</Text>
              <Text fz={13}>{detailCompany.country ? detailCompany.country.name : '-'}</Text>
            </Box>
            {mappingInfoCompany(detailCompany.isClient)}
          </Box>
        </Box>

        <Box>
          <Text className={classes.titlePage} mb={20}>Laporan Perusahaan</Text>
          <Box px={10}>
            <CompanyReportTable companyId={companyId} />
          </Box>
        </Box>
      </Box>
    </AuthLayout>
    
  )
}

export default CompanyReport