import React from 'react'
import { Table, Flex, ActionIcon, Box, Text, Tooltip } from '@mantine/core'
import { IconTrash, IconPencil } from '@tabler/icons-react'
import { thousandFormat, decimalFormat, decimalCheck } from '../../../plugins/addons'

const ActionMenu = ({ handleAction }) => {
  return (
    <Flex gap='xs'>
      <Tooltip label='Edit Intensitas'>
        <ActionIcon variant="transparent" onClick={() => handleAction('update')}>
          <IconPencil stroke={1.5} size={18} />
        </ActionIcon>
      </Tooltip>
      <Tooltip label='Hapus Pilihan'>
        <ActionIcon color='red' variant="transparent" onClick={() => handleAction('delete')}>
          <IconTrash stroke={1.5} size={18} />
        </ActionIcon>
      </Tooltip>
    </Flex>
  )
}

const TableSelectedCompanyReport = ({ label, data, actionMethod }) => {

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingIsProductionParams = (val) => {
    if (!val.parameter.aspect.isProduction) {
      const valueIntensity = val.customIntensity ? val.customIntensity : val.intensity
      return (<Table.Td>{valueIntensity ? decimalCheck(valueIntensity) ? thousandFormat(valueIntensity) : decimalFormat(valueIntensity) : 0} {val.parameter.unit.symbol} / {val.parameter.divider.unit.symbol}</Table.Td>)
    } else {
      return (<Table.Td>-</Table.Td>)
    }
  }

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.companyReport.company.name}</Table.Td>
        <Table.Td>{val.companyReport.name}</Table.Td>
        <Table.Td>{val.companyReport.year}</Table.Td>
        <Table.Td>{decimalCheck(val.finalValue) ? thousandFormat(val.finalValue) : decimalFormat(val.finalValue)} {val.parameter.unit.symbol}</Table.Td>
        {mappingIsProductionParams(val)}
        {actionMethod !== undefined ? (<Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} /></Table.Td>) : ''}
      </Table.Tr>
    )
  })

  if (data.length > 0) {
    return (
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    )
  } else {
    return (  
      <Box ta='center' my={50}>
        <Text fz={13} mb={6} c='gray.6'>"Belum ada sumber data yang terpilih"</Text>
      </Box>
    )
  }
  
}

export default TableSelectedCompanyReport