import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button, TextInput, Select } from '@mantine/core'
import classes from '../Auth.module.css'
import { getListCompanyReport, deleteCompanyReport, assignLockCompanyReport } from '../../../services/company-report'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { modalSetStatusReport } from '../../../components/ui/ModalManager/modalSetStatusReport'
import TableCompanyReport from '../../../components/pages/CompanyReport/TableCompanyReport'
import FormCompanyReport from '../../../components/pages/CompanyReport/FormCompanyReport'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  type: 'benchmark',
  isFinished: false,
  orderBy: 'createdAt',
  order: 'desc'
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
  {
    value: 'year',
    label: 'Tahun'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Perusahaan',
    width: 'auto'
  },
  {
    label: 'Sektor Perusahaan',
    width: 'auto'
  },
  {
    label: 'Level Perusahaan',
    width: 'auto'
  },
  {
    label: 'Nama Laporan',
    width: 'auto'
  },
  {
    label: 'Tahun Laporan',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 100
  },
  {
    label: 'Action',
    width: 100
  },
]
 
const BenchmarkReport = () => {
  const permissionState = useSelector(state => state.permission.access)
  const navigate = useNavigate()
  const [params, setParams] = useState(defaultParameter)
  const [companyReportList, setCompanyReportList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState('createdAt')
  const [selectedOrder, setSelectedOrder] = useState('desc')

  const mappingOrder = [
    {
      value: 'asc',
      label: selectedFilter === 'name' ? 'A - Z' : 'Terlama - Terbaru'
    },
    {
      value: 'desc',
      label: selectedFilter === 'name' ? 'Z - A' : 'Terbaru - Terlama'
    },
  ]

  const handleGetCompanyReportList = async () => {
    setLoading(true)
    try {
      const response = await getListCompanyReport(params)
      setCompanyReportList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetCompanyReportList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      type: 'benchmark',
      isFinished: false,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      type: 'benchmark',
      isFinished: false,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectFilter = (filterOrder) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      type: 'benchmark',
      isFinished: false,
      orderBy: filterOrder,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      type: 'benchmark',
      isFinished: false,
      orderBy: params.orderBy,
      order: orders
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    report: (val) => {
      let url = ''
      if (val.type === 'benchmark') {
        url = 'benchmark-report'
      } else {
        url = 'company-report'
      }
      navigate(`/${url}/parameters/${val.id}`)
    },
    delete: (val) => {
      modalDeleteData('Laporan Benchmark', val.id, val.name, deleteCompanyReport, handleGetCompanyReportList)
    },
    unlock: (val) => {
      modalSetStatusReport(val.id, val.name, false, 'company-report', assignLockCompanyReport, handleGetCompanyReportList)
    },
    lock: (val) => {
      modalSetStatusReport(val.id, val.name, true, 'company-report', assignLockCompanyReport, handleGetCompanyReportList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionReport = permissionState['company-report']
    const createdPermission = permissionReport.find(val => val.alias === 'company-report.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Laporan</Button>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Manajemen Data Client</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Laporan. . .'
              name="search"
              onChange={handleSearchChange}
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [setSelectedFilter(e), handleSelectFilter(e)]}
              value={selectedFilter}
              checkIconPosition="right"
              allowDeselect={false}
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableCompanyReport label={labelTable} data={companyReportList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Laporan Benchmark</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormCompanyReport dataReport={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetCompanyReportList} typeReport='benchmark' isClientCompany={true} />
      </Modal>
    </AuthLayout>
  )
}

export default BenchmarkReport