import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea, Select } from '@mantine/core'
import { addAspect, updateAspect } from '../../../services/aspect'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  description: '',
  isProduction: 'false',
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  isProduction: {
    isError: false,
    message: ''
  },
}

const defaultProduction = [
  {
    value: 'true',
    label: 'Ya'
  },
  {
    value: 'false',
    label: 'Tidak'
  }
]

const FormAspect = ({ dataAspect, aspectProduction, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [selectedProduction, setSelectedProduction] = useState('false')

  const isProductionState = aspectProduction.find((val) => {
    return val.isProduction
  })

  useEffect(() => {
    setSelectedProduction(isProductionState ? 'false' : null)
    if (dataAspect !== null) {
      handleSetForm(dataAspect)
    }
    // eslint-disable-next-line
  }, [dataAspect])

  const handleSetForm = (val) => {
    const dataDetail = {
      id: val.id,
      name: val.name,
      description: val.description,
      isProduction: val.isProduction.toString(),
    }
    setSelectedProduction(val.isProduction ? 'true' : 'false')
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitAspect = async (payloadAspect) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadAspect.name,
      description: payloadAspect.description,
      isProduction: selectedProduction
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    payload.isProduction = selectedProduction === 'true' ? true : false
    if (dataAspect !== null) {
      methodFunction = updateAspect(payloadAspect.id, payload)
      titleMessageSuccess = 'Update Aspek Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate aspek'
      titleMessageError = 'Gagal Mengupdate Aspek'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addAspect(payload)
      titleMessageSuccess = 'Tambah Aspek Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan aspek baru'
      titleMessageError = 'Gagal Menambahkan Aspek'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }


  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Aspek'
          placeholder='Masukkan nama aspek'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi'
          placeholder='Masukkan deskripsi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={handleChange}
          withAsterisk
          autosize
          minRows={4}
        />
      </Box>
      <Box mb='md'>
        <Select
          name="isProduction"
          label="Parameter Utama"
          placeholder="Pilih salah satu "
          data={defaultProduction}
          onChange={(e) => setSelectedProduction(e)}
          error={validationForm.isProduction.isError ? `${validationForm.isProduction.message}` : ''}
          value={selectedProduction}
          withAsterisk
          checkIconPosition="right"
          disabled={isProductionState}
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitAspect(formData)}>{dataAspect === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormAspect