import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea, Select } from '@mantine/core'
import { addCompany, updateCompany } from '../../../services/companies'
import { getListCountry } from '../../../services/country'
import { getListIndustries } from '../../../services/industries'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  level: '',
  continent: '',
  industryId: '',
  countryId: '',
  address: '',
  isClient: false
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  level: {
    isError: false,
    message: ''
  },
  continent: {
    isError: false,
    message: ''
  },
  industryId: {
    isError: false,
    message: ''
  },
  countryId: {
    isError: false,
    message: ''
  },
  address: {
    isError: false,
    message: ''
  }
}

const defaultLevel = [
  {
    value: 'national',
    label: 'Nasional'
  },
  {
    value: 'international',
    label: 'Internasional'
  },
]

const defaultDataCompany = [
  {
    value: 'false',
    label: 'Perusahaan Pembanding'
  },
  {
    value: 'true',
    label: 'Perusahaan Client'
  },
]

const defaultContinent = [
  {
    value: 'asia',
    label: 'Asia'
  },
  {
    value: 'afrika',
    label: 'Afrika'
  },
  {
    value: 'eropa',
    label: 'Eropa'
  },
  {
    value: 'amerika_utara',
    label: 'Amerika Utara'
  },
  {
    value: 'amerika_selatan',
    label: 'Amerika Selatan'
  },
  {
    value: 'australia',
    label: 'Australia'
  },
  {
    value: 'oseania',
    label: 'Oseania'
  },
]

const FormCompanies = ({ dataCompany, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedContinent, setSelectedContinent] = useState(null)
  const [selectedIndustryId, setSelectedIndustryId] = useState(null)
  const [selectedCountryId, setSelectedCountryId] = useState(null)
  const [selectedDataClient, setSelectedDataClient] = useState('false')
  const [countryList, setCountryList] = useState([])
  const [categoryList, setCategoryList] = useState([])

  const handleGetListCountry = async (valContinent) => {
    const params = {
      'continent': valContinent
    }
    try {
      const response = await getListCountry(params)
      const dataCountry = response.data
      const mappingCountry = dataCountry.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setCountryList(mappingCountry)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetListIndustries = async () => {
    try {
      const response = await getListIndustries()
      const dataIndustries = response.data
      const mappingIndustries = dataIndustries.map((val) => {
        return {
          value: val.id.toString(),
          label: val.name
        }
      })
      setCategoryList(mappingIndustries)
    } catch (error) {
      console.log(error)
    }
  }

  const submitCompany = async (payloadCompany) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadCompany.name,
      level: selectedLevel,
      continent: selectedContinent,
      industryId: selectedIndustryId,
      countryId: selectedCountryId,
      address: payloadCompany.address,
      isClient: selectedDataClient === 'true' ? true : false
    }
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataCompany !== null) {
      methodFunction = updateCompany(payloadCompany.id, payload)
      titleMessageSuccess = 'Update Perusahaan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate data perusahaan'
      titleMessageError = 'Gagal Mengupdate Perusahaan'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addCompany(payload)
      titleMessageSuccess = 'Tambah Perusahaan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan perusahaan baru'
      titleMessageError = 'Gagal Menambahkan Perusahaan'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  useEffect(() => {
    handleGetListIndustries()
    if (dataCompany !== null) {
      handleSetForm(dataCompany)
    }
    // eslint-disable-next-line
  }, [dataCompany])

  useEffect(() => {
    if (selectedContinent !== null) {
      handleGetListCountry(selectedContinent)
    }
    // eslint-disable-next-line
  }, [selectedContinent])

  const handleSetForm = (dataCompany) => {
    const dataDetail = {
      id: dataCompany.id,
      name: dataCompany.name,
      level: dataCompany.level,
      continent: dataCompany.continent,
      industryId: dataCompany.industry.id,
      countryId: dataCompany.country.id,
      address: dataCompany.address,
      isClient: dataCompany.isClient === true ? 'true' : 'false'
    }
    setSelectedLevel(dataCompany.level)
    setSelectedIndustryId(dataCompany.industry.id)
    setSelectedCountryId(dataCompany.country.id)
    setSelectedContinent(dataCompany.continent)
    setSelectedDataClient(dataDetail.isClient)
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSelectChange = (val, name, handleSetSelected) => {
    handleSetSelected(val)
    setFormData((old) => ({ ...old, [name]: val }))
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Perusahaan'
          placeholder='Masukkan nama Perusahaan'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Select
          name="level"
          label="Level Perusahaan"
          placeholder="Pilih salah satu level"
          searchable
          nothingFoundMessage='Level tidak ditemukan'
          data={defaultLevel}
          onChange={(val) => handleSelectChange(val, 'level', setSelectedLevel)}
          error={validationForm.level.isError ? `${validationForm.level.message}` : ''}
          value={selectedLevel}
          withAsterisk
          checkIconPosition="right"
        />
      </Box>
      <Box mb='md'>
        <Select
          name="industryId"
          label="Kategori Perusahaan"
          placeholder="Pilih salah satu kategori"
          searchable
          nothingFoundMessage='Kategori tidak ditemukan'
          data={categoryList}
          onChange={(val) => handleSelectChange(val, 'industryId', setSelectedIndustryId)}
          error={validationForm.industryId.isError ? `${validationForm.industryId.message}` : ''}
          value={selectedIndustryId}
          withAsterisk
          checkIconPosition="right"
        />
      </Box>
      <Box mb='md'>
        <Select
          name="continent"
          label="Wilayah Benua Perusahaan"
          placeholder="Pilih salah satu benua"
          searchable
          nothingFoundMessage='Benua tidak ditemukan'
          data={defaultContinent}
          onChange={(val) => {
            handleSelectChange(val, 'continent', setSelectedContinent)
            setSelectedCountryId(null)
          }}
          error={validationForm.continent.isError ? `${validationForm.continent.message}` : ''}
          value={selectedContinent}
          withAsterisk
          checkIconPosition="right"
        />
      </Box>
      <Box mb='md'>
        <Select
          name="countryId"
          label="Negara"
          placeholder="Pilih salah satu negara"
          searchable
          nothingFoundMessage='Negara tidak ditemukan'
          data={countryList}
          onChange={(val) => handleSelectChange(val, 'countryId', setSelectedCountryId)}
          error={validationForm.countryId.isError ? `${validationForm.countryId.message}` : ''}
          value={selectedCountryId}
          withAsterisk
          checkIconPosition="right"
          disabled={selectedContinent === null}
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='address'
          value={formData.address}
          label='Alamat'
          placeholder='Masukkan alamat perusahaan'
          error={validationForm.address.isError ? `${validationForm.address.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Select
          name="isClient"
          label="Tipe Perusahaan"
          placeholder="Pilih salah satu tipe"
          data={defaultDataCompany}
          onChange={(val) => handleSelectChange(val.toString(), 'isClient', setSelectedDataClient)}
          value={selectedDataClient}
          withAsterisk
          checkIconPosition="right"
          allowDeselect={false}
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitCompany(formData)}>{dataCompany === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormCompanies