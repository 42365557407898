import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, FileInput, Select, Text, Modal, ScrollArea, Badge } from '@mantine/core'
import { YearPickerInput } from '@mantine/dates'
import { addCompanyReport, updateCompanyReport, uploadFileCompanyReport } from '../../../services/company-report'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { IconFileCv } from '@tabler/icons-react'
import { getListCompany } from '../../../services/companies' 
import dayjs from 'dayjs'
import FormCompanies from '../Companies/FormCompanies'

const defaultVal = {
  name: '',
  year: null,
  companyId: '',
  sourceType: 'url',
  source: '',
  file: null,
}
const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  year: {
    isError: false,
    message: ''
  },
  companyId: {
    isError: false,
    message: ''
  },
  sourceType: {
    isError: false,
    message: ''
  },
  source: {
    isError: false,
    message: ''
  },
  file: {
    isError: false,
    message: ''
  }
}

const defaultSource = [
  {
    value: 'file',
    label: 'File'
  },
  {
    value: 'url',
    label: 'Link URL'
  },
]

const FormCompanyReport = ({ dataReport, onCloseModal, reloadList, typeReport, isClientCompany }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [openModalCompany, setOpenModalCompany] = useState(false)
  const [selectedSource, setSelectedSource] = useState('url')
  const [sourceFileUrl, setSourceFileUrl] = useState(null)

  const handleGetCompanyList = async () => {
    const params = {
      isClient: isClientCompany ? 1 : 0
    }
    try {
      const response = await getListCompany(params)
      const dataCompany = response.data
      const mappingCompany = dataCompany.map((val) => {
        const remap = {
          value: val.id.toString(),
          label: val.name
        }
        return remap
      })
      setCompanyList(mappingCompany)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    handleGetCompanyList()
    if (dataReport !== null) {
      handleSetForm(dataReport)
    }
    // eslint-disable-next-line
  }, [dataReport])

  const handleSetForm = (dataReport) => {
    const dataYear = new Date()
    dataYear.setFullYear(dataReport.year)
    const dataDetail = {
      id: dataReport.id,
      companyId: dataReport.company.id,
      name: dataReport.name,
      year: dataYear,
      sourceType: dataReport.sourceType,
      // source: dataReport.source,
    }
    if (dataReport.sourceType === 'file') {
      setSourceFileUrl(dataReport.source)
      dataDetail.file = null
    } else {
      dataDetail.source = dataReport.source
    }
    setSelectedSource(dataReport.sourceType)
    setSelectedCompanyId(dataReport.company.id)
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  
  const handleChangeYear = (e) => {
    // eslint-disable-next-line
    setFormData({ ...formData, ['year']: e })
  }
  
  const handleUploadFile = (files) => {
    // eslint-disable-next-line
    setFormData({ ...formData, ['file']: files })
  }

  const submitReport = async (payloadReport) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadReport.name,
      companyId: selectedCompanyId,
      year: payloadReport.year,
      sourceType: selectedSource
    }
    if (selectedSource === 'url') {
      payload.source = payloadReport.source
    } else {
      payload.file = payloadReport.file
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    const body = {
      name: payload.name,
      year: Number(dayjs(payload.year).format('YYYY')),
      type: typeReport,
      sourceType: payload.sourceType,
      source: payload.source
    }
    if (dataReport === null) {
      body.companyId = selectedCompanyId
    } 
    if (dataReport !== null) {
      methodFunction = updateCompanyReport(payloadReport.id, body)
      titleMessageSuccess = 'Update Laporan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate laporan'
      titleMessageError = 'Gagal Mengupdate Laporan'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addCompanyReport(body)
      titleMessageSuccess = 'Tambah Laporan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan laporan baru'
      titleMessageError = 'Gagal Menambahkan Laporan'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (payload.sourceType === 'file') {
        if (payloadReport.file !== null) {
          const reportId = response.id !== undefined ? response.id : payloadReport.id
          const upload = await handleUploadFileReport(reportId)
          if (upload) {
            onCloseModal()
            reloadList()
            notificationSuccess(titleMessageSuccess, captionMessageSuccess)
            setLoadingForm(false)
          }
        }
      } else {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
    } catch (error) {
      setLoadingForm(false)
      console.log(error)
      const errorMessage = error
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleUploadFileReport = async (reportId) => {
    const form = new FormData()
    form.set('file', formData.file)
    try {
      const response = await uploadFileCompanyReport(reportId, form)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const EmptyStateCompany = () => {
    return (
      <Box ta='center' my={20}>
        <Text fz={13} mb={6}>"Perusahaan tidak ditemukan"</Text>
        <Text fz={10} c='blue' onClick={() => setOpenModalCompany(true)} td='underline' style={{ cursor: 'pointer' }}>Tambah Perusahaan</Text>
      </Box>
    )
  }

  const onCloseModalCompanyForm = () => {
    setOpenModalCompany(false)
  }

  const mappingSourceFileReport = (typeFile) => {
    if (typeFile === 'file') {
      return (
        <Box>
          <Box mb='xs'>
            <FileInput
              leftSection={<IconFileCv stroke={1.5} size={18} />}
              name='file'
              value={formData.file}
              accept='application/msword, application/vnd.ms-excel, application/pdf'
              label='File Laporan'
              placeholder='Upload file laporan'
              error={validationForm.file.isError ? `${validationForm.file.message}` : ''}
              onChange={handleUploadFile}
              withAsterisk
              disabled={sourceFileUrl !== null}
            />
          </Box>
          <Box>
            {sourceFileUrl !== null ? (<Badge size='sm' color='indigo.9' w={400}>{sourceFileUrl}</Badge>) : ''}
          </Box>
        </Box>
        
      )
    } else {
      return (
        <TextInput
          name='source'
          value={formData.source}
          label='Link URL File'
          placeholder='Masukkan link url file'
          error={validationForm.source.isError ? `${validationForm.source.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      )
    }
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Laporan'
          placeholder='Masukkan nama laporan'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <YearPickerInput
          name='year'
          value={formData.year}
          label='Tahun Laporan'
          placeholder='Masukkan tahun laporan'
          error={validationForm.year.isError ? `${validationForm.year.message}` : ''}
          onChange={handleChangeYear}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Select
          name="companyId"
          label="Nama Perusahaan"
          placeholder="Pilih salah satu perusahaan"
          data={companyList}
          nothingFoundMessage={<EmptyStateCompany />}
          onChange={(e) => setSelectedCompanyId(e)}
          error={validationForm.companyId.isError ? `${validationForm.companyId.message}` : ''}
          value={selectedCompanyId}
          withAsterisk
          searchable
          checkIconPosition="right"
          disabled={dataReport !== null}
        />
      </Box>
      <Box mb='md'>
        <Select
          name="sourceType"
          label="Tipe File Perusahaan"
          placeholder="Pilih salah satu tipe file"
          data={defaultSource}
          onChange={(e) => setSelectedSource(e)}
          error={validationForm.sourceType.isError ? `${validationForm.sourceType.message}` : ''}
          value={selectedSource}
          withAsterisk
          checkIconPosition="right"
          allowDeselect={false}
        />
      </Box>
      {mappingSourceFileReport(selectedSource)}
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitReport(formData)}>{dataReport === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
      <Modal opened={openModalCompany} onClose={onCloseModalCompanyForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>Tambah Perusahaan</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormCompanies dataCompany={null} onCloseModal={onCloseModalCompanyForm} reloadList={handleGetCompanyList} />
      </Modal>
    </Box>
  )
}

export default FormCompanyReport