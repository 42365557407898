import React, { useEffect, useState } from 'react'
import { Box, Group, Flex, Button, TextInput, Pagination, Modal, Text, ScrollArea, Center, Loader } from '@mantine/core'
import TableSkeleton from '../../ui/TableSkeleton'
import { getListAttachment, deleteAttachment, getDetailAttachment } from '../../../services/attachment'
import TableAttachment from '../Attachment/TableAttachment'
import FormAttachment from '../Attachment/FormAttachment'
import { modalDeleteData } from '../../ui/ModalManager/modalDeleteData.js'
import { getListKlhkReport } from '../../../services/klhk-report'

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Order',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const FormSecondaryAttachment = ({ detailData, onCloseModal, reloadList }) => {
  const defaultParameter = {
    skip: 0,
    take: 10,
    benchmarkReport: detailData !== null ? detailData.id : null,
    search: '',
    orderBy: 'order',
    order: 'asc'
  }

  const [loadingAttachment, setLoadingAttachment] = useState(true)
  const [loadingDetailAttachment, setLoadingDetailAttachment] = useState(false)
  const [attachmentList, setAttachmentList] = useState([])
  const [detailDataAttachment, setDetailDataAttachment] = useState(null)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [klhkReportList, setKlhkReportList] = useState([])
  const [count, setCount] = useState(0)
  const [params, setParams] = useState(defaultParameter)

  const handleGetAttachmentList = async () => {
    setLoadingAttachment(true)
    try {
      const response  = await getListAttachment(params)
      setAttachmentList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingAttachment(false)
    }
  }

  const handleGetDetailAttachment = async (attachmentId) => {
    setLoadingDetailAttachment(true)
    try {
      const response  = await getDetailAttachment(attachmentId)
      const responseKlhkList = await getListKlhkReport()
      setDetailDataAttachment(response)
      setKlhkReportList(responseKlhkList.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingDetailAttachment(false)
    }
  }

  useEffect(() => {
    handleGetAttachmentList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      benchmarkReport: params.benchmarkReport,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      benchmarkReport: params.benchmarkReport,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      handleGetDetailAttachment(val.id)
    },
    delete: (val) => {
      modalDeleteData('attachment', val.id, val.name, deleteAttachment, handleGetAttachmentList)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailDataAttachment(null)
  }

  const LoadingDetail = () => {
    return (
      <Center h={350}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }

  return (
    <Box>
      <Box my={20}>
        <Box>
          <Flex justify='flex-end' mb={14}>
            <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Attachment</Button>
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Attachment. . .'
              name="search"
              onChange={handleSearchChange}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={14}>
          {loadingAttachment ? <TableSkeleton total={3} /> : <TableAttachment label={labelTable} data={attachmentList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Box>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
          </Group>
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='75%' title={<Text fw='Bold'>Lampiran Attachment</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        {loadingDetailAttachment ? <LoadingDetail /> : <FormAttachment dataAttachment={detailDataAttachment} klhkList={klhkReportList} benchmarkReportId={detailData !== null ? detailData.id : null} onCloseModal={onCloseModalForm} reloadList={handleGetAttachmentList} />}
      </Modal>
    </Box>
  )
}

export default FormSecondaryAttachment