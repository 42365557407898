import React, { useState } from 'react'
import PETLogo from '../../../assets/img/PET_logo.png'
import { Box, Text, Center, Card, Button, Group, Progress, Popover, PasswordInput } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import classes from '../Login/Login.module.css'
import { validation } from '../../../plugins/validation'
import { resetPasswordVerify } from '../../../services/auth.js'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications/index.js'
import { useNavigate, useLocation } from 'react-router-dom'
import PasswordRequired from '../../../components/ui/PasswordRequired'

const defaultVal = {
  password: '',
  verifyPassword: ''
}

const formValidation = {
  password: {
    isError: false,
    message: ''
  },
  verifyPassword: {
    isError: false,
    message: ''
  }
}

const requirements = [
  { 
    re: /[0-9]/,
    label: 'Password harus berisikan nomor'
  },
  {
    re: /[a-z]/,
    label: 'Password harus berisikan huruf kecil'
  },
  {
    re: /[A-Z]/,
    label: 'Password harus berisikan huruf kapital'
  }
]

function getStrength(password) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1
    }
  })

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10)
}

const VerifyPassword = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const resetToken = queryParams.get("token")
  const { width, height } = useViewportSize()
  const [ formData, setFormData ] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [ loading, setLoading ] = useState(false)
  const [popoverPassword, setPopoverPassword] = useState(false)
  const checkPassword = requirements.map((val, index) => (
    <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />
  ))
  const strengthPassword = getStrength(formData.password)

  const progressBar = Array(4).fill(0).map((_, index) => {
    return (
      <Progress 
        styles={{ section: { transitionDuration: '0ms' }}}
        value={Object.values(formData.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
        color={strengthPassword > 80 ? 'teal' : strengthPassword > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    )
  })

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    setLoading(true)
    setValidationForm(formValidation)
    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoading(false)
      return 
    }
    if (formData.password !== formData.verifyPassword) {
      setValidationForm((old) => ({
        ...old,
        verifyPassword: {
          isError: true,
          message: 'Password harus sama dengan password baru anda'
        }
      }))
      setLoading(false)
      return 
    }
    const payload = {
      password: formData.verifyPassword
    }
    try {
      const response = await resetPasswordVerify(resetToken, payload)
      if (response) {
        notificationSuccess('Perubahan Password Berhasil', `${response.message}`)
        navigate("/login")
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Perubahan Password Gagal', `${errorMessage}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className={classes.loginPage} style={{ backgroundImage: `linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)`, minHeight: `${height}px`, minWidth: `${width}px`  }}>
      <Center maw={width / 1.2} h={height} mx='auto'>
        <Card className={classes.loginFormLayout} shadow='sm' radius='md' withBorder>
          <Box>
            <Box ta='center' mb={30}>
              <img src={PETLogo} width='160' alt='logo-katalis' />
              <Text fz='xl' fw='bold' mt={10}>PET Benchmark</Text>
              <Text fz='sm' c='gray.6'>Silahkan Masukkan Perubahan Password Anda</Text>
            </Box>
            <Box>
              <Box mb={30}>
                <Box mb="md">
                  <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                    <Popover.Target>
                      <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                        <PasswordInput
                          name="password"
                          value={formData.password}
                          placeholder="Masukkan password"
                          label="Password"
                          error={validationForm.password.isError ? `${validationForm.password.message}` : ''}
                          onChange={handleChange}
                          withAsterisk
                        />
                      </Box>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Group gap={5} grow mt="xs" mb="md">
                        {progressBar}
                      </Group>
                      <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
                      {checkPassword}
                    </Popover.Dropdown>
                  </Popover>
                </Box>
                <Box mb="md">
                  <PasswordInput
                    value={formData.verifyPassword}
                    name="verifyPassword"
                    placeholder="Ulangi password anda"
                    label="Konfirmasi Password"
                    error={validationForm.verifyPassword.isError ? validationForm.verifyPassword.message : ''}
                    onChange={handleChange}
                    withAsterisk
                  />
                </Box>
              </Box>
              <Box>
                <Group grow >
                  <Button radius='md' size='md' loading={loading} color='indigo.9' onClick={() => handleSubmit()}>Ubah Password</Button>
                </Group>
              </Box>
            </Box>
          </Box>
        </Card>
      </Center>  
    </Box>
  )
}

export default VerifyPassword