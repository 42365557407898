import React from 'react'
import { modals } from '@mantine/modals'
import { ActionIcon, Box, Text } from '@mantine/core'
import { IconAlertTriangleFilled } from '@tabler/icons-react'


export const modalWarningData = ( caption, description, functionMethod ) => {

  const handleConfirm = () => {
    functionMethod()
  }

  return modals.openConfirmModal({
    centered: 'true',
    closeOnConfirm: true,
    children: (
      <Box>
        <Box ta='center'>
          <ActionIcon variant='transparent' color='yellow' size={120}>
            <IconAlertTriangleFilled stroke={1.5} size={120} />
          </ActionIcon>
          <Text fw={500} mb={16}>{caption}</Text>
          <Text fz='xs' c='gray.6' mb={20}>{description}</Text>
        </Box>
      </Box>
    ),
    labels: { confirm: 'Lanjutkan', cancel: "Batal" },
    confirmProps: { color: 'indigo.9' },
    onConfirm: () => handleConfirm(),
  })
}