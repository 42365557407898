import { useEffect, useState } from "react"
import TableUnitConversion from "../UnitConversion/TableUnitConversion"
import { Box, Button, Flex, Group, Modal, ScrollArea, Text } from "@mantine/core"
import FormUnitConversion from "../UnitConversion/FormUnitConversion"
import { deleteUnitConversion, getListUnitConversion } from "../../../services/unit-conversion"
import { modalDeleteData } from "../../ui/ModalManager/modalDeleteData"
import TableSkeleton from "../../ui/TableSkeleton"
import { useSelector } from 'react-redux'

const labelTableConversion = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Satuan Unit',
    width: 'auto'
  },
  {
    label: 'Konversi Unit',
    width: 'auto'
  },
  {
    label: 'Nilai Unit',
    width: 'auto'
  },
  {
    label: 'Nilai Konversi Unit',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

export const TableConversion = ({ unit, onClose }) => {
  const permissionState = useSelector(state => state.permission.access)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [unitConversionList, setUnitConversionList] = useState([])
  const [loading, setLoading] = useState(true)

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('unit konversi', val.id, val.unitFrom.name, deleteUnitConversion, () => handleGetUnitConversionList(unit.id))
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const handleGetUnitConversionList = async (unitId) => {
    setLoading(true)
    const params = {
      unit: unitId
    }
    try {
      const response = await getListUnitConversion(params)
      const parsedData = response.data.map((val) => {
        if (val.unitFrom.id !== unitId) {
          return {
            ...val,
            unitFrom: val.unitTo,
            unitTo: val.unitFrom,
            value: val.valueReverse,
            isReversed: true,
          }
        }
        return val
      })
      setUnitConversionList(parsedData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  
  useEffect(() => {
    if (unit !== null) {
      handleGetUnitConversionList(unit.id)
    }
    // eslint-disable-next-line
  }, [unit])

  const handleCreate = () => {
    const permissionUnit = permissionState['unit']
    const createdPermission = permissionUnit.find(val => val.alias === 'unit.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Satuan Unit</Button>
      )
    }
  }

  return (
    <Box>
      <Box>
        <Flex justify='flex-end' mb={14}>
          {permissionState !== null ? handleCreate() : ''}
        </Flex>
      </Box>
      <Box my={20}>
        {loading ? <TableSkeleton total={4} /> : <TableUnitConversion label={labelTableConversion} data={unitConversionList} actionMethod={handleAction} countData={0} />}
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onClose}>Tutup</Button>
          </Group>
        </Flex>
      </Box>

      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='80%' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Unit Konversi</Text>} scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormUnitConversion defaultUnit={unit} dataUnitConversion={detailData} onCloseModal={onCloseModalForm} reloadList={() => handleGetUnitConversionList(unit.id)} />
      </Modal>
    </Box>
  )
}