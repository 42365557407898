import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex } from '@mantine/core'
import { addRole, updateRole } from '../../../services/roles'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  alias: ''
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  alias: {
    isError: false,
    message: ''
  }
}

const FormRole = ({ dataRole, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  useEffect(() => {
    if (dataRole !== null) {
      handleSetForm(dataRole)
    }
  }, [dataRole])

  const handleSetForm = (dataRole) => {
    const dataDetail = {
      id: dataRole.id,
      name: dataRole.name,
      alias: dataRole.alias
    }
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitRole = (payloadRole) => {
    if (dataRole === null) {
      handleAddRole(payloadRole)
    } else {
      handleUpdateRole(payloadRole)
    }
  }

  const handleAddRole = async (payloadRole) => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadRole.name,
      alias: payloadRole.alias
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await addRole(payload)
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess('Tambah Role Berhasil', 'Anda telah berhasil menambahkan role baru')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Gagal Menambahkan Role', `${Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  const handleUpdateRole = async (payloadRole) => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadRole.name,
      alias: payloadRole.alias
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    try {
      const response = await updateRole(payloadRole.id ,payload)
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess('Update Role Berhasil', 'Anda telah berhasil mengupdate role baru')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Gagal Update Role', `${Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }


  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Role'
          placeholder='Masukkan nama role'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='alias'
          value={formData.alias}
          label='Alias'
          placeholder='Masukkan nama alias'
          error={validationForm.alias.isError ? `${validationForm.alias.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitRole(formData)}>{dataRole === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormRole