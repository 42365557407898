import http from '../plugins/axios'

export const getListCompany = async (params) => {
  try {
    const response = await http.get(`/companies`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailCompany = async (companyId) => {
  try {
    const response = await http.get(`/companies/${companyId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const addCompany = async (payload) => {
  try {
    const response = await http.post(`/companies`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateCompany = async (companyId, payload) => {
  try {
    const response = await http.patch(`/companies/${companyId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteCompany = async (companyId) => {
  try {
    const response = await http.delete(`/companies/${companyId}`)
    return response.data
  } catch(error) {
    throw error
  }
}