const MENU_PERMISSION = {
  dashboard: 'dashboard',
  permission: 'permission',
  user: 'user',
  role: 'role',
  company: 'company',
  companyReport: 'company-report',
  klhkReport: 'klhk-report',
  industry: 'industry',
  unit: 'unit',
  parameter: 'parameter',
  benchmark: 'benchmark',
  benchmarker: 'benchmarker',
  aspect: 'aspect'
}

export { MENU_PERMISSION }