import React from 'react'
import { Table } from '@mantine/core'
import { decimalCheck, decimalFormat, thousandFormat } from '../../../plugins/addons'

const TableCompanyBenchmark = ({ label, data, parameter }) => {
  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingIsProductionParams = (val) => {
    if (!val.comparisonReportParameter.parameter.aspect.isProduction) {
      const valueIntensity = val.comparisonReportParameter.customIntensity !== null ? val.comparisonReportParameter.customIntensity : val.comparisonReportParameter.intensity
      return (<Table.Td>{valueIntensity ? decimalCheck(valueIntensity) ? thousandFormat(valueIntensity) : decimalFormat(valueIntensity) : 0} {val.comparisonReportParameter.parameter.unit.symbol} / {val.comparisonReportParameter.parameter.divider.unit.symbol}</Table.Td>)
    } else {
      return (<Table.Td>-</Table.Td>)
    }
  }

  // eslint-disable-next-line
  const mappingDataTable = data => data.map((val, index) => {
    if (val.klhkReportParameter === null) {
      return (
        <Table.Tr key={index} style={{ backgroundColor: val.isMain ? '#2d64ff': 'transparent', color: val.isMain ? '#ffff' : '#000' }}>
          <Table.Td>{index + 1}.</Table.Td>
          <Table.Td>{val.comparisonReportParameter.companyReport.company.name}</Table.Td>
          <Table.Td>{decimalCheck(val.comparisonReportParameter.finalValue) ? thousandFormat(val.comparisonReportParameter.finalValue) : decimalFormat(val.comparisonReportParameter.finalValue)} {val.comparisonReportParameter.parameter.unit.symbol}</Table.Td>
          {mappingIsProductionParams(val)}
          <Table.Td>{val.normalDistribution}</Table.Td>
        </Table.Tr>
      )
    }
  })
  
  return (
    <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
      <Table.Thead>
        <Table.Tr>
          {mappingTableHead(label)}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {mappingDataTable(data)}
      </Table.Tbody>
    </Table>
  )
}

export default TableCompanyBenchmark