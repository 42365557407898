import React from 'react'
import { Table, Menu, ActionIcon, Tooltip } from '@mantine/core'
import { IconDotsVertical, IconPencil, IconTrash, IconReportAnalytics, IconLock, IconLockOpen, IconClipboardTypography } from '@tabler/icons-react'
import { useSelector } from 'react-redux'

const ActionMenu = ({ handleAction, statusLocked }) => {
  const permissionState = useSelector(state => state.permission.access)
  const permissionUpdate = () => {
    const permissionReport = permissionState['klhk-report']
    const updatedPermission = permissionReport.find(val => val.alias === 'klhk-report.update')
    if (updatedPermission !== undefined && !statusLocked ) {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={12} />}
          onClick={() => handleAction('edit')}
          fw='600'
          fz={12}
        >
          Edit
        </Menu.Item>
      )
    }
  }

  const permissionAttachment = () => {
    const permissionReport = permissionState['klhk-report']
    const updatedPermission = permissionReport.find(val => val.alias === 'klhk-report.update')
    if (updatedPermission !== undefined && !statusLocked ) {
      return (
        <Menu.Item
          leftSection={<IconClipboardTypography stroke={1.5} size={12} />}
          onClick={() => handleAction('attachment')}
          fw='600'
          fz={12}
        >
          Lampiran
        </Menu.Item>
      )
    }
  }

  const permissionLockedReport = () => {
    const permissionReport = permissionState['klhk-report']
    const statusPermission = permissionReport.find(val => val.alias === 'klhk-report.lock')
    if (statusPermission !== undefined) {
      if (!statusLocked) {
        return (
          <Menu.Item
            leftSection={<IconLock stroke={1.5} size={12} />}
            onClick={() => handleAction('lock')}
            fw='600'
            fz={12}
          >
            Kunci Akses
          </Menu.Item>
        )
      } else {
        return (
          <Menu.Item
            leftSection={<IconLockOpen stroke={1.5} size={12} />}
            onClick={() => handleAction('unlock')}
            fw='600'
            fz={12}
          >
            Buka Akses
          </Menu.Item>
        )
      }
    }
  }

  const permissionDelete = () => {
    const permissionParameter = permissionState['klhk-report']
    const deletedPermission = permissionParameter.find(val => val.alias === 'klhk-report.delete')
    if (deletedPermission !== undefined && !statusLocked) {
      return (
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={12} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={12}
        >
          Hapus
        </Menu.Item>
      )
    }
  }

  return (
    <Menu width={170} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {permissionState !== null ? permissionUpdate() : ''}
        {permissionState !== null ? permissionAttachment() : ''}
        <Menu.Item
          leftSection={<IconReportAnalytics stroke={1.5} size={12} />}
          onClick={() => handleAction('report')}
          fw='600'
          fz={12}
        >
          Detail
        </Menu.Item>
        {permissionState !== null ? permissionLockedReport() : ''}
        {permissionState !== null ? permissionDelete() : ''}
      </Menu.Dropdown>
    </Menu>
  )
}

const TableKlhk = ({ label, data, countData, actionMethod }) => {
  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + countData + 1}.</Table.Td>
        <Table.Td>{val.issue}</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td>{val.year}</Table.Td>
        <Table.Td>{val.industry.name}</Table.Td>
        {mappingStatusReport(val.isLocked)}
        <Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} statusLocked={val.isLocked} /></Table.Td>
      </Table.Tr>
    )
  })

  const mappingStatusReport = (status) => {
    if (!status) {
      return (
        <Table.Td>
          <Tooltip label='Anda diijinkan merubah atau menghapus laporan ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs' color='green'>
              <IconLockOpen stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        </Table.Td>
      )
    } else {
      return (
        <Table.Td>
          <Tooltip label='Anda tidak diijinkan merubah atau menghapus laporan ini'>
            <ActionIcon ml={10} mt={6} variant='transparent' size='xs' color='red'>
              <IconLock stroke={1.5}/>
            </ActionIcon>
          </Tooltip>
        </Table.Td>
      )
    }
  }

  return (
    <Table.ScrollContainer minWidth={768} type='native'>
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '12.5px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableKlhk