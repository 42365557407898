import React, { useState } from 'react'
import PETLogo from '../../../assets/img/PET_logo.png'
import { Box, Text, Center, Card, TextInput, Button, Group } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import classes from '../Login/Login.module.css'
import { validation } from '../../../plugins/validation'
import { resetPasswordRequest } from '../../../services/auth.js'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications/index.js'

const defaultVal = {
  email: '',
  password: ''
}

const formValidation = {
  email: {
    isError: false,
    message: ''
  },
}

const ForgotPassword = () => {
  const { width, height } = useViewportSize()
  const [ formData, setFormData ] = useState(defaultVal)
  const [ validationForm, setValidationForm ] = useState(formValidation)
  const [ loading, setLoading ] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    setLoading(true)
    setValidationForm(formValidation)
    const isErrorForm = validation(formData.email, setValidationForm)
    if (isErrorForm) {
      setLoading(false)
      return
    }
    const payload = {
      email: formData.email,
      resetUrl: 'proper.tandeem.id/password-resets/verify'
    }
    try {
      const response = await resetPasswordRequest(payload)
      if (response) {
        notificationSuccess('Pengiriman Verifikasi Berhasil', `${response.message}`)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Pengiriman Verifikasi Gagal', 'Silahkan periksa kembali form anda')
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className={classes.loginPage} style={{ backgroundImage: `linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12)`, minHeight: `${height}px`, minWidth: `${width}px`  }}>
      <Center maw={width / 1.2} h={height} mx='auto'>
        <Card className={classes.loginFormLayout} shadow='sm' radius='md' withBorder>
          <Box>
            <Box ta='center' mb={30}>
              <img src={PETLogo} width='160' alt='logo-katalis' />
              <Text fz='xl' fw='bold' mt={10}>PET Benchmark</Text>
              <Text fz='sm' c='gray.6'>Permintaan Lupa Password ke PET Benchmark</Text>
            </Box>
            <Box>
              <Box mb={30}>
                <Box mb='md'>
                  <TextInput
                    name='email'
                    label='Email'
                    placeholder='Masukkan email'
                    error={validationForm.email.isError ? `${validationForm.email.message}` : ''}
                    onChange={handleChange}
                    withAsterisk
                  />
                </Box>
              </Box>
              <Box>
                <Group grow >
                  <Button radius='md' size='md' loading={loading} color='indigo.9' onClick={() => handleSubmit()}>Kirim Verifikasi</Button>
                </Group>
              </Box>
            </Box>
          </Box>
        </Card>
      </Center>  
    </Box>
  )
}

export default ForgotPassword