import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea } from '@mantine/core'
import { addUnit, updateUnit } from '../../../services/unit-conversion'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'

const defaultVal = {
  name: '',
  symbol: '',
  description: ''
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  symbol: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  }
}

const FormUnit = ({ dataUnit, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  useEffect(() => {
    if (dataUnit !== null) {
      handleSetForm(dataUnit)
    }
  }, [dataUnit])

  const handleSetForm = (val) => {
    const dataDetail = {
      id: val.id,
      name: val.name,
      symbol: val.symbol,
      description: val.description
    }
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitUnit = async (payloadUnit) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadUnit.name,
      symbol: payloadUnit.symbol,
      description: payloadUnit.description
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (dataUnit !== null) {
      methodFunction = updateUnit(payloadUnit.id, payload)
      titleMessageSuccess = 'Update Unit Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate unit'
      titleMessageError = 'Gagal Mengupdate Unit'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addUnit(payload)
      titleMessageSuccess = 'Tambah Unit Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan unit baru'
      titleMessageError = 'Gagal Menambahkan Unit'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }


  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Satuan Unit'
          placeholder='Masukkan nama satuan unit'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='symbol'
          value={formData.symbol}
          label='Simbol Satuan Unit'
          placeholder='Masukkan simbol satuan unit'
          error={validationForm.symbol.isError ? `${validationForm.symbol.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Textarea
          name='description'
          value={formData.description}
          label='Deskripsi'
          placeholder='Masukkan deskripsi'
          error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitUnit(formData)}>{dataUnit === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormUnit