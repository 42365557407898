import { createSlice } from "@reduxjs/toolkit"

const benchmarkData = createSlice({
  name: 'benchmark',
  initialState: {
    layoutStep: 0,
    dataBenchmark: null
  },
  reducers: {
    updatedDataBenchmark: (state, action) => {
      state.layoutStep = action.payload.layoutStep
      state.dataBenchmark = action.payload.dataBenchmark
    }
  }
})

export const { updatedDataBenchmark } = benchmarkData.actions
export default benchmarkData.reducer