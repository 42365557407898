import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, Textarea, Select, Tooltip } from '@mantine/core'
import { addParameter, updateParameter } from '../../../services/parameter'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { getListAspect } from '../../../services/aspect'
import { getListUnit } from '../../../services/unit-conversion'
import { getListIndustries } from '../../../services/industries'
import { getListParameter } from '../../../services/parameter'

const defaultVal = {
  name: '',
  description: '',
  unitId: null,
  aspectId: null,
  industryId: null,
  dividerId: null,
  multiplier: null
}

const formValidation = {
  name: {
    isError: false,
    message: ''
  },
  description: {
    isError: false,
    message: ''
  },
  unitId: {
    isError: false,
    message: ''
  },
  aspectId: {
    isError: false,
    message: ''
  },
  industryId: {
    isError: false,
    message: ''
  },
  dividerId: {
    isError: false,
    message: ''
  }
}

const FormParameters = ({ dataParameter, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [industriesList, setIndustriesList] = useState([])
  const [aspectList, setAspectList] = useState([])
  const [parameterList, setParameterList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [selectedAspect, setSelectedAspect] = useState(null)
  const [selectedIndustries, setSelectedIndustries] = useState(null)
  const [selectedParameter, setSelectedParameter] = useState(null)
  const [productionParameterId, setProductionParameterId] = useState(null)

  const handleGetUnit = async () => {
    try {
      const response = await getListUnit()
      const dataUnit = response.data
      const remapUnit = dataUnit.map((val) => {
        const map = {
          value: val.id,
          label: `${val.name} (${val.symbol})`
        }
        return map
      })
      setUnitList(remapUnit)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetAspect = async () => {
    try {
      const response = await getListAspect()
      const dataAspect = response.data
      const productionParameter = dataAspect.find(val => val.isProduction)
      const remapAspect = dataAspect.map((val) => {
        const map = {
          value: val.id,
          label: `${val.name} ${val.isProduction ? '*': ''}`
        }
        return map
      })
      setAspectList(remapAspect)
      setProductionParameterId(productionParameter.id)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetIndustries = async () => {
    try {
      const response = await getListIndustries()
      const dataIndustries = response.data
      const remapIndustries = dataIndustries.map((val) => {
        const map = {
          value: val.id,
          label: val.name
        }
        return map
      })
      setIndustriesList(remapIndustries)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetParameter = async () => {
    const params = {
      industry: selectedIndustries,
      // isProduction: 1
    }
    try {
      const response = await getListParameter(params)
      const dataParameter = response.data
      const remapParameter = dataParameter.map((val) => {
        const map = {
          value: val.id,
          label: `${val.name} ${val.aspect.isProduction ? '*' : ''}`
        }
        return map
      })
      setParameterList(remapParameter)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetUnit()
    handleGetAspect()
    handleGetIndustries()
    if (dataParameter !== null) {
      handleSetForm(dataParameter)
    }
    // eslint-disable-next-line
  }, [dataParameter])

  useEffect(() => {
    if (selectedIndustries !== null) {
      handleGetParameter()
    }
    // eslint-disable-next-line
  }, [selectedIndustries])

  const handleSetForm = (dataParameter) => {
    const dataDetail = {
      id: dataParameter.id,
      name: dataParameter.name,
      description: dataParameter.description,
      unitId: dataParameter.unit !== null ? dataParameter.unit.id : null,
      industryId: dataParameter.industry !== null ? dataParameter.industry.id : null,
      aspectId: dataParameter.aspect !== null ? dataParameter.aspect.id : null,
      dividerId: dataParameter.divider !== null ? dataParameter.divider.id : null,
      multiplier: dataParameter.multiplier !== null ? dataParameter.multiplier : null
    }
    setSelectedUnit(dataDetail.unitId)
    setSelectedIndustries(dataDetail.industryId)
    setSelectedAspect(dataDetail.aspectId)
    setSelectedParameter(dataDetail.dividerId)
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const submitParameter = async (payloadParameter) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadParameter.name,
      description: payloadParameter.description,
      unitId: selectedUnit,
      aspectId: selectedAspect,
      industryId: selectedIndustries
    }
    if (selectedAspect !== null) {
      if (selectedAspect !== productionParameterId) {
        payload.dividerId = selectedParameter
      }
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (selectedAspect !== null) {
      if (selectedAspect !== productionParameterId) {
        payload.multiplier = payloadParameter.multiplier === '' || payloadParameter.multiplier === null ? null : payloadParameter.multiplier
      }
    }
    if (dataParameter !== null) {
      methodFunction = updateParameter(payloadParameter.id, payload)
      titleMessageSuccess = 'Update Parameter Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate parameter'
      titleMessageError = 'Gagal Mengupdate Parameter'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addParameter(payload)
      titleMessageSuccess = 'Tambah Parameter Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan parameter baru'
      titleMessageError = 'Gagal Menambahkan Parameter'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  const setDividerParameter = () => {
    if (selectedAspect !== null) {
      if (selectedAspect !== productionParameterId) {
        return (
          <Box>
            <Box mb='md'>
              <Select
                name="dividerId"
                label="Pembagi Intensitas"
                placeholder="Pilih salah satu "
                data={parameterList}
                onChange={(e) => setSelectedParameter(e)}
                error={validationForm.dividerId.isError ? `${validationForm.dividerId.message}` : ''}
                value={selectedParameter}
                withAsterisk
                checkIconPosition="right"
                searchable
                description="produksi utama memiliki logo (*)"
              />
            </Box>
            <Box mb='md'>
              <TextInput
                name='multiplier'
                value={formData.multiplier}
                label='Jumlah Pengkali Parameter'
                placeholder='Masukkan jumlah pengkali parameter (opsional)'
                onChange={handleChange}
                type='number'
              />
            </Box>
          </Box>
        )
      }
    }
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Parameter'
          placeholder='Masukkan nama parameter'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Tooltip label='Deskripsi ini digunakan untuk informasi parameter di laporan benchmark' position='bottom-end' offset={5} withArrow>
          <Textarea
            name='description'
            value={formData.description}
            label='Deskripsi'
            placeholder='Masukkan deskripsi'
            error={validationForm.description.isError ? `${validationForm.description.message}` : ''}
            onChange={handleChange}
            withAsterisk
          />
        </Tooltip>
      </Box>
      <Box mb='md'>
        <Select
          name="industryId"
          label="Sektor Perusahaan"
          placeholder="Pilih salah satu "
          data={industriesList}
          onChange={(e) => setSelectedIndustries(e)}
          error={validationForm.industryId.isError ? `${validationForm.industryId.message}` : ''}
          value={selectedIndustries}
          withAsterisk
          searchable
          checkIconPosition="right"
        />
      </Box>
      <Box mb='md'>
        <Select
          name="aspectId"
          label="Aspek Parameter"
          placeholder="Pilih salah satu "
          data={aspectList}
          onChange={(e) => setSelectedAspect(e)}
          error={validationForm.aspectId.isError ? `${validationForm.aspectId.message}` : ''}
          value={selectedAspect}
          withAsterisk
          checkIconPosition="right"
          searchable
          description="produksi utama memiliki logo (*)"
        />
      </Box>
      <Box mb='md'>
        <Select
          name="unitId"
          label="Satuan Unit"
          placeholder="Pilih salah satu "
          data={unitList}
          onChange={(e) => setSelectedUnit(e)}
          error={validationForm.unitId.isError ? `${validationForm.unitId.message}` : ''}
          value={selectedUnit}
          withAsterisk
          searchable
          checkIconPosition="right"
        />
      </Box>
      {setDividerParameter()}
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitParameter(formData)}>{dataParameter === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormParameters