import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select, Group, Badge, LoadingOverlay, Modal } from '@mantine/core'
import classes from '../Auth.module.css'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TableBenchmarkResult from '../../../components/pages/BenchmarkResult/TableBenchmarkResult'
import { getListBenchmarkReport, deleteBenchmarkReport, getDetailBenchmarkReport, updateBenchmarkReport } from '../../../services/benchmark-report'
import { getListBenchmarkParameter } from '../../../services/benchmark'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { useDispatch } from 'react-redux'
import { updatedDataBenchmark } from '../../../store/benchmarkData'
import { useNavigate } from 'react-router-dom'
import FormDocumentBenchmark from '../../../components/pages/BenchmarkResult/FormDocumentBenchmark'
import { modalRevisionData } from '../../../components/ui/ModalManager/modalRevisionData'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc',
  status: 'draft'
}

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Benchmark',
    width: 'auto'
  },
  {
    label: 'Nama Perusahaan',
    width: 'auto'
  },
  {
    label: 'Laporan Perusahaan',
    width: 'auto'
  },
  {
    label: 'Sektor Perusahaan',
    width: 'auto'
  },
  {
    label: 'Pembuat',
    width: 'auto'
  },
  {
    label: 'Dibuat',
    width: 'auto'
  },
  {
    label: 'Diupdate',
    width: 'auto'
  },
  {
    label: 'Jumlah Revisi',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const defaultOrder = [
  {
    value: 'asc',
    label: 'Terlama - Terbaru'
  },
  {
    value: 'desc',
    label: 'Terbaru - Terlama'
  },
]

const BenchmarkResult = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params, setParams] = useState(defaultParameter)
  const [benchmarkList, setBenchmarkList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [selectedOrder, setSelectedOrder] = useState('desc')
  const [selectedFilter, setSelectedFilter] = useState('draft')
  const [loadingDraft, setLoadingDraft] = useState(false)
  const [modalUpload, setModalUpload] = useState(false)
  const [detailData, setDetailData] = useState(null)

  const handleGetBenchmarkReportList = async () => {
    setLoading(true)
    try {
      const response = await getListBenchmarkReport(params)
      setBenchmarkList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  const handleGetDetailDataBenchmark = async (benchmarkId) => {
    setLoadingDraft(true)
    const params = {
      benchmarkReport: benchmarkId
    }
    try {
      const responseDetailbenchmark = await getDetailBenchmarkReport(benchmarkId)
      const responseDataBenchmarkParams = await getListBenchmarkParameter(params)
      const dataBenchmarkParams = responseDataBenchmarkParams.data

      const mappingPayload = (data) => {
        const dataCompare = []
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const dataBenchmarkComparison = data[i].benchmarkParameterComparisons
            if (dataBenchmarkComparison.length > 0) {
              const remapPayload = {
                benchmarkParameterId: data[i].id,
                parameterId: data[i].companyReportParameter.parameter.id,
                companyReportParameterIds: [],
                klhkReportParameterId: null,
                useDataKlhk: false,
                klhkReportCustomChart: data[i].customChart
              }
              for (let j = 0; j < dataBenchmarkComparison.length; j++) {
                if (dataBenchmarkComparison[j].isMain === false) {
                  if (dataBenchmarkComparison[j].klhkReportParameter === null) {
                    const remap = {
                      ...dataBenchmarkComparison[j].comparisonReportParameter,
                      intensity: dataBenchmarkComparison[j].comparisonReportParameter.customIntensity !== null ? dataBenchmarkComparison[j].comparisonReportParameter.customIntensity : dataBenchmarkComparison[j].comparisonReportParameter.intensity
                    }
                    remapPayload.companyReportParameterIds.push(remap)
                    remapPayload.useDataKlhk = false
                  } else {
                    remapPayload.klhkReportParameterId = dataBenchmarkComparison[j].klhkReportParameter
                    remapPayload.useDataKlhk = true
                  }
                }
              }
              dataCompare.push(remapPayload)
            }
          }
        }
        return dataCompare
      }

      

      const payload = {
        name: responseDetailbenchmark.name,
        description: responseDetailbenchmark.description,
        companyId: responseDetailbenchmark.companyReport.company.id,
        companyReportId: responseDetailbenchmark.companyReport.id,
        benchmarkReportId: responseDetailbenchmark.id,
        benchmarkerId: responseDetailbenchmark.benchmarker.id,
        selectedParameter: null,
        changeParaphrase: responseDetailbenchmark.status === 'revision' ? true : false,
        compareBenchmarkLevelAsia: null,
        compareBenchmarkLevelNational: null,
        compareBenchmarkLevelInternational: null
      }

      const dataComparisonAsia = dataBenchmarkParams.filter(val => val.level === 'asia')
      const dataComparisonNational = dataBenchmarkParams.filter(val => val.level === 'national')
      const dataComparisonInternational = dataBenchmarkParams.filter(val => val.level === 'international')
      
      const mappingDataAsia = mappingPayload(dataComparisonAsia)
      const mappingDataNational = mappingPayload(dataComparisonNational)
      const mappingDataInternational = mappingPayload(dataComparisonInternational)
      
      payload.compareBenchmarkLevelAsia = mappingDataAsia.length > 0 ? JSON.stringify(mappingDataAsia) : null
      payload.compareBenchmarkLevelNational = mappingDataNational.length > 0 ? JSON.stringify(mappingDataNational) : null
      payload.compareBenchmarkLevelInternational = mappingDataInternational.length > 0 ? JSON.stringify(mappingDataInternational) : null

      let activeStep = 0
      if (payload.compareBenchmarkLevelNational !== null) {
        activeStep = 1
        payload.selectedParameter = mappingDataNational[0].parameterId
      } 
      if (payload.compareBenchmarkLevelAsia !== null) {
        activeStep = 2
        payload.selectedParameter = mappingDataAsia[0].parameterId
      } 
      if (payload.compareBenchmarkLevelInternational !== null) {
        activeStep = 3
        payload.selectedParameter = mappingDataInternational[0].parameterId
      }

      dispatch(updatedDataBenchmark({ 'layoutStep': activeStep, 'dataBenchmark': payload }))
      navigate('/benchmark')
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingDraft(false)
    }
  }

  useEffect(() => {
    handleGetBenchmarkReportList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order,
      status: params.status
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order,
      status: params.status
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: orders,
      status: params.status
    }
    setParams(parameter)
  }

  const handleStatus = (value) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order,
      status: value
    }
    setParams(parameter)
    setSelectedFilter(value)
  }

  const actions = {
    resume: (val) => {
      handleGetDetailDataBenchmark(val.id)
    },
    detail: (val) => {
      navigate(`/benchmark-results/${val.id}`)
    },
    delete: (val) => {
      modalDeleteData('Benchmark', val.id, val.name, deleteBenchmarkReport, handleGetBenchmarkReportList)
    },
    upload: (val) => {
      setModalUpload(true)
      setDetailData(val)
    },
    revert: (val) => {
      modalRevisionData(val.id, val.name, updateBenchmarkReport, handleGetBenchmarkReportList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalUpload = () => {
    setModalUpload(false)
    setDetailData(null)
  }

  return (
    <AuthLayout>
      <Box pos='relative'>
        <LoadingOverlay visible={loadingDraft} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Text className={classes.titlePage} mb={20}>List Benchmark Management</Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari data benchmark. . .'
              name="search"
              onChange={handleSearchChange}
            />
            <Select
              name="order"
              data={defaultOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Flex>
        </Box>
        <Group my={20}>
          <Badge variant={selectedFilter === 'draft' ? '' : 'outline'} color="indigo.9" size='lg' onClick={() => handleStatus('draft')} style={{ cursor: 'pointer' }}>Draft</Badge>
          <Badge variant={selectedFilter === 'final' ? '' : 'outline'} color="indigo.9" size='lg' onClick={() => handleStatus('final')} style={{ cursor: 'pointer' }} >Selesai</Badge>
          <Badge variant={selectedFilter === 'revision' ? '' : 'outline'} color="indigo.9" size='lg' onClick={() => handleStatus('revision')} style={{ cursor: 'pointer' }} >Revisi</Badge>
        </Group>
        <Box my={20}>
          {loading ? <TableSkeleton total={7} /> : <TableBenchmarkResult label={labelTable} data={benchmarkList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={modalUpload} onClose={onCloseModalUpload} closeOnClickOutside={false} centered size='xl' title={<Text fw='Bold'>Upload Laporan</Text>} withCloseButton={false}>
        <FormDocumentBenchmark dataResult={detailData} onCloseModal={onCloseModalUpload} reloadList={handleGetBenchmarkReportList} />
      </Modal>
    </AuthLayout>
  )
}

export default BenchmarkResult