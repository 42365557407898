import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, Button, TextInput, Select, Group, Badge, Center, Loader } from '@mantine/core'
import classes from '../Auth.module.css'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { getListCompany, getDetailCompany, deleteCompany } from '../../../services/companies'
import FormCompanies from '../../../components/pages/Companies/FormCompanies'
import TableCompanies from '../../../components/pages/Companies/TableCompanies'
import FormEditorCompanies from '../../../components/pages/Companies/FormEditorCompanies'
// import { useNavigate } from 'react-router-dom'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc',
  isClient: 0
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Perusahaan',
    width: 'auto'
  },
  {
    label: 'Level Perusahaan',
    width: 'auto'
  },
  {
    label: 'Wilayah Benua Perusahaan',
    width: 'auto'
  },
  {
    label: 'Kategori Perusahaan',
    width: 'auto'
  },
  {
    label: 'Negara',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const Companies = () => {
  // const navigate = useNavigate()
  const [params, setParams] = useState(defaultParameter)
  const [companyList, setCompanyList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingForm, setLoadingForm] = useState(false)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [openModalEditor, setOpenModalEditor] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState('createdAt')
  const [selectedOrder, setSelectedOrder] = useState('desc')
  const [selectedClient, setSelectedClient] = useState(0)
  

  const mappingOrder = [
    {
      value: 'asc',
      label: selectedFilter === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: selectedFilter === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetCompanyList = async () => {
    setLoading(true)
    try {
      const response = await getListCompany(params)
      setCompanyList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetDetailCompany = async (companyId) => {
    setLoadingForm(true)
    try {
      const response = await getDetailCompany(companyId)
      setDetailData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingForm(false)
    }
  }

  

  useEffect(() => {
    handleGetCompanyList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order,
      isClient: params.isClient
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order,
      isClient: params.isClient
    }
    setParams(parameter)
  }

  const handleSelectFilter = (filterOrder) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: filterOrder,
      order: params.order,
      isClient: params.isClient
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: orders,
      isClient: params.isClient
    }
    setParams(parameter)
  }

  const handleIsClient = (value) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.orders,
      isClient: value
    }
    setParams(parameter)
    setSelectedClient(value)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    // report: val => {
    //   navigate(`/company/report/${val.id}`)
    // },
    delete: (val) => {
      modalDeleteData('perusahaan', val.id, val.name, deleteCompany, handleGetCompanyList)
    },
    client: (val) => {
      setOpenModalEditor(true)
      handleGetDetailCompany(val.id)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalEditor = () => {
    setOpenModalEditor(false)
    setDetailData(null)
  }

  const LoadingDetail = () => {
    return (
      <Center h={350}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }
  
  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Manajemen Perusahaan</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            <Button color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Perusahaan</Button>
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Perusahaan. . .'
              name="search"
              onChange={handleSearchChange}
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [setSelectedFilter(e), handleSelectFilter(e)]}
              value={selectedFilter}
              checkIconPosition="right"
              allowDeselect={false}
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Flex>
        </Box>
        <Group my={20}>
          <Badge variant={selectedClient === 0 ? '' : 'outline'} color="indigo.9" size='lg' onClick={() => handleIsClient(0)} style={{ cursor: 'pointer' }}>Perusahaan Pembanding</Badge>
          <Badge variant={selectedClient === 1 ? '' : 'outline'} color="indigo.9" size='lg' onClick={() => handleIsClient(1)} style={{ cursor: 'pointer' }} >Perusahaan Client</Badge>
        </Group>
        <Box my={20}>
          {loading ? <TableSkeleton total={7} /> : <TableCompanies label={labelTable} data={companyList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='xl' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Perusahaan</Text>} withCloseButton={false}>
        <FormCompanies dataCompany={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetCompanyList} />
      </Modal>
      <Modal opened={openModalEditor} onClose={onCloseModalEditor} closeOnClickOutside={false} centered size='75%' title={<Text fw='Bold'>Update Informasi Perusahaan</Text>} withCloseButton={false}>
        {loadingForm ? <LoadingDetail/> : <FormEditorCompanies detailCompany={detailData} onCloseModal={onCloseModalEditor} reloadList={handleGetCompanyList} /> }
      </Modal>
    </AuthLayout>
    
  )
}

export default Companies