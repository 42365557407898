import React, { useState } from 'react'
import { Box, Text, SimpleGrid, Paper, Group, Grid, Select, Table, Button } from '@mantine/core'
import { IconUser, IconClipboardText, IconChecklist } from '@tabler/icons-react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { useNavigate } from 'react-router-dom'


const defaultStateChart = [
  {
    value: 'monthly',
    label: 'Bulan'
  },
  {
    value: 'daily',
    label: 'Hari'
  },
]


const StatisticData = ({ data }) => {
  const [stateChart, setStateChart] = useState('monthly')
  const navigate = useNavigate()

  const mappingDataUser = (totalUser) => {
    if (totalUser) {
      return (
        <Paper withBorder p='md' radius='md'>
          <Group justify='space-between'>
            <Text size="sm" fw={600}>
              Total User
            </Text>
            <IconUser size='2rem' stroke='1.5' />
          </Group>
          <Text mt={25} fz='lg'>{totalUser}</Text>
          <Text fz="xs" c="dimmed" mt={2}>
            User Terdaftar
          </Text>
        </Paper>
      )
    }
  }

  const mappingDraftBenchmark = (totalDraft) => {
    if (totalDraft) {
      return (
        <Paper withBorder p='md' radius='md'>
          <Group justify='space-between'>
            <Text size="sm" fw={600}>
              Draft
            </Text>
            <IconClipboardText size='2rem' stroke='1.5' />
          </Group>
          <Text mt={25} fz='lg'>{totalDraft}</Text>
          <Text fz="xs" c="dimmed" mt={2}>
            Draft Benchmark
          </Text>
        </Paper>
      )
    }
  }
  const mappingFinishBenchmark = (totalFinish) => {
    if (totalFinish) {
      return (
        <Paper withBorder p='md' radius='md'>
          <Group justify='space-between'>
            <Text size="sm" fw={600}>
              Selesai
            </Text>
            <IconChecklist size='2rem' stroke='1.5' />
          </Group>
          <Text mt={25} fz='lg'>{totalFinish}</Text>
          <Text fz="xs" c="dimmed" mt={2}>
            Selesai Benchmark
          </Text>
        </Paper>
      )
    }
  }

  const mappingChartBenchmark = (data) => {
    return (
      <Paper withBorder p='md' radius='md'>
        <Group justify='space-between'>
          <Text size="sm" fw={600}>
            Grafik Benchmark
          </Text>
          <Select
            data={defaultStateChart}
            value={stateChart}
            allowDeselect={false}
            onChange={setStateChart}
            size='xs'
          />
        </Group>
        <Box my={20}>
          {stateChart === 'monthly' ? monthlyBenchmark(data.benchmarkMonthlyProgresses) : dailyBenchmark(data.benchmarkDailyProgresses)}
        </Box>
      </Paper>
    )
  }

  const monthlyBenchmark = (dataMonthly) => {
    if (dataMonthly.length > 0) {
      return (
        <ResponsiveContainer height={300}>
            <LineChart
              height={300}
              data={dataMonthly}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis dataKey="count" />
              <Tooltip />
              <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
      )
    }
  }

  const dailyBenchmark = (dataDaily) => {
    if (dataDaily.length > 0) {      
      return (
        <ResponsiveContainer height={300}>
            <LineChart
              height={300}
              data={dataDaily}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis dataKey="count" />
              <Tooltip />
              <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} legendType="none" />
            </LineChart>
        </ResponsiveContainer>
      )
    }
  }

  const mappingLatestBenchmark = (dataLatest) => {
    const mappingDataTable = (data) => data.map((val, index) => {
      return (
        <Table.Tr key={index}>
          <Table.Td>{index + 1}.</Table.Td>
          <Table.Td>{val.name}</Table.Td>
        </Table.Tr>
      )
    })
    return (
      <Paper withBorder p='md' radius='md' h={405}>
        <Group justify='space-between'>
          <Text size="sm" fw={600}>
            Benchmark Terakhir
          </Text>
          <Button size='xs' variant="white" onClick={() => navigate('/benchmark-results')}>Selengkapnya</Button>
        </Group>
        
        <Box my={20}>
        <Table.ScrollContainer minWidth={380}>
          <Table horizontalSpacing='xs' withTableBorder style={{ fontSize: '12px' }}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th width={10}>No.</Table.Th>
                  <Table.Th width='auto'>Nama Benchmark</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {mappingDataTable(dataLatest)}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Box>
      </Paper>
    )
  }

  const mappingAspectComparison = (dataAspect) => {
    const mappingDataTable = (data) => data.map((val, index) => {
      return (
        <Table.Tr key={index}>
          <Table.Td>{index + 1}.</Table.Td>
          <Table.Td>{val.name}</Table.Td>
          <Table.Td>{val.companyReport}</Table.Td>
          <Table.Td>{val.klhkReport}</Table.Td>
        </Table.Tr>
      )
    })
    return (
      <Paper withBorder p='md' radius='md'>
        <Group justify='space-between'>
          <Text size="sm" fw={600}>
            Statistik Aspek Perusahaan 
          </Text>
        </Group>
        
        <Box my={20}>
        <Table.ScrollContainer>
          <Table horizontalSpacing='xs' withTableBorder style={{ fontSize: '12px' }}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th width={10}>No.</Table.Th>
                  <Table.Th width='auto'>Aspek Perusahaan</Table.Th>
                  <Table.Th width='auto'>Laporan Perusahaan</Table.Th>
                  <Table.Th width='auto'>Data Perdirjen</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {mappingDataTable(dataAspect)}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Box>
      </Paper>
    )
  }
  return (
    <Box>
      <Text fw={600} mb={16}>Statistik Benchmark</Text>
      <SimpleGrid cols={{ base: 1, md: 4, lg: 4}} mb={20}>
        {mappingDataUser(data.userCount)}
        {mappingDraftBenchmark(data.myBenchmark.draft)}
        {mappingFinishBenchmark(data.myBenchmark.final)}
      </SimpleGrid>
      <Grid mb={20}>
        <Grid.Col span={{ base: 12, md: 12, lg: 8 }}>
          {mappingChartBenchmark(data)}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
          {mappingLatestBenchmark(data.lattestBenchmarks)}
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          {mappingAspectComparison(data.comparisonReportCounts)}
        </Grid.Col>
      </Grid>
      
    </Box>
  )
}

export default StatisticData