import React, { useState, useEffect } from 'react'
import { Box, Flex, Group, Button, Input } from '@mantine/core'
import { updateCompany } from '../../../services/companies'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const defaultVal = {
  name: '',
  level: '',
  continent: '',
  industryId: '',
  countryId: '',
  address: '',
  profile: '',
  operational: '',
  contactPerson: '',
  isClient: false
}

const formValidation = {
  profile: {
    isError: false,
    message: ''
  },
  operational: {
    isError: false,
    message: ''
  }
}

const FormEditorCompanies = ({ detailCompany, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)

  const editorConfig = {
    extraPlugins: [uploadPlugin]
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file
        const base64 = await toBase64(file)
        return {
          default: base64
        }
      },
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  const submitCompany = async (payloadCompany) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      name: payloadCompany.name,
      level: payloadCompany.level,
      continent: payloadCompany.continent,
      industryId: payloadCompany.industryId,
      countryId: payloadCompany.countryId,
      address: payloadCompany.address,
      profile: payloadCompany.profile,
      operational: payloadCompany.operational,
      isClient: payloadCompany.isClient
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    payload.contactPerson = payloadCompany.contactPerson
    if (detailCompany !== null) {
      methodFunction = updateCompany(payloadCompany.id, payload)
      titleMessageSuccess = 'Update Perusahaan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate data perusahaan'
      titleMessageError = 'Gagal Mengupdate Perusahaan'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (response) {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  useEffect(() => {
    if (detailCompany !== null) {
      handleSetForm(detailCompany)
    }
    // eslint-disable-next-line
  }, [detailCompany])

  const handleSetForm = (detail) => {
    const dataDetail = {
      id: detail.id,
      name: detail.name,
      level: detail.level,
      continent: detail.continent,
      industryId: detail.industry.id,
      countryId: detail.country.id,
      address: detail.address,
      isClient: detail.isClient,
      profile: detail.profile === null ? '' : detail.profile,
      operational: detail.operational === null ? '' : detail.operational,
      contactPerson: detail.contactPerson === null ? '' : detail.contactPerson
    }
    setFormData(dataDetail)
  }

  const handleChangeEditor = (name, val) => {
    setFormData((old) => ({ ...old, [name]: val }))
  }
  return (
    <Box>
      <Box mb='md'>
        <Input.Wrapper 
          label="Profil Perusahaan"
          withAsterisk
          error={validationForm.profile.isError ? `${validationForm.profile.message}` : ''}
        >
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={formData.profile}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  'height',
                  '500px',
                  editor.editing.view.document.getRoot()
                )
              })
            }}
            onChange={( event, editor ) => {
              const valueEditor = editor.getData()
              handleChangeEditor('profile', valueEditor)
            }}
          />
        </Input.Wrapper>
      </Box>
      <Box mb='md'>
        <Input.Wrapper 
          label="Operasional Perusahaan"
          withAsterisk
          error={validationForm.operational.isError ? `${validationForm.operational.message}` : ''}
        >
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={formData.operational}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  'height',
                  '500px',
                  editor.editing.view.document.getRoot()
                )
              })
            }}
            onChange={( event, editor ) => {
              const valueEditor = editor.getData()
              handleChangeEditor('operational', valueEditor)
            }}
          />
        </Input.Wrapper>
      </Box>
      <Box mb='md'>
        <Input.Wrapper label="Kontak Perusahaan">
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={formData.contactPerson}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  'height',
                  '500px',
                  editor.editing.view.document.getRoot()
                )
              })
            }}
            onChange={( event, editor ) => {
              const valueEditor = editor.getData()
              handleChangeEditor('contactPerson', valueEditor)
            }}
          />
        </Input.Wrapper>
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitCompany(formData)}>Update Profil</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormEditorCompanies