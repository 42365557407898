import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button, TextInput, Select } from '@mantine/core'
import classes from '../Auth.module.css'
import { getListRoles, deleteRole } from '../../../services/roles'
import TableRoles from './TableRoles'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import FormRole from './FormRole'
import FormPermission from './FormPermission'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { getPermissionList } from '../../../services/permission'


const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'name',
  order: 'asc'
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]



const Roles = () => {
  const [ params, setParams ] = useState(defaultParameter)
  const [ roleList, setRoleList ] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [openModalPermission, setOpenModalPermission] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [defaultPermission, setDefaultPermission] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('createdAt')
  const [selectedOrder, setSelectedOrder] = useState('desc')

  const mappingOrder = [
    {
      value: 'asc',
      label: selectedFilter === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: selectedFilter === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetRoleList = async () => {
    setLoading(true)
    try {
      const response = await getListRoles(params)
      setRoleList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetDefaultPermission = async () => {
    try {
      const response = await getPermissionList()
      setDefaultPermission(response)
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    handleGetRoleList()
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    handleGetDefaultPermission()
    // eslint-disable-next-line
  }, [])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectFilter = (filterOrder) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: filterOrder,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: orders
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('role', val.id, val.name, deleteRole, handleGetRoleList)
    },
    permission: (val) => {
      setOpenModalPermission(true)
      setDetailData(val)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalPermission = () => {
    setOpenModalPermission(false)
    setDetailData(null)
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Role Management</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            <Button color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Role</Button>
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Role. . .'
              name="search"
              onChange={handleSearchChange}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [setSelectedFilter(e), handleSelectFilter(e)]}
              value={selectedFilter}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableRoles label={labelTable} data={roleList} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Role</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormRole dataRole={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetRoleList} />
      </Modal>
      <Modal opened={openModalPermission} onClose={onCloseModalPermission} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>Set Permission For "{detailData !== null ? detailData.name : ''}"</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormPermission roleId={detailData !== null ? detailData.id : null} permissionList={defaultPermission} onCloseModal={onCloseModalPermission} />
      </Modal>
    </AuthLayout>
  )
}

export default Roles