import React from 'react'
import { Table, Menu, ActionIcon } from '@mantine/core'
import dayjs from 'dayjs'
import { IconDotsVertical, IconTrash, IconPencil, IconFileAnalytics, IconReportOff } from '@tabler/icons-react'

const ActionMenu = ({ handleAction, statusBenchmark, remainingQuota }) => {
  const mappingMenuDropdown = (status) => {
    if (status !== 'final') {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={18} />}
          onClick={() => handleAction('resume')}
          fw='600'
          fz={12}
        >
          {status === 'draft' ? 'Lanjutkan' : 'Revisi'}
        </Menu.Item>
      )
    } else {
      return (
        <Menu.Item
          leftSection={<IconPencil stroke={1.5} size={18} />}
          onClick={() => handleAction('detail')}
          fw='600'
          fz={12}
        >
          Detail
        </Menu.Item>
      )
    }
  }

  const mappingMenuUpload = (status) => {
    if (status === 'final') {
      return (
        <Menu.Item
          leftSection={<IconFileAnalytics stroke={1.5} size={18} />}
          onClick={() => handleAction('upload')}
          fw='600'
          fz={12}
        >
          Upload Laporan
        </Menu.Item>
      )
    }
  }

  const mappingMenuRevision = (status, remaining) => {
    if (status === 'final' && remaining > 0) {
      return (
        <Menu.Item
          leftSection={<IconReportOff stroke={1.5} size={18} />}
          onClick={() => handleAction('revert')}
          fw='600'
          fz={12}
        >
          Revisi Laporan
        </Menu.Item>
      )
    }
  }

  return (
    <Menu width={175} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {mappingMenuDropdown(statusBenchmark)}
        {mappingMenuUpload(statusBenchmark)}
        {mappingMenuRevision(statusBenchmark, remainingQuota)}
        <Menu.Item
          color='red'
          leftSection={<IconTrash stroke={1.5} size={18} />}
          onClick={() => handleAction('delete')}
          fw='600'
          fz={12}
        >
          Hapus
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const TableBenchmarkResult = ({ label, data, countData, actionMethod }) => {
  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const setStatusRevision = (val) => {
    if (val.status === 'draft') {
      return 'Tidak Terbatas'
    } else if (val.status === 'final') {
      return val.revisionQuota
    } else if (val.status === 'revision') {
      return 'Proses'
    }
  }

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + countData + 1}.</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td>{val.companyReport.company.name}</Table.Td>
        <Table.Td>{val.companyReport.name}</Table.Td>
        <Table.Td>{val.companyReport.company.industry.name}</Table.Td>
        <Table.Td>{val.createdBy.fullName}</Table.Td>
        <Table.Td>{dayjs(val.createdAt).format("DD-MM-YYYY / HH:mm")}</Table.Td>
        <Table.Td>{dayjs(val.updatedAt).format("DD-MM-YYYY / HH:mm")}</Table.Td>
        <Table.Td>{setStatusRevision(val)}</Table.Td>
        <Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} statusBenchmark={val.status} remainingQuota={val.revisionQuota} /></Table.Td>
      </Table.Tr>
    )
  })
  return (
    <Table.ScrollContainer minWidth={768}>
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableBenchmarkResult