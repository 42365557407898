import http from '../plugins/axios'

export const getListIndustries = async (params) => {
  try {
    const response = await http.get(`/industries`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addIndustries = async (payload) => {
  try {
    const response = await http.post(`/industries`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateIndustries = async (industriesId, payload) => {
  try {
    const response = await http.patch(`/industries/${industriesId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteIndustries = async (industriesId) => {
  try {
    const response = await http.delete(`/industries/${industriesId}`)
    return response.data
  } catch(error) {
    throw error
  }
}