import React, { useState, useEffect } from 'react'
import { Box, Text, Modal, ScrollArea } from '@mantine/core'
import { getListCompanyReportParams, deleteCompanyReportParams } from '../../../../../../services/company-report-params'
import TableSkeleton from '../../../../../../components/ui/TableSkeleton'
import TableReportParams from './TableReportParams'
import FormReportParams from './FormReportParams'
import { modalDeleteData } from '../../../../../../components/ui/ModalManager/modalDeleteData'
import AttachImageReportParams from './AttachImageReportParams'

const labelTable = [
  {
    label: 'No.',
    width: 50
  },
  {
    label: 'Parameter',
    width: 'auto'
  },
  {
    label: 'Nama Benchmark',
    width: 'auto'
  },
  {
    label: 'Gambar Pendukung',
    width: 'auto'
  },
  {
    label: 'Jumlah',
    width: 'auto'
  },
  {
    label: 'Konversi Benchmark',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 80
  },
]

const CompanyReportParams = ({ reportParamsId, reportName }) => {
  const [reportParamsList, setReportParamsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [openModalAttachImage, setOpenModalAttachImage] = useState(false)

  const initDataReportParams = async () => {
    setLoading(true)
      const params = {
        companyReport: reportParamsId,
        orderBy: 'order',
        order: 'desc'
      }
    try {
      const response = await getListCompanyReportParams(params)
      setReportParamsList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (reportParamsId !== null) {
      initDataReportParams()
    }
    // eslint-disable-next-line
  }, [reportParamsId])


  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('Laporan Parameter', val.id, val.value, deleteCompanyReportParams, initDataReportParams)
    },
    attach: (val) => {
      setOpenModalAttachImage(true)
      setDetailData(val)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalAttach = () => {
    setOpenModalAttachImage(false)
    setDetailData(null)
  }

  return (
    <Box>
      <Text fz={16} fw='600' mb={12}>Laporan Parameter {reportName}</Text>
      <Box my={20}>
        {loading ? <TableSkeleton total={5} /> : <TableReportParams label={labelTable} data={reportParamsList} actionMethod={handleAction} />}
        <Text fw={600} fz={12} c='red'>Catatan: Silahkan isi jumlah produksi terlebih dahulu sebelum mengisi parameter yang lain</Text>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Laporan Parameter</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormReportParams dataReportParams={detailData} reportId={reportParamsId} reportName={reportName} onCloseModal={onCloseModalForm} reloadList={initDataReportParams} />
      </Modal>
      <Modal opened={openModalAttachImage} onClose={onCloseModalAttach} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>Lampirkan File Gambar Laporan</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <AttachImageReportParams dataReportParams={detailData} reportId={reportParamsId} onCloseModal={onCloseModalAttach} reloadList={initDataReportParams} />
      </Modal>
    </Box>
  )
}

export default CompanyReportParams