import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classes from '../../../pages/administrator/Benchmark/Benchmark.module.css'
import { Box, ScrollArea, Text, Center, Loader, Fieldset, Group, Button, Flex, Modal, Menu, Checkbox } from '@mantine/core'
import { getDetailBenchmarkReport } from '../../../services/benchmark-report'
import CompareParameterBenchmark from './CompareParameterBenchmark'
import { useDispatch } from 'react-redux'
import { updatedDataBenchmark } from '../../../store/benchmarkData' 
// import FormAttachmentBenchmark from './FormAttachmentBenchmark'
import { modalWarningData } from '../../ui/ModalManager/modalWarningData'
import FormPrimaryAttachment from './FormPrimaryAttachment'
import FormSecondaryAttachment from './FormSecondaryAttachment'

const OverviewDataBenchmark = ({ prevStep, nextStep, activeStep }) => {
  const dispatch = useDispatch()
  const dataBenchmark = useSelector(state => state.benchmark.dataBenchmark)
  const [detailReport, setDetailReport] = useState({})
  const [loading, setLoading] = useState(true)
  const [openPrimaryAttachment, setOpenPrimaryAttachment] = useState(false)
  const [openSecondaryAttachment, setOpenSecondaryAttachment] = useState(false)
  const [detailData, setDetailData] = useState(null)

  const handleDetailCompanyReport = async () => {
    setLoading(true)
    try {
      const response = await getDetailBenchmarkReport(dataBenchmark.benchmarkReportId)
      setDetailReport(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleDetailCompanyReport()
    // eslint-disable-next-line
  }, [])

  const LoadingData = () => {
    return (
      <Center h={500}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }

  const toPrevStep = () => {
    dispatch(updatedDataBenchmark({ 'layoutStep': activeStep - 1, 'dataBenchmark': dataBenchmark }))
    prevStep()
  }

  const toNextStep = () => {
    const textWarning = {
      caption: 'Apakah Anda Yakin Ingin Melakukan Benchmarking ?',
      description: 'Silahkan cek data dan lampiran terlebih dahulu, karena data yang sudah di benchmark tidak dapat diedit lagi'
    }
    const functionNextStep = () => {
      dispatch(updatedDataBenchmark({ 'layoutStep': activeStep + 1, 'dataBenchmark': dataBenchmark }))
      nextStep()
    }
    modalWarningData(textWarning.caption, textWarning.description, functionNextStep)
  }

  const onOpenModalAttachment = (typeAttachment) => {
    if (typeAttachment === 'primary') {
      setOpenPrimaryAttachment(true)
    } else {
      setOpenSecondaryAttachment(true)
    }
    setDetailData(detailReport)
  }

  const onCloseModalAttachment = (typeAttachment) => {
    if (typeAttachment === 'primary') {
      setOpenPrimaryAttachment(false)
    } else {
      setOpenSecondaryAttachment(false)
    }
    setDetailData(null)
  }

  const handleChange = (val) => {
    console.log(val)
    const payload = {
      ...dataBenchmark,
      changeParaphrase: val
    }
    dispatch(updatedDataBenchmark({ 'layoutStep': activeStep, 'dataBenchmark': payload }))
  }

  const MappingOverviewData = () => {
    return (
      <Box>
        <Box mb={16}>
          <Text fz={13}>Nama Perusahaan:</Text>
          <Text fz={13} fw='600'>{detailReport.companyReport.company.name}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Nama Laporan:</Text>
          <Text fz={13} fw='600'>{detailReport.name}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Deskripsi:</Text>
          <Text fz={13} fw='600'>{detailReport.description}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Benchmarker:</Text>
          <Text fz={13} fw='600'>{detailReport.benchmarker.name}</Text>
        </Box>
        <Box mb={16}>
          <Text fz={13}>Pembuat:</Text>
          <Text fz={13} fw='600'>{detailReport.createdBy.fullName}</Text>
        </Box>
        <Flex justify="flex-end">
          <Checkbox checked={dataBenchmark.changeParaphrase} onChange={(e) => handleChange(e.target.checked)} label={<Text fw='500'>Ganti Parafrase Laporan</Text>} disabled={detailReport.status !== 'revision'} />
        </Flex>
        {dataCompareBenchmark(JSON.parse(dataBenchmark.compareBenchmarkLevelNational), 'Nasional')}
        {dataCompareBenchmark(JSON.parse(dataBenchmark.compareBenchmarkLevelAsia), 'Asia')}
        {dataCompareBenchmark(JSON.parse(dataBenchmark.compareBenchmarkLevelInternational), 'Internasional')}
      </Box>
    )
  }

  const dataCompareBenchmark = (data, titleLevel) => {
    if (data !== null) {
      const mappingParameter = data => data.map((val, index) => {
        return (
          <Box my={12} key={index}>
            <CompareParameterBenchmark data={val} />
          </Box>
        )
      })
  
      return (
        <Box my={50}>
          <Fieldset legend={<Text fz={16} fw='600'>Pembanding Data Level {titleLevel}</Text>} variant='filled'>
            {mappingParameter(data)}
          </Fieldset>
        </Box>
      )
    }
  }


  return (
    <Box>
      <Text className={classes.titlePage} mb={20}>Overview Data Benchmark</Text>
      <Flex justify='end' my={6}>
        <Menu shadow="md" width={180} position="bottom-end" offset={6} withArrow arrowPosition="center">
          <Menu.Target>
            <Button size='xs'>Lampiran File</Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item fz={13} onClick={() => onOpenModalAttachment('primary')}> Lampiran Utama </Menu.Item>
            <Menu.Item fz={13} onClick={() => onOpenModalAttachment('secondary')}> Lampiran Tambahan </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <ScrollArea miw={768} scrollbars='x'>
        {loading ? <LoadingData /> : <MappingOverviewData />}
      </ScrollArea>
      <Group justify='space-between' mt="xl">
        <Button variant="default" onClick={toPrevStep}>Sebelumnya</Button>
        <Box>
          <Button onClick={toNextStep}>Selanjutnya</Button>
        </Box>
      </Group>
      <Modal opened={openPrimaryAttachment} onClose={() => onCloseModalAttachment('primary')} centered size='70%' closeOnClickOutside={false} title={<Text fw='Bold'>Lampiran File Utama</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormPrimaryAttachment detailData={detailData} onCloseModal={() => onCloseModalAttachment('primary')} reloadList={handleDetailCompanyReport} />
      </Modal>
      <Modal opened={openSecondaryAttachment} onClose={() => onCloseModalAttachment('secondary')} centered size='70%' closeOnClickOutside={false} title={<Text fw='Bold'>Lampiran File Tambahan</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormSecondaryAttachment detailData={detailData} onCloseModal={() => onCloseModalAttachment('secondary')} reloadList={handleDetailCompanyReport} />
      </Modal>
    </Box>
  )
}

export default OverviewDataBenchmark