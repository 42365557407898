import React, { useState, useEffect } from 'react'
import { getDetailParameter } from '../../../services/parameter'
import { Box, Text } from '@mantine/core'
import TableSelectedCompanyReport from './TableSelectedCompanyReport'
import TableSelectedKlhkReport from './TableSelectedKlhkReport'

const defaultValue = {
  name: ''
}

const labelTable = [
  {
    label: 'No.',
    width: 20
  },
  {
    label: 'Nama Perusahaan',
    width: 370
  },
  {
    label: 'Sumber Data',
    width: 200
  },
  {
    label: 'Tahun',
    width: 100
  },
  {
    label: 'Pemakaian / Timbulan',
    width: 180
  },
  {
    label: 'Intensitas',
    width: 250
  },
]

const labelTableKlhk = [
  {
    label: 'No.',
    width: 20
  },
  {
    label: 'Data Perdirjen',
    width: 'auto'
  },
  {
    label: '50% Rata-Rata',
    width: 'auto'
  },
  {
    label: '25% Teratas',
    width: 'auto'
  },
  {
    label: '25 Terbawah',
    width: 'auto'
  },
]

const CompareParameterBenchmark = ({ data }) => {
  const [detailParameter, setDetailParameter] = useState(defaultValue)

  const handleGetDetailParameter = async () => {
    try {
      const response = await getDetailParameter(data.parameterId)
      setDetailParameter(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetDetailParameter()
    // eslint-disable-next-line
  }, [data])

  const mappingCompareReport = (data) => {
    if (data.length > 0) {
      return (
        <Box>
          <TableSelectedCompanyReport label={labelTable} data={data} />
        </Box>
      )
    }
  }

  const mappingKlhkReport = (data) => {
    if (data !== null) {
      const dataKlhk = [data]
      return (
        <Box>
          <TableSelectedKlhkReport label={labelTableKlhk} data={dataKlhk} />
        </Box>
      ) 
    }
    
  }

  if (data.companyReportParameterIds.length > 0) {
    return (
      <Box mb={40}>
        <Text fz={13} fw='600'>{detailParameter.name}</Text>
        <Box mt={12}>
          {mappingCompareReport(data.companyReportParameterIds)}
        </Box>
      </Box>
    )
  } else {
    return (
      <Box mb={40}>
        <Text fz={13} fw='600'>{detailParameter.name}</Text>
        <Box mt={12}>
          {mappingKlhkReport(data.klhkReportParameterId)}
        </Box>
      </Box>
    )
  }
  
}

export default CompareParameterBenchmark