import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button } from '@mantine/core'
import classes from '../../Auth.module.css'
import { getListKlhkReportParams, generateKlhkReportParams, deleteKlhkReportParams } from '../../../../services/klhk-report-params.js'
import { getDetailKlhkReportById } from '../../../../services/klhk-report'
import TableSkeleton from '../../../../components/ui/TableSkeleton'
import { modalDeleteData } from '../../../../components/ui/ModalManager/modalDeleteData'
import TableKlhkReportParams from '../../../../components/pages/KlhkReport/ReportParams/TableKlhkReportParams'
import FormKlhkReportParams from '../../../../components/pages/KlhkReport/ReportParams/FormKlhkReportParams'
import { useParams } from 'react-router-dom'
import { getListParameter } from '../../../../services/parameter'
import { notificationSuccess, notificationError } from '../../../../components/ui/Notifications'

const defaultReport = {
  id: null,
  name: '',
  year: null,
  industry: {
    id: null
  },
  isLocked: false
}

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Parameter',
    width: 'auto'
  },
  {
    label: '50% Rata-Rata',
    width: 'auto'
  },
  {
    label: '25% Teratas',
    width: 'auto'
  },
  {
    label: '25% Terbawah',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const KlhkReportParams = () => {
  const parameter = useParams()
  const klhkReportId = parameter.id
  const defaultParameter = {
    skip: 0,
    take: 10,
    search: '',
    klhkReport: klhkReportId,
    orderBy: 'order',
    order: 'asc',
    industry: null
  }
  const [params, setParams] = useState(defaultParameter)
  const [detailKlhkReport, setDetailKlhkReport] = useState(defaultReport)
  const [reportParamsList, setReportParamsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [count, setCount] = useState(0)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [parameterList, setParameterList] = useState([])

  const handleGetDetailKlhkReport = async (reportId) => {
    try {
      const response = await getDetailKlhkReportById(reportId)
      setDetailKlhkReport(response)
      // eslint-disable-next-line
      setParams({ ...params, ['industry']: response.industry.id })
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetParameterList = async () => {
    try {
      const response = await getListParameter()
      setParameterList(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const initDataKlhkReportParams = async () => {
    setLoading(true)
    try {
      const response = await getListKlhkReportParams(params)
      setReportParamsList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const updateRecentKlhk = async () => {
    setLoadingUpdate(true)
    const payload = {
      klhkReportId: klhkReportId
    }
    try {
      const response = await generateKlhkReportParams(payload)
      if (response) {
        initDataKlhkReportParams()
        notificationSuccess('Update Parameter Berhasil', 'Anda telah berhasil melakukan update parameter')
      }
    } catch (error) {
      console.log(error)
      notificationError('Update Parameter Gagal', 'Silahkan menghubungi admin sistem untuk mengatasi masalah ini')
    } finally {
      setLoadingUpdate(false)
    }
  }



  useEffect(() => {
    handleGetDetailKlhkReport(klhkReportId)
    handleGetParameterList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initDataKlhkReportParams()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      klhkReport: klhkReportId,
      orderBy: 'order',
      order: 'asc'
    }
    setParams(params)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('Laporan KLHK Parameter', val.id, val.parameter.name, deleteKlhkReportParams, initDataKlhkReportParams)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }


  return (
    <AuthLayout>
      <Box>
        <Box mb={40}>
          <Text className={classes.titlePage} mb={40} ta='center'>Informasi Laporan Perdirjen</Text>
          <Box>
            <Box mb={12}>
              <Text fz={13}>Nama Laporan:</Text>
              <Text fz={13} fw='600'>{detailKlhkReport.name}</Text>
            </Box>
            <Box mb={12}>
              <Text fz={13}>Tahun Laporan:</Text>
              <Text fz={13} fw='600'>{detailKlhkReport.year}</Text>
            </Box>
          </Box>
        </Box>
        <Text className={classes.titlePage} mb={20}>Parameter Data Perdirjen</Text>
        <Flex justify='flex-end' mb={14}>
          <Button size='xs' color='indigo.9' loading={loadingUpdate} onClick={updateRecentKlhk} disabled={detailKlhkReport.isLocked}>Update Parameter Terbaru</Button>
        </Flex>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableKlhkReportParams label={labelTable} data={reportParamsList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'}  Batas Intensitas Perdirjen</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormKlhkReportParams dataKlhkParams={detailData} klhkReportId={klhkReportId} parameterList={parameterList} onCloseModal={onCloseModalForm} reloadList={initDataKlhkReportParams} />
      </Modal>
    </AuthLayout>
  )
}

export default KlhkReportParams