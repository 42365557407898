import http from '../plugins/axios'

export const login = async (payload) => {
  try {
    const response = await http.post(`/auth/login`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const refreshToken = async (payload) => {
  try {
    const response = await http.post(`/auth/refresh`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getPermissionRole = async () => {
  try {
    const response = await http.get(`/auth/permissions`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const resetPasswordRequest = async (payload) => {
  try {
    const response = await http.post(`/password-resets/request`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const resetPasswordVerify = async (resetToken, payload) => {
  try {
    const response = await http.post(`/password-resets/verify?token=${resetToken}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}