import React, { useEffect, useState } from 'react'
import { Box, Group, Flex, Button, FileInput, Text, Image } from '@mantine/core'
import { uploadApprovalSheet, uploadEventReport, uploadCoverLetter } from '../../../services/benchmark-report.js'
import { notificationSuccess } from '../../ui/Notifications'
import NoImage from '../../../assets/img/no-image.webp'
import { getImageFile } from '../../../services/file'

const defaultVal = {
  approvalSheet: null,
  eventReport: null,
  coverLetter: null,
}
const formValidation = {
  approvalSheet: {
    isError: false,
    message: ''
  },
  eventReport: {
    isError: false,
    message: ''
  },
  coverLetter: {
    isError: false,
    message: ''
  }
}

const FormPrimaryAttachment = ({ detailData, onCloseModal, reloadList }) => {
  const [formUpload, setFormUpload] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingSubmitAttachment, setLoadingSubmitAttachment] = useState(false)
  const [imageBaseApprovalSheet64, setImageBaseApprovalSheet64] = useState(null)
  const [imageBaseCoverLetter64, setImageBaseCoverLetter64] = useState(null)
  const [imageBaseEventReport64, setImageBaseEventReport64] = useState(null)

  const submitAttachment = async (payloadAttachment) => {
    setLoadingSubmitAttachment(true)
    const benchmarkId = detailData !== null ? detailData.id : null
    try {
      if (formUpload.approvalSheet !== null) {
        const formApprovalSheet = new FormData()
        formApprovalSheet.set('file', formUpload.approvalSheet)
        await uploadApprovalSheet(benchmarkId, formApprovalSheet)
      }
      if (formUpload.coverLetter !== null) {
        const formCoverLetter = new FormData()
        formCoverLetter.set('file', formUpload.coverLetter)
        await uploadCoverLetter(benchmarkId, formCoverLetter)
      }
      if (formUpload.eventReport !== null) {
        const formEventReport = new FormData()
        formEventReport.set('file', formUpload.eventReport)
        await uploadEventReport(benchmarkId, formEventReport)
      }
      
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSubmitAttachment(false)
      notificationSuccess('Upload Attachment Berhasil', 'Anda telah berhasil melakukan upload attachment')
      onCloseModal()
      reloadList()
    }
  }

  useEffect(() => {
    if (detailData !== null) {
      handleGetDetail(detailData)
    }
  }, [detailData])

  const handleGetDetail = (data) => {
    if (data.approvalSheet !== null) {
      setImageBaseApprovalSheet64(getImageFile(data.approvalSheet))
    }
    if (data.coverLetter !== null) {
      setImageBaseCoverLetter64(getImageFile(data.coverLetter))
    }
    if (data.eventReport !== null) {
      setImageBaseEventReport64(getImageFile(data.eventReport))
    }
  }

  const convertFileBaseCoverLetter64 = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBaseCoverLetter64(reader.target.result)
    }
  }
  const convertFileBaseApprovalSheet64 = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBaseApprovalSheet64(reader.target.result)
    }
  }
  const convertFileBaseEventReport64 = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBaseEventReport64(reader.target.result)
    }
  }

  const handleFileApprovalSheet = (fileUpload) => {
    setValidationForm(formValidation)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationForm({
        approvalSheet: {
          isError: true,
          message: 'File foto terlalu besar'
        }
      })
      // eslint-disable-next-line
      setFormUpload({ ...formUpload, ['aprovalSheet']: null })
      return
    }
    convertFileBaseApprovalSheet64(fileUpload)
    // eslint-disable-next-line
    setFormUpload({ ...formUpload, ['approvalSheet']: fileUpload })
  }

  const handleFileEventReport = (fileUpload) => {
    setValidationForm(formValidation)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationForm({
        approvalSheet: {
          isError: true,
          message: 'File foto terlalu besar'
        }
      })
      // eslint-disable-next-line
      setFormUpload({ ...formUpload, ['eventReport']: null })
      return
    }
    convertFileBaseEventReport64(fileUpload)
    // eslint-disable-next-line
    setFormUpload({ ...formUpload, ['eventReport']: fileUpload })
  }

  const handleFileCoverLetter = (fileUpload) => {
    setValidationForm(formValidation)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationForm({
        approvalSheet: {
          isError: true,
          message: 'File foto terlalu besar'
        }
      })
      // eslint-disable-next-line
      setFormUpload({ ...formUpload, ['coverLetter']: null })
      return
    }
    convertFileBaseCoverLetter64(fileUpload)
    // eslint-disable-next-line
    setFormUpload({ ...formUpload, ['coverLetter']: fileUpload })
  }

  return (
    <Box>
      <Box my={20}>
        <Box mb={40}>
          <FileInput
            name='coverLetter'
            value={formUpload.coverLetter}
            accept="image/png,image/jpeg"
            label='Upload Gambar Surat Pengantar'
            placeholder='Upload gambar surat pengantar'
            error={validationForm.coverLetter.isError ? `${validationForm.coverLetter.message}` : ''}
            onChange={handleFileCoverLetter}
            mb='lg'
          />
          <Box>
            <Text fw='bold' mb={12}>Tinjauan Gambar Surat Pengantar</Text>
            <Image src={imageBaseCoverLetter64 === null ? NoImage : imageBaseCoverLetter64} radius="md" h={250} fit="contain" />
          </Box>
        </Box>
        <Box mb={40}>
          <FileInput
            name='approvalSheet'
            value={formUpload.approvalSheet}
            accept="image/png,image/jpeg"
            label='Upload Gambar Lembar Persetujuan'
            placeholder='Upload gambar lembar persetujuan'
            error={validationForm.approvalSheet.isError ? `${validationForm.approvalSheet.message}` : ''}
            onChange={handleFileApprovalSheet}
            mb='lg'
          />
          <Box>
            <Text fw='bold' mb={12}>Tinjauan Gambar Lembar Persetujuan</Text>
            <Image src={imageBaseApprovalSheet64 === null ? NoImage : imageBaseApprovalSheet64} radius="md" h={250} fit="contain" />
          </Box>
        </Box>
        <Box mb={40}>
          <FileInput
            name='eventReport'
            value={formUpload.eventReport}
            accept="image/png,image/jpeg"
            label='Upload Gambar Berita Acara'
            placeholder='Upload gambar berita acara'
            error={validationForm.eventReport.isError ? `${validationForm.eventReport.message}` : ''}
            onChange={handleFileEventReport}
            mb='lg'
          />
          <Box>
              <Text fw='bold' mb={12}>Tinjauan Gambar Berita Acara</Text>
              <Image src={imageBaseEventReport64 === null ? NoImage : imageBaseEventReport64} radius="md" h={250} fit="contain" />
            </Box>
        </Box>
      </Box>
      <Box>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingSubmitAttachment} variant="filled" color='indigo.9' onClick={() => submitAttachment(formUpload)}>Simpan</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormPrimaryAttachment