import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { Box, Text, Flex, Pagination, Modal, ScrollArea, Button, TextInput, Select } from '@mantine/core'
import classes from '../Auth.module.css'
import { getListIndustries, deleteIndustries } from '../../../services/industries'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TableIndustries from '../../../components/pages/Industries/TableIndustries'
import FormIndustries from '../../../components/pages/Industries/FormIndustries'
import ParameterIndustries from '../../../components/pages/Industries/ParameterIndustries'
import { modalDeleteData } from '../../../components/ui/ModalManager/modalDeleteData'
import { useSelector } from 'react-redux'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'name',
  order: 'asc'
}

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const defaultOrder = [
  {
    value: 'asc',
    label: 'A - Z'
  },
  {
    value: 'desc',
    label: 'Z - A'
  },
]

const Industries = () => {
  const permissionState = useSelector(state => state.permission.access)
  const [ params, setParams ] = useState(defaultParameter)
  const [ IndustriesList, setIndustriesList ] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [openModalParameter, setOpenModalParameter] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState('asc')

  const handleGetIndustriesList = async () => {
    setLoading(true)
    try {
      const response = await getListIndustries(params)
      const dataIndustries = response.data
      setIndustriesList(dataIndustries)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  

  useEffect(() => {
    handleGetIndustriesList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: e.target.value,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleChangePage = (e) => {
    const parameter = {
      skip: (e - 1) * 10,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: params.order
    }
    setParams(parameter)
  }

  const handleSelectOrder = (orders) => {
    const parameter = {
      skip: 0,
      take: 10,
      search: params.search,
      orderBy: params.orderBy,
      order: orders
    }
    setParams(parameter)
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    parameter: (val) => {
      setOpenModalParameter(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('sektor perusahaan', val.id, val.name, deleteIndustries, handleGetIndustriesList)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const onCloseModalParameter = () => {
    setOpenModalParameter(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionIndustry = permissionState['industry']
    const createdPermission = permissionIndustry.find(val => val.alias === 'industry.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' color='indigo.9' onClick={() => setOpenModalForm(true)}>Tambah Sektor</Button>
      )
    }
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titlePage} mb={20}>Manajemen Sektor Perusahaan</Text>
        <Box>
          <Flex justify='flex-end' mb={14}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Kategori. . .'
              name="search"
              onChange={handleSearchChange}
            />
            <Select
              name="order"
              data={defaultOrder}
              onChange={(e) => [setSelectedOrder(e), handleSelectOrder(e)]}
              value={selectedOrder}
              checkIconPosition="right"
              allowDeselect={false}
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableIndustries label={labelTable} data={IndustriesList} countData={params.skip} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination onChange={handleChangePage} value={params.skip / 10 + 1} total={Math.ceil(count / params.take) || 0} color='indigo.9' />
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Sektor Perusahaan</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormIndustries dataIndustries={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetIndustriesList} />
      </Modal>
      <Modal opened={openModalParameter} onClose={onCloseModalParameter} closeOnClickOutside={false} centered size='80%' title={<Text fw='Bold'>Parameter Sektor {detailData !== null ? `"${detailData.name}"` : ''}</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <ParameterIndustries dataIndustries={detailData} onCloseModal={onCloseModalParameter} />
      </Modal>
    </AuthLayout>
  )
}

export default Industries