import http from '../plugins/axios'

export const getListCompanyReport = async (params) => {
  try {
    const response = await http.get(`/company-reports`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailCompanyReportById = async (companyId, params) => {
  try {
    const response = await http.get(`/company-reports/${companyId}`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addCompanyReport = async (payload) => {
  try {
    const response = await http.post(`/company-reports`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateCompanyReport = async (reportId, payload) => {
  try {
    const response = await http.patch(`/company-reports/${reportId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteCompanyReport = async (reportId) => {
  try {
    const response = await http.delete(`/company-reports/${reportId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const assignLockCompanyReport = async (payload) => {
  try {
    const response = await http.patch(`/company-reports/lock`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const uploadFileCompanyReport = async (reportId, payload) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  try {
    const response = await http.post(`/company-reports/${reportId}/file`, payload, config)
    return response.data
  } catch (error) {
    console.log(error)
    throw (error)
  }
}