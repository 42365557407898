import React, { useState, useEffect } from 'react'
import { getImageFile } from '../../../services/file'
import { Box, Image, Flex, Button } from '@mantine/core'

const PreviewImageChartKlhk = ({ dataImage, onCloseModal }) => {
  const [imageBase64, setImageBase64] = useState(null)

  useEffect(() => {
    if (dataImage !== null) {
      handleDetailImage(dataImage)
    }
    // eslint-disable-next-line
  }, [dataImage])

  const handleDetailImage = (fileImage) => {
    if (typeof fileImage === 'object') {
      convertfileBase64(fileImage)
    } else {
      setImageBase64(getImageFile(fileImage))
    }
  }

  
  const convertfileBase64 = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBase64(reader.target.result)
    }
  }
  return (
    <Box>
      <Image src={imageBase64 === null ? '' : imageBase64} radius="md" h={350} fit="contain" />
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
        </Flex>
      </Box>
    </Box>
  )
  
}

export default PreviewImageChartKlhk