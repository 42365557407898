import React, { useState, useEffect } from 'react'
import { getListCompanyReportParams } from '../../../services/company-report-params'
import { Box, ScrollArea, Flex, TextInput, Center, Text, Group, Checkbox, Button, Table, Select } from '@mantine/core'
import { YearPickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { updatedDataBenchmark } from '../../../store/benchmarkData'
import { IconSquareCheck, IconDeselect } from '@tabler/icons-react'
import { decimalCheck, decimalFormat, thousandFormat } from '../../../plugins/addons'
import TableSkeleton from '../../ui/TableSkeleton'
import { notificationWarning } from '../../ui/Notifications'

const labelTable = [
  {
    label: '',
    width: 30
  },
  {
    label: 'Nama Laporan',
    width: 'auto'
  },
  {
    label: 'Perusahaan',
    width: 'auto'
  },
  {
    label: 'Benua / Wilayah',
    width: 'auto'
  },
  {
    label: 'Negara',
    width: 'auto'
  },
  {
    label: 'Tahun',
    width: 'auto'
  },
  {
    label: 'Timbulan / Pemakaian',
    width: 'auto'
  },
  {
    label: 'Intensitas',
    width: 'auto'
  },
]

const defaultContinent = {
  'asia': 'Asia',
  'afrika': 'Afrika',
  'eropa': 'Eropa',
  'amerika_utara': 'Amerika Utara',
  'amerika_selatan': 'Amerika Selatan',
  'australia': 'Australia',
  'oseania': 'Oseania'
}

const defaultDataContinent = [
  {
    value: 'asia',
    label: 'Asia'
  },
  {
    value: 'afrika',
    label: 'Afrika'
  },
  {
    value: 'eropa',
    label: 'Eropa'
  },
  {
    value: 'amerika_utara',
    label: 'Amerika Utara'
  },
  {
    value: 'amerika_selatan',
    label: 'Amerika Selatan'
  },
  {
    value: 'australia',
    label: 'Australia'
  },
  {
    value: 'oseania',
    label: 'Oseania'
  },
]

const BenchmarkDataSource = ({ onCloseModal, selectedParams, companyId, comparisonBenchmarkList, benchmarkLevel, handleRefreshChart }) => {
  const dispatch = useDispatch()
  const stateBenchmark = useSelector(state => state.benchmark)
  const defaultComparison = comparisonBenchmarkList
  const companyReportParams = {
    search: '',
    parameter: selectedParams,
    type: 'comparison',
    excludeCompany: companyId,
    isNotNull: 1,
    year: '',
    intensityFrom : null,
    intensityTo: null,
    continent: ''
  }
  const [companyReportList, setCompanyReportList] = useState([])
  const [loadingReport, setLoadingReport] = useState(true)
  const [parameterCompanyReport, setParameterCompanyReport] = useState(companyReportParams)
  const [compareBenchmarkList, setCompareBenchmarkList] = useState(defaultComparison)
  
  const handleGetCompanyReportParamsList = async () => {
    setLoadingReport(true)
    try {
      const response = await getListCompanyReportParams(parameterCompanyReport)
      setCompanyReportList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingReport(false)
    }
  }

  useEffect(() => {
    handleGetCompanyReportParamsList()
    // eslint-disable-next-line
  }, [parameterCompanyReport])

  const handleSearchChange = (e) => {
    setParameterCompanyReport({ ...parameterCompanyReport, [e.target.name]: e.target.value })
  }

  const handleSearchYear = (e) => {
    const parameter = {
      year: e !== null ? dayjs(e).format('YYYY') : '',
      parameter: parameterCompanyReport.parameter,
      type: parameterCompanyReport.type,
      excludeCompany: parameterCompanyReport.excludeCompany,
      isNotNull: 1,
      search: parameterCompanyReport.search,
      continent: parameterCompanyReport.continent
    }
    setParameterCompanyReport(parameter)
  }

  const handleSelectChange = (val, name) => {
    setParameterCompanyReport((old) => ({ ...old, [name]: val }))
  }

  const checkAllSourceData = (allReports) => {
    const selectedParameter = compareBenchmarkList.find(value => value.parameterId === selectedParams).companyReportParameterIds
    if (selectedParameter.length === allReports.length) {
      notificationWarning('Data Parameter Sudah Terpilih', 'Anda sudah memilih semua parameter')
      return
    }
    if (allReports.length > 0) {
      for (let i = 0; i < allReports.length; i++) {
        const findParameter = selectedParameter.find(val => val.id === allReports[i].id)
        if (findParameter === undefined) {
          selectedParameter.push(allReports[i])
        }
      }
      setCompareBenchmarkList([...compareBenchmarkList])
    }
  }

  const uncheckAllSourceData = () => {
    const selectedParameter = compareBenchmarkList.find(value => value.parameterId === selectedParams).companyReportParameterIds
    selectedParameter.splice(0, selectedParameter.length)
    setCompareBenchmarkList([...compareBenchmarkList])
  }

  const storeCompareBenchmark = (data) => {
    const filterSelectedParameter = data.filter(val => val.parameterId !== undefined)
    const payload = {
      name: stateBenchmark.dataBenchmark.name,
      description: stateBenchmark.dataBenchmark.description,
      companyId: stateBenchmark.dataBenchmark.companyId,
      companyReportId: stateBenchmark.dataBenchmark.companyReportId,
      benchmarkReportId: stateBenchmark.dataBenchmark.benchmarkReportId,
      benchmarkerId: stateBenchmark.dataBenchmark.benchmarkerId,
      selectedParameter: selectedParams,
      compareBenchmarkLevelAsia: stateBenchmark.dataBenchmark.compareBenchmarkLevelAsia,
      compareBenchmarkLevelNational: stateBenchmark.dataBenchmark.compareBenchmarkLevelNational,
      compareBenchmarkLevelInternational: stateBenchmark.dataBenchmark.compareBenchmarkLevelInternational
    }
    if (benchmarkLevel === 'national') {
      payload.compareBenchmarkLevelNational = JSON.stringify(filterSelectedParameter)
    } else if (benchmarkLevel === 'asia') {
      payload.compareBenchmarkLevelAsia = JSON.stringify(filterSelectedParameter)
    } else if (benchmarkLevel === 'international') {
      payload.compareBenchmarkLevelInternational = JSON.stringify(filterSelectedParameter)
    }
    dispatch(updatedDataBenchmark({ 'layoutStep': stateBenchmark.layoutStep, 'dataBenchmark': payload }))
    onCloseModal()
    handleRefreshChart()
  }

  const selectReport = (eventChecked, valueReport) => {
    const selectedParameter = compareBenchmarkList.find(value => value.parameterId === selectedParams).companyReportParameterIds
    if (eventChecked) {
      selectedParameter.push(valueReport)
      setCompareBenchmarkList([...compareBenchmarkList])
    } else {
      for (let i = 0; i < selectedParameter.length; i++) {
        if (selectedParameter[i].id === valueReport.id) {
          selectedParameter.splice(i,1)
        }
      }
      setCompareBenchmarkList([...compareBenchmarkList])
    }
  }

  const mappingDataReport = (dataCompanyReport) => {
    if (dataCompanyReport.length > 0) {
      const mappingTableHead = (data) => data.map((val, index) => {
        return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
      })

      const mappingIsProductionParams = (val) => {
        if (!val.parameter.aspect.isProduction) {
          return (<Table.Td>{val.intensity ? decimalCheck(val.intensity) ? thousandFormat(val.intensity) : decimalFormat(val.intensity) : 0} {val.parameter.unit.symbol} / {val.parameter.divider.unit.symbol}</Table.Td>)
        } else {
          return (<Table.Td>-</Table.Td>)
        }
      }

      const mappingTableCompanyReport = (data) => data.map((val, index) => {
        return (
          <Table.Tr key={index}>
            <Table.Td><Checkbox checked={compareBenchmarkList.find(value => value.parameterId === selectedParams).companyReportParameterIds.find(value => value.id === val.id)} onChange={(event) => selectReport(event.target.checked, val)} /></Table.Td>
            <Table.Td>{val.companyReport.name}</Table.Td>
            <Table.Td>{val.companyReport.company.name}</Table.Td>
            <Table.Td tt='capitalize'>{defaultContinent[val.companyReport.company.continent]}</Table.Td>
            <Table.Td tt='capitalize'>{val.companyReport.company.country.name}</Table.Td>
            <Table.Td>{val.companyReport.year}</Table.Td>
            <Table.Td>{val.finalValue ? decimalCheck(val.finalValue) ? thousandFormat(val.finalValue) : decimalFormat(val.finalValue) : 0} {val.parameter === null ? '' : val.parameter.unit.symbol}</Table.Td>
            {mappingIsProductionParams(val)}
            </Table.Tr>
          
        )
      })
      return (
        <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '13px' }}>
          <Table.Thead>
            <Table.Tr>
              {mappingTableHead(labelTable)}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {mappingTableCompanyReport(dataCompanyReport)}
          </Table.Tbody>
        </Table>
      )
    } else {
      return (
        <Center h={300}>
          <Text c='gray.6'>Tidak ada sumber data</Text>
        </Center>
      )
    }
  }

  return (
    <Box>
      <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
        <Box>
          <Text size='xs' mb={4} fw='600'>Filter</Text>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Laporan. . .'
              name="search"
              size='xs'
              onChange={handleSearchChange}
              
            />
            <YearPickerInput
              placeholder="Cari Tahun. . ."
              onChange={handleSearchYear}
              clearable
              size='xs'
            />
            <Select
              placeholder="Cari Benua. . ."
              data={defaultDataContinent}
              value={parameterCompanyReport.continent}
              onChange={(val) => handleSelectChange(val, 'continent')}
              size='xs'
            />
          </Flex>
        </Box>
        <Box>
          <Text size='xs' fw='600' mb={4}>Range Intensitas</Text>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Dari'
              name="intensityFrom"
              onChange={handleSearchChange}
              size='xs'
              type='number'
            />
            <TextInput
              placeholder='Ke'
              name="intensityTo"
              onChange={handleSearchChange}
              size='xs'
              type='number'
            />
          </Flex>
        </Box>
      </Flex>
      <Group my={14}>
        <Button size='xs' leftSection={<IconSquareCheck size={12} />} variant='light' onClick={() => checkAllSourceData(companyReportList)}>Pilih Semua</Button>
        <Button size='xs' leftSection={<IconDeselect size={12} />} variant='light' color='red' onClick={() => uncheckAllSourceData()}>Hapus Semua</Button>
      </Group>
      <Box mih={350} my={20}>
        <ScrollArea>
          {loadingReport ? <TableSkeleton total={8} /> : mappingDataReport(companyReportList)}
        </ScrollArea>
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button variant="filled" color='indigo.9'onClick={() => storeCompareBenchmark(compareBenchmarkList)}>Simpan</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default BenchmarkDataSource
