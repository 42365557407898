import React, { useState, useEffect } from 'react'
import NoImage from '../../../../assets/img/no-image.webp'
import { Box, Text, Image, Flex, Group, Button, FileInput } from '@mantine/core'
import { uploadImageCompanyReport } from '../../../../services/company-report-params'
import { notificationSuccess, notificationError } from '../../../ui/Notifications'
import { getImageFile } from '../../../../services/file'

const formValidation = {
  file: {
    isError: false,
    message: ''
  }
}

const AttachImageCompanyReport = ({ dataCompanyParams, onCloseModal, reloadList }) => {
  const [loadingForm, setLoadingForm] = useState(false)
  const [imageBase64, setImageBase64] = useState(null)
  const [fileImage, setFileImage] = useState(null)
  const [companyReportParamsId, setCompanyReportParamsId] = useState(null)
  const [validationForm, setValidationForm] = useState(formValidation)

  useEffect(() => {
    if (dataCompanyParams !== null) {
      handleSetForm(dataCompanyParams)
    }
    // eslint-disable-next-line
  }, [dataCompanyParams])

  const handleSetForm = (value) => {
    setCompanyReportParamsId(value.id)
    if (value.image !== null) {
      setImageBase64(getImageFile(value.image))
    }
  } 

  const handleFilePhoto = (fileUpload) => {
    setValidationForm(formValidation)
    const imageSize = fileUpload.size / 1024 / 1024
    if (imageSize > 2) {
      setValidationForm({
        file: {
          isError: true,
          message: 'File foto terlalu besar'
        }
      })
      setFileImage(null)
      return
    }
    convertfileBase64(fileUpload)
    setFileImage(fileUpload)
  }

  const convertfileBase64 = (file) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (reader) => {
      setImageBase64(reader.target.result)
    }
  }

  const attachFileCompanyReport = async () => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    if (fileImage === null) {
      setValidationForm({
        file: {
          isError: true,
          message: 'Silahkan upload gambar terlebih dahulu'
        }
      })
      setLoadingForm(false)
      return
    }
    const form = new FormData()
    form.append('file', fileImage)
    try {
      await uploadImageCompanyReport(companyReportParamsId, form)
      onCloseModal()
      reloadList()
      notificationSuccess('Upload Gambar Berhasil', 'Anda telah berhasil menambahkan gambar')
    } catch (error) {
      notificationError('Upload Gambar Gagal', 'Anda gagal menambahkan gambar')
      console.log(error)
    } finally {
      setLoadingForm(false)
    }
  }
  
  return (
    <Box>
      <Box mb='md'>
        <Text fw='bold' mb={12}>Tinjauan Gambar</Text>
        <Image src={imageBase64 === null ? NoImage : imageBase64} radius="md" h={250} fit="contain" />
      </Box>
      <Box mb='md'>
        <FileInput
          name='file'
          value={fileImage}
          accept="image/png,image/jpeg"
          label='Upload Gambar'
          placeholder='Upload file gambar'
          error={validationForm.file.isError ? `${validationForm.file.message}` : ''}
          onChange={handleFilePhoto}
          withAsterisk
        />
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => attachFileCompanyReport()}>Simpan</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default AttachImageCompanyReport