import React from 'react'
import { modals } from '@mantine/modals'
import { ActionIcon, Box, Text } from '@mantine/core'
import { IconLock, IconLockOpen } from '@tabler/icons-react'
import { notificationSuccess, notificationError } from '../Notifications'


export const modalSetStatusReport = ( id, name, status, typeReport, functionSetStatus, reloadData ) => {

  const handleAssignLockData = async (idReport) => {
    let payload = {}
    if (typeReport === 'company-report') {
      payload = {
        companyReports: [
          {
            id: idReport,
            isLocked: status
          }
        ]
      }
    } else {
      payload = {
        klhkReports: [
          {
            id: idReport,
            isLocked: status
          }
        ]
      }
    }
    try {
      const response = await functionSetStatus(payload)
      if (response) {
        notificationSuccess(`${!status ? 'Buka Akses' : 'Kunci Akses'} berhasil`,  `Data berhasil ${!status ? 'dibuka' : 'dikunci'}`)
        reloadData()
        modals.closeAll()
      }
    } catch (error) {
      console.log(error)
      notificationError(`Ubah akses gagal`, `ubah akses gagal`)
    }
  }

  return modals.openConfirmModal({
    centered: 'true',
    closeOnConfirm: false,
    children: (
      <Box>
        <Box ta='center'>
          <ActionIcon variant='transparent' color={!status ? 'green' : 'red'} size={120}>
            {!status ? (<IconLockOpen stroke={1.5} size={120} />) : (<IconLock stroke={1.5} size={120} />)}
          </ActionIcon>
          <Text fw={500} mb={4}>Apakah anda yakin ingin {!status ? 'membuka akses' : 'mengunci akses'} ? </Text>
          <Text fw='bold' mb={14}>"{name}"</Text>
        </Box>
      </Box>
    ),
    labels: { confirm: `${!status ? 'Buka Akses' : 'Kunci Akses'}`, cancel: "Batal" },
    confirmProps: { color: 'indigo.9' },
    onConfirm: () => handleAssignLockData(id),
  })
}
