import React from 'react'
import { Table, Menu, ActionIcon, Tooltip } from '@mantine/core'
import { IconDotsVertical, IconPencil, IconTrash, IconReportAnalytics, IconLock, IconLockOpen } from '@tabler/icons-react'
import { useLocation } from 'react-router-dom' 
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const defaultLevel = {
  'national': 'Nasional',
  'asia': 'Asia',
  'international': 'Internasional'
}

const ActionMenu = ({ handleAction, statusLocked }) => {
  const { pathname } = useLocation()
  const permissionState = useSelector(state => state.permission.access)

  const menuRoute = (pathname) => {
    if (pathname !== '/benchmark-results') {
      const permissionUpdate = () => {
        const permissionReport = permissionState['company-report']
        const updatedPermission = permissionReport.find(val => val.alias === 'company-report.update')
        if (updatedPermission !== undefined && !statusLocked) {
          return (
            <Menu.Item
              leftSection={<IconPencil stroke={1.5} size={12} />}
              onClick={() => handleAction('edit')}
              fw='600'
              fz={12}
            >
              Edit
            </Menu.Item>
          )
        }
      }

      const permissionLockedReport = () => {
        const permissionReport = permissionState['company-report']
        const statusPermission = permissionReport.find(val => val.alias === 'company-report.lock')
        if (statusPermission !== undefined) {
          if (!statusLocked) {
            return (
              <Menu.Item
                leftSection={<IconLock stroke={1.5} size={12} />}
                onClick={() => handleAction('lock')}
                fw='600'
                fz={12}
              >
                Kunci Akses
              </Menu.Item>
            )
          } else {
            return (
              <Menu.Item
                leftSection={<IconLockOpen stroke={1.5} size={12} />}
                onClick={() => handleAction('unlock')}
                fw='600'
                fz={12}
              >
                Buka Akses
              </Menu.Item>
            )
          }
        }
      }
    
      const permissionDelete = () => {
        const permissionParameter = permissionState['company-report']
        const deletedPermission = permissionParameter.find(val => val.alias === 'company-report.delete')
        if (deletedPermission !== undefined && !statusLocked) {
          return (
            <Menu.Item
              color='red'
              leftSection={<IconTrash stroke={1.5} size={12} />}
              onClick={() => handleAction('delete')}
              fw='600'
              fz={12}
            >
              Hapus
            </Menu.Item>
          )
        }
      }

      return (
        <Menu.Dropdown>
          {permissionState !== null ? permissionUpdate() : ''}
          <Menu.Item
            leftSection={<IconReportAnalytics stroke={1.5} size={12} />}
            onClick={() => handleAction('report')}
            fw='600'
            fz={12}
          >
            Detail
          </Menu.Item>
          {permissionState !== null ? permissionLockedReport() : ''}
          {permissionState !== null ? permissionDelete() : ''}
        </Menu.Dropdown>
      )
    } else {
      return (
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconReportAnalytics stroke={1.5} size={12} />}
            onClick={() => handleAction('detail')}
            fw='600'
            fz={12}
          >
            Hasil Benchmark
          </Menu.Item>
        </Menu.Dropdown>
      )
    }
  }
  return (
    <Menu width={170} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant='transparent' size='sm' color='rgba(0, 0, 0, 1)'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      {menuRoute(pathname)}
    </Menu>
  )
}

const TableCompanyReport = ({ label, data, countData, actionMethod }) => {
  const { pathname } = useLocation()

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + countData + 1}.</Table.Td>
        <Table.Td>{val.company.name}</Table.Td>
        <Table.Td>{val.company.industry.name}</Table.Td>
        <Table.Td>{defaultLevel[val.company.level]}</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td>{val.year}</Table.Td>
        {finishedBenchmark(val.finishedAt)}
        {mappingStatusReport(val.isLocked)}
        <Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} statusLocked={val.isLocked} /></Table.Td>
      </Table.Tr>
    )
  })

  const finishedBenchmark = (dateBenchmark) => {
    if (pathname === '/benchmark-results') {
      return (<Table.Td>{dayjs(dateBenchmark).format('DD MMMM YYYY')}</Table.Td>)
    }
  }

  const mappingStatusReport = (status) => {
    if (pathname !== '/benchmark-results') {
      if (!status) {
        return (
          <Table.Td>
            <Tooltip label='Anda diijinkan merubah atau menghapus laporan ini'>
              <ActionIcon ml={10} mt={6} variant='transparent' size='xs' color='green'>
                <IconLockOpen stroke={1.5}/>
              </ActionIcon>
            </Tooltip>
          </Table.Td>
        )
      } else {
        return (
          <Table.Td>
            <Tooltip label='Anda tidak diijinkan merubah atau menghapus laporan ini'>
              <ActionIcon ml={10} mt={6} variant='transparent' size='xs' color='red'>
                <IconLock stroke={1.5}/>
              </ActionIcon>
            </Tooltip>
          </Table.Td>
        )
      }
    }
  }
  return (
    <Table.ScrollContainer minWidth={768} type='native'>
      <Table horizontalSpacing='sm' withTableBorder style={{ fontSize: '12.5px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableCompanyReport