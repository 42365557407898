import React, { useEffect } from 'react'
import { MantineProvider, LoadingOverlay } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { routes, authRoutes } from './routes'
import Cookies from 'universal-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { getCredential } from './store/authData'
import { getPermissionRoute } from './store/permissionRoute'

// css mantine
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'

// css ck-editor
import '../src/assets/css/editor-style.css'

// css react quill
import 'react-quill/dist/quill.snow.css'


export default function App() {
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname, search } = useLocation()
  const { isLoading, isLogin } = useSelector(state => state.auth)

  const handleSession = () => {
    const sessionLogin = cookies.get('token')
    const queryParams = new URLSearchParams(search)
    const resetToken = queryParams.get("token")
    if (sessionLogin !== undefined) {
      dispatch(getCredential())
      dispatch(getPermissionRoute())
      navigate(pathname !== undefined ? pathname : '/dashboard')
    } else if (resetToken) {
      navigate(`/password-resets/verify?token=${resetToken}`)
    } else {
      navigate('/login')
      dispatch(getCredential())
      // dispatch(getPermissionRoute())
    }
  }

  // init load page to top page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // initialize session
  useEffect(() => {
    handleSession()
    //eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      if (isLogin) {
        return navigate(pathname !== '/login' ? pathname : '/dashboard')
      } else {
        return navigate('/login', { state: { from: location }, replace: true})
      }
    }
    // eslint-disable-next-line
  }, [isLogin])

  const styleUI = {
    fontFamily: "Montserrat, sans-serif",
    headings: { 
      fontFamily: 'Montserrat, sans-serif'
    },
  }

  // eslint-disable-next-line
  const getRoute = (allRoutes) => allRoutes.map((page) => {
    if (!page.hasChildren) {
      return (
        <Route path={page.route} element={page.component}  key={page.name} />
      )
    } else {
      return (
        <Route path={page.route} key={page.name}>
          {childrenRoute(page.children)}
        </Route>
      )
    }
  })

  // eslint-disable-next-line
  const childrenRoute = (arrayRoute) => arrayRoute.map((children) => {
    if (children) {
      if (children.route === null) {
        return (
          <Route index element={children.component} key={children.name} />
        )
      } else {
        return (
          <Route path={children.route} element={children.component} key={children.name} />
        )
      }
    }
  })

  return (
    <MantineProvider theme={styleUI}>
      <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "md", blur: 3 }} />
      <Notifications autoClose={4000} position="top-right" limit={5} />
      <ModalsProvider>
        <Routes>
          {getRoute(routes)}
          {getRoute(authRoutes)}
        </Routes>
      </ModalsProvider>
    </MantineProvider>
  )
}
