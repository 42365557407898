import React, { useState, useEffect } from 'react'
import { Box, TextInput, Button, Group, Flex, FileInput, Select, ActionIcon, Tooltip, Text } from '@mantine/core'
import { YearPickerInput } from '@mantine/dates'
import { addKlhkReport, updateKlhkReport, uploadFileKlhkReport } from '../../../services/klhk-report'
import { validation } from '../../../plugins/validation'
import { notificationSuccess, notificationError } from '../../../components/ui/Notifications'
import { IconFileCv, IconTrash, IconDownload  } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { getListIndustries } from '../../../services/industries'
import CBadge from '../../ui/CBadge'
import { getFile } from '../../../services/file'

const defaultVal = {
  issue: '',
  name: '',
  year: null,
  industryId: null,
  sourceType: 'file',
  source: null,
  file: null
}
const formValidation = {
  issue: {
    isError: false,
    message: ''
  },
  name: {
    isError: false,
    message: ''
  },
  year: {
    isError: false,
    message: ''
  },
  industryId: {
    isError: false,
    message: ''
  },
  file: {
    isError: false,
    message: ''
  }
}

const FormKlhk = ({ dataKlhk, onCloseModal, reloadList }) => {
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [industriesList, setIndustriesList] = useState([])
  const [selectedIndustryId, setSelectedIndustryId] = useState(null)

  const handleGetIndustriesList = async () => {
    try {
      const response = await getListIndustries()
      const dataIndustry = response.data
      const mappingIndustry = dataIndustry.map((val) => {
        const remap = {
          value: val.id.toString(),
          label: val.name
        }
        return remap
      })
      setIndustriesList(mappingIndustry)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetIndustriesList()
    if (dataKlhk !== null) {
      handleSetForm(dataKlhk)
    }
    // eslint-disable-next-line
  }, [dataKlhk])

  const handleSetForm = (dataKlhk) => {
    const dataYear = new Date()
    dataYear.setFullYear(dataKlhk.year)
    const dataDetail = {
      id: dataKlhk.id,
      issue: dataKlhk.issue,
      industryId: dataKlhk.industry.id,
      name: dataKlhk.name,
      year: dataYear,
      sourceType: dataKlhk.sourceType,
      source: dataKlhk.source,
      file: null
    }
    setSelectedIndustryId(dataKlhk.industry.id)
    setFormData(dataDetail)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  
  const handleChangeYear = (e) => {
    // eslint-disable-next-line
    setFormData({ ...formData, ['year']: e })
  }
  
  const handleUploadFile = (files) => {
    // eslint-disable-next-line
    setFormData({ ...formData, ['file']: files })
  }

  const submitReport = async (payloadReport) => {
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''
    setLoadingForm(true)
    setValidationForm(formValidation)
    const payload = {
      issue: payloadReport.issue,
      name: payloadReport.name,
      year: payloadReport.year,
      industryId: selectedIndustryId,
      sourceType: 'file'
    }
    const isError = validation(payload, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    const body = {
      issue: payload.issue,
      name: payload.name,
      industryId: selectedIndustryId,
      year: Number(dayjs(payload.year).format('YYYY')),
      sourceType: payload.sourceType,
      source: null
    }
    if (dataKlhk !== null) {
      methodFunction = updateKlhkReport(payloadReport.id, body)
      titleMessageSuccess = 'Update Laporan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil mengupdate laporan'
      titleMessageError = 'Gagal Mengupdate Laporan'
      captionMessageError = 'Silahkan cek kembali form anda'
    } else {
      methodFunction = addKlhkReport(body)
      titleMessageSuccess = 'Tambah Laporan Berhasil'
      captionMessageSuccess = 'Anda telah berhasil menambahkan laporan baru'
      titleMessageError = 'Gagal Menambahkan Laporan'
      captionMessageError = 'Silahkan cek kembali form anda'
    }
    try {
      const response = await methodFunction
      if (formData.source === null || formData.file !== null) {
        const reportId = response.id !== undefined ? response.id : payloadReport.id
        const upload = await handleUploadFileReport(reportId)
        if (upload) {
          onCloseModal()
          reloadList()
          notificationSuccess(titleMessageSuccess, captionMessageSuccess)
          setLoadingForm(false)
        }
      } else {
        onCloseModal()
        reloadList()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
        setLoadingForm(false)
      }
      
    } catch (error) {
      setLoadingForm(false)
      const errorMessage = error.response.data.message
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    }
  }

  const handleUploadFileReport = async (reportId) => {
    const form = new FormData()
    form.set('file', formData.file)
    try {
      const response = await uploadFileKlhkReport(reportId, form)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const showDataFile = (dataSource) => {
    if (dataSource !== null) {
      const downloadFile = () => {
        window.open(`${getFile(dataSource)}`, '_blank')
      }

      const deleteAttachFile = () => {
        // eslint-disable-next-line
        setFormData({ ...formData, ['source']: null })
        // eslint-disable-next-line
        // setFormData({ ...formData, ['file']: null })
      }

      return (
        <Box>
          <Text fz={14} mb={8} fw={500}>File Sebelumnya</Text>
          <Flex justify='space-between'>
          <Box w="50%">
            <CBadge name={dataSource} />
          </Box>
          <Group>
            <Tooltip label='Unduh'>
              <ActionIcon variant='transparent' color='indigo.9' onClick={downloadFile}>
                <IconDownload stroke={1.5} size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label='Hapus'>
              <ActionIcon variant='transparent' color='red' onClick={deleteAttachFile}>
                <IconTrash stroke={1.5} size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Flex>
        </Box>
        
      )
    } 
  }

  return (
    <Box>
      <Box mb='md'>
        <TextInput
          name='issue'
          value={formData.issue}
          label='Nomor Perdirjen'
          placeholder='Masukkan nomor perdirjen'
          error={validationForm.issue.isError ? `${validationForm.issue.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <TextInput
          name='name'
          value={formData.name}
          label='Nama Laporan'
          placeholder='Masukkan nama laporan'
          error={validationForm.name.isError ? `${validationForm.name.message}` : ''}
          onChange={handleChange}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <YearPickerInput
          name='year'
          value={formData.year}
          label='Tahun Laporan'
          placeholder='Masukkan tahun laporan'
          error={validationForm.year.isError ? `${validationForm.year.message}` : ''}
          onChange={handleChangeYear}
          withAsterisk
        />
      </Box>
      <Box mb='md'>
        <Select
          name="industryId"
          label="Sektor Perusahaan"
          placeholder="Pilih salah satu sektor perusahaan"
          data={industriesList}
          nothingFoundMessage='Sektor tidak ditemukan'
          onChange={(e) => setSelectedIndustryId(e)}
          error={validationForm.industryId.isError ? `${validationForm.industryId.message}` : ''}
          value={selectedIndustryId}
          withAsterisk
          searchable
          checkIconPosition="right"
        />
      </Box>
      <Box mb='md'>
        <FileInput
          leftSection={<IconFileCv stroke={1.5} size={18} />}
          name='file'
          value={formData.file}
          accept='application/msword, application/vnd.ms-excel, application/pdf'
          label='File Laporan'
          placeholder='Upload file laporan'
          error={validationForm.file.isError ? `${validationForm.file.message}` : ''}
          onChange={handleUploadFile}
        />
      </Box>
      <Box my={12}>
        {formData.source !== null ?  showDataFile(formData.source) : ''}
      </Box>
      <Box mt={20}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
            <Button loading={loadingForm} variant="filled" color='indigo.9' onClick={() => submitReport(formData)}>{dataKlhk === null ? 'Tambah' : 'Update'}</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormKlhk