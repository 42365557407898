import React from 'react'
import classes from './AuthSidebar.module.css'
import { useSelector } from 'react-redux'
import { authRoutes } from '../../../../routes'
import { Box, ThemeIcon, Text, Center, Loader } from '@mantine/core'
import { useNavigate, useLocation } from 'react-router-dom'

const AuthSidebar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isLoadingPermission, access } = useSelector(state => state.permission)

  const camelToDashCase = (str) => {
    if (str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    } else {
      return str
    }
  }

  const LoadingData = () => {
    return (
      <Center h={500}>
        <Loader color='blue' size='xl' type='dots' />
      </Center>
    )
  }
  
  const mappingMenuSidebar = (listPermission) => {
    if (listPermission !== null) {
      // eslint-disable-next-line
      const renderRoute = authRoutes.map(({ name, route, icon, type, permission }, index) => {
        let returnRender = null
        const dataPermission = Object.keys(listPermission)
        dataPermission.push('dashboard')

        const isAllowed = dataPermission.includes(camelToDashCase(permission))
        if (type === 'route') {
          returnRender = (
            <a
              className={classes.link}
              key={index}
              href={route}
              data-active={route === pathname || undefined}
              onClick={(event) => {
                event.preventDefault()
                navigate(`${route}`)
              }}
            >
              <ThemeIcon variant='light' className={classes.linkIcon} data-active={route === pathname || undefined}  radius="md" size="md">
                {icon}
              </ThemeIcon>
              <span style={{ textTransform: 'capitalize' }}>{name}</span>
            </a>
          )
        } else if (type === 'title') {
          returnRender = (
            <Text fz={12} fw='bold' c='gray.5' ml={10} tt='uppercase' my={12} key={index} >{name}</Text>
          )
        }

        if (permission) {
          if (isAllowed) {
            return returnRender
          } else {
            return null
          }
        }
      })
      return renderRoute
    }
  }
  return (
    <Box p='lg'>
      {isLoadingPermission ? (<LoadingData />) : mappingMenuSidebar(access)}
    </Box>
  )
}

export default AuthSidebar