import React, { useState, useEffect } from 'react'
import { Box, Button, Group, Flex, Modal, Text, ScrollArea } from '@mantine/core'
import { getListParameter, deleteParameter } from '../../../services/parameter'
import TableSkeleton from '../../ui/TableSkeleton'
import TableParameterIndustries from './TableParameterIndustries'
import FormParameterIndustries from './FormParameterIndustries'
import TableOrderParameter from './TableOrderParameter'
import { modalDeleteData } from '../../ui/ModalManager/modalDeleteData'
import { useSelector } from 'react-redux'

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Aspek',
    width: 'auto'
  },
  {
    label: 'Satuan Unit',
    width: 'auto'
  },
  {
    label: 'Jumlah Pengkali',
    width: 'auto'
  },
  {
    label: 'Produksi Utama',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const labelTableOrder = [
  {
    label: 'Order.',
    width: 50
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Aspek',
    width: 'auto'
  },
  {
    label: 'Satuan Unit',
    width: 'auto'
  },
  {
    label: 'Jumlah Pengkali',
    width: 'auto'
  },
  {
    label: 'Produksi Utama',
    width: 'auto'
  },
]

const ParameterIndustries = ({ dataIndustries, onCloseModal }) => {
  const permissionState = useSelector(state => state.permission.access)
  const [parameterList, setParameterList] = useState([])
  const [loadingParameter, setLoadingParameter] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const [changeOrder, setChangeOrder] = useState(false)

  const handleGetDataParameter = async (industryId) => {
    setLoadingParameter(true)
    const params = {
      industry: industryId,
      orderBy: 'order',
      order: 'asc'
    }
    try {
      const response = await getListParameter(params)
      setParameterList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setChangeOrder(false)
      setLoadingParameter(false)
    }
  }

  useEffect(() => {
    if (dataIndustries !== null) {
      handleGetDataParameter(dataIndustries.id)
    }
    // eslint-disable-next-line
  }, [dataIndustries])

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('parameter', val.id, val.name, deleteParameter, () => handleGetDataParameter(dataIndustries.id))
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionIndustryParameter = permissionState['parameter']
    const createdPermission = permissionIndustryParameter.find(val => val.alias === 'parameter.create')
    if (createdPermission !== undefined) {
      return (
        <Button color='indigo.9' size='xs' onClick={() => setOpenModalForm(true)}>Tambah Parameter</Button>
      )
    }
  }

  const mappingStateOrder = (status) => {
    if (!status) {
      return (
        <Flex justify='flex-end' mb={14}>
          <Button color='indigo.9' size='xs' mr={6} onClick={() => setChangeOrder(true)} disabled={parameterList.length <= 1}>Ubah Order</Button>
          {permissionState !== null ? handleCreate() : ''}
        </Flex>
      )
    } 
  }

  return (
    <Box>
      {mappingStateOrder(changeOrder)}
      <Box my={20}>
        {loadingParameter ? <TableSkeleton total={6} /> : !changeOrder ? <TableParameterIndustries label={labelTable} data={parameterList} actionMethod={handleAction} /> : <TableOrderParameter label={labelTableOrder} data={parameterList} industryId={dataIndustries.id} cancelSubmit={() => setChangeOrder(false)} handleReloadList={() => handleGetDataParameter(dataIndustries.id)} />}
      </Box>
      <Box mt={30}>
        <Flex justify='flex-end'>
          <Group>
            <Button variant="outline" color='indigo.9' onClick={onCloseModal}>Tutup</Button>
          </Group>
        </Flex>
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} closeOnClickOutside={false} centered size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Parameter</Text>}  scrollAreaComponent={ScrollArea.Autosize} withCloseButton={false}>
        <FormParameterIndustries idIndustry={dataIndustries !== null ? dataIndustries.id : null} dataParameter={detailData} onCloseModal={onCloseModalForm} reloadList={() => handleGetDataParameter(dataIndustries.id)} />
      </Modal>
    </Box>
    
  )
}

export default ParameterIndustries